.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    /* height: 100%; */
    /* padding-left: 24px; */
    color: rgb(0, 0, 0);
    line-height: 24px;
}

@media (min-width: 500px) {
    .NavigationItems {
        flex-flow: column;
    }
}

.NavLink {
    /* margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%; */
    display: flex;
    line-height: 24px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'Roboto';
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}
/* 
 a {

    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    padding: 1.3rem 1.8rem;
    border-left: 6px solid transparent;
}


 a.hover {
    color: #292B2C;
    border-left: 6px solid #FFCC00;
}

 a:hover {
    background-color: #e8eaed;
    color: #292B2C;
}


a.Selected {
    color: #292B2C;
    border-left: 6px solid #FFCC00;
    background-color: #e8eaed;
    color: #292B2C;
} */