.Toolbar {
    display: flex;
    background-color: #FFF;
    border-bottom: 1px solid #e8e8e8;
    color: black;
    top: 0;
    width: 100%;
    min-width: 320px;
    padding: 0.8rem 0;
    /* position: fixed; */
    z-index: 10000;
}

/* daeffa */
.ToolbarWrapper {
    padding: 8px;
    background-color: #265973;
    color: white;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.ToolbarLogoSection {
    order: 1;
    /* padding-right: 4.8rem; */
    box-sizing: border-box;
    flex: 1 0 100%;
    vertical-align: middle;
    white-space: nowrap;
    align-items: center;
    display: flex;
    max-width: 32rem;
}

.ToolbarHamburgerWrapper {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 2.4rem;
    width: 2.4rem;
    flex: 0 0 auto;
    fill: #FFF;
}

.AppLogo,
.AppLogo:hover {
    font-weight: 300;
    font-size: 1.8rem;
    background-color: #265973;
    color: #fff;
    /* border-right: 1px solid rgba(255, 255, 255, 0.36); */
    padding: 1.3rem 0;
    border-left: 1px solid transparent;
}

.CompanyLogo {
    /* height: 7.2rem; */
    line-height: 4.8rem;
    color: #fff;
    padding: 1.2rem 0;
    padding-left: 1.6rem;
}

.Logo {
    background-color: transparent;
    padding: 0;
    height: 3.4rem;
    box-sizing: border-box;
}



.Bold {
    font-weight: 800;
}

.LogoWrapper {
    align-items: center;
    display: flex;
    position: relative;
}

.Logo {
    display: inline-block;
    font-family: ''Roboto'', Arial, sans-serif;
    font-size: 24px;
    line-height: 24px;
    padding-left: 8px;
    position: relative;
    top: -1.5px;
    vertical-align: middle;
}

.bold {
    font-weight: 600;
}

.gb_Ec {
    vertical-align: middle;
    white-space: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-user-select: none;
}

.gb_dc {
    line-height: normal;
    position: relative;
    padding-left: 16px;
}

.gb_dc a {
    outline: none;
    color: inherit;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
}

.gb_Ic {
    height: 24px;
    width: 74px;
    display: inline-block;
    vertical-align: middle;
}

.SelectionWrapper {
    vertical-align: middle;
    white-space: nowrap;
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    justify-content: flex-start;
}

.gb_Sd {
    padding-right: 16px;
    max-width: 100%;
    flex: 1 1 auto;
    width: 100%;
    height: 48px;
}

.SearchForm {
    background: rgba(255, 255, 255, 0.36);
    color: white;
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    margin-left: 0;
    margin-right: 0;
    max-width: 60rem;
    position: relative;
    transition: background 100ms ease-in, width 100ms ease-out;
}

.SearchFormInputWrapper {
    margin-left: 50px;
    margin-right: 50px;
}

.SearchFormInput {
    background: transparent;
    border: none;
    font: normal 16px 'Roboto', sans-serif;
    font-variant-ligatures: none;
    outline: none;
    padding: 1.4rem;
    padding-right: 0;
    padding-left: 2px;
    width: 51rem;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 1);
}

.SearchFormInput::placeholder {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
}

.SearchFormIcon {
    position: absolute;
    padding-left: 2px;
    padding-right: 2px;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0 4px;
    line-height: 0;
    float: left;
    top: 0;
    font: 400 13px 'Roboto', arial, sans-serif;
}

.SearchIconWrapper {
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    line-height: 0;
    margin: 1.2rem;
    height: 24px;
    width: 24px;
}

.SearchIconWrapper svg {
    fill: rgba(0, 0, 0, 0.5);
    opacity: 1;
}

.UserControls {
    order: 3;
    color: #fff;
    height: 48px;
    vertical-align: middle;
    white-space: nowrap;
    align-items: center;
    display: flex;
    height: 48px;
    line-height: normal;
    padding: 0 4px;
    box-sizing: border-box;
    /* padding-left: 32px; */
    flex: 1 1 auto;
    -webkit-box-pack: flex-end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.ToolbarHelpWrapper {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    padding: 12px;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.ToolbarHelpWrapper span {
    padding-right: 12px;
}



.Notifications {
    order: 2;
    flex: 1 1 100%;
    /* padding: 0 5rem; */
    position: relative;
    min-width: 242px;
}

.NotificationsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.TimeContainer {
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    cursor: pointer;
    display: inline-block;
    text-align: left;
    white-space: nowrap;
}



.Time {
    display: inline-block;
    font-size: 38px;
    position: relative;
    vertical-align: middle;
    z-index: 5;
    color: #fff;
    font-weight: 300;
    letter-spacing: 1.5px;
    font-family: 'Open Sans';
}


.TimeHours {
    display: inline-block;
    vertical-align: top;
}

.TimeSemicolon {
    display: inline-block;
    vertical-align: top;
}

.TimeMinutes {
    display: inline-block;
    vertical-align: top;
}

.TimeAmPm {
    display: inline-block;
    font-size: 12px;
    line-height: 24px;
    margin-left: 0;
    vertical-align: top;
}

.TimemanRightSide {
    color: #fff;
}

.TimemanRightSide {
    display: inline-block;
    font-size: 12px;
    font-family: "OpenSans-Regular", sans-serif;
    padding: 0 0 0 13px;
    position: relative;
    text-transform: uppercase;
    vertical-align: middle;
    z-index: 5;
}

.TimemanContainer .TimemanBackground {
    width: auto;
    left: 0;
    right: 0;
}

.TimemanBackground {
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.TimemanInfo {
    display: block;
    margin-bottom: 8px;
}

.TimemanTaskTime {
    padding-left: 17px;
}

.TimemanBeginningBut,
.TimemanTaskTime {
    display: block;
    padding-left: 20px;
    position: relative;
}

.planner-wrap .timeman-event,
.planner-wrap .timeman-tasks {
    display: block;
    margin: 3px 11px 3px 0;
    padding-left: 15px;
}

.TimemanTasks {
    display: inline-block;
    vertical-align: top;
    position: relative;
}

.TimemanTaskTime {
    padding-left: 17px;
}

.TimemanBeginningBut,
.TimemanTaskTime {
    display: block;
    padding-left: 20px;
    position: relative;
}

.TimemanTasks:before {
    background: url('data:image/svg+xml;charset=US-ASCII,<svg%20xmlns%3D"http%3A//www.w3.org/2000/svg"%20width%3D"6"%20height%3D"9"%20viewBox%3D"0%200%206%209">%0A%20%20<polygon%20fill%3D"%23fff"%20points%3D"6%200%200%200%200%209%201%209%201%205%206%205"/>%0A</svg>%0A') center center no-repeat;
}

.TimemanTasks:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 6px;
    height: 9px;
    margin: 0 5px;
}