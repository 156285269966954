.NavigationItem {
    display: flex;
    line-height: 24px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'Roboto';
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.NavigationItem a {
    margin-left: 7.5px;
        text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    padding: 1.3rem 1.8rem;
    border-left: 6px solid transparent;
    color: #292B2C;
}


.NavigationItem a.hover {
    color: #d7effb
}

.NavigationItem a:hover { 
    /* color: #292B2C;
    border-left: 6px solid #FFCC00;
    background-color: #d7effb; */
    margin-left: 7.5px;
    color: #292B2C;
    border: 0;
    border-left: 6px solid #4680A0;
    background-color: #FFF;
    border-color: #4680A0;
}
/* 
@media (min-width: 500px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }

    .NavigationItem a {
        color: #292B2C;
        height: 100%;
    }
} */

.NavigationItem a.Selected {
    /* color: #292B2C;
    border-left: 6px solid #FFCC00;
    background-color: #d7effb; */
    margin-left: 7.5px;
    color: #292B2C;
    border: 0;
    /* border-left: 6px solid rgb(230, 236, 241);
    background-color: #FFF;
    border-color: rgb(230, 236, 241); */
    border-left: 6px solid #4680A0;
    background-color: #FFF;
    border-color: #4680A0;
}
