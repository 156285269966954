:root {
  box-sizing: border-box;

}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  /* background-color: #f4f5f8; */
  /* background-color: #f6f9fa; */
  background-color: #efefef;
  font-family: 'Roboto';
  height: 100%;
  /* padding-top: 6.4rem; */
}

/* UTILITY CLASSES */
.src_u-left-text__3KQ9j {
  text-align: left;
}

.src_u-right-text__1FJYq {
  text-align: right;
}

.src_u-margin-bottom-big__KJQ93 {
  margin-bottom: 7rem;
}

.src_u-margin-bottom-medium__1FN6r {
  margin-bottom: 4rem;
}

.src_u-margin-bottom-small__3g3zk {
  margin-bottom: 1.5rem;
}

.src_u-text-semi-bold__VOKsV {
  font-weight: 600;
}

.src_u-inline-block__3WbZ3 {
  display: inline-block;
}

.src_tab-list__AsAlz {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.src_tab-list-item__1pMWO {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.src_tab-list-active__2s4Pf {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}


.Layout_Wrapper__1hNEC {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.Layout_ToolBar__18j6c {
    /* height: 6.4rem; */
}

.Layout_Content__pJj1n {
    width: 100% !important;
}

/* .MainContentWrapper {
    width: 100%;
} */
.Layout_FlexColumns__2fFKY {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.Layout_shell-view__1jLp9 .Layout_main__3DqbJ:not(.Layout_main-flex-container-true__lUbXZ) {
    width: 100%;
    height: 100%;
    position: absolute;
}

.Layout_bAw__1ckGU {
    min-width: 56px;
    -webkit-transition-duration: .15s;
    transition-duration: .15s;
    -webkit-transition-property: min-width, width;
    transition-property: min-width, width;
    -webkit-transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    z-index: 2;
}
.Toolbar_Toolbar__24dFu {
    display: flex;
    background-color: #FFF;
    border-bottom: 1px solid #e8e8e8;
    color: black;
    top: 0;
    width: 100%;
    min-width: 320px;
    padding: 0.8rem 0;
    /* position: fixed; */
    z-index: 10000;
}

/* daeffa */
.Toolbar_ToolbarWrapper__2pWh_ {
    padding: 8px;
    background-color: #265973;
    color: white;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Toolbar_ToolbarLogoSection__3yeMh {
    order: 1;
    /* padding-right: 4.8rem; */
    box-sizing: border-box;
    flex: 1 0 100%;
    vertical-align: middle;
    white-space: nowrap;
    align-items: center;
    display: flex;
    max-width: 32rem;
}

.Toolbar_ToolbarHamburgerWrapper__16xt9 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 2.4rem;
    width: 2.4rem;
    flex: 0 0 auto;
    fill: #FFF;
}

.Toolbar_AppLogo__3b2m_,
.Toolbar_AppLogo__3b2m_:hover {
    font-weight: 300;
    font-size: 1.8rem;
    background-color: #265973;
    color: #fff;
    /* border-right: 1px solid rgba(255, 255, 255, 0.36); */
    padding: 1.3rem 0;
    border-left: 1px solid transparent;
}

.Toolbar_CompanyLogo__17aDG {
    /* height: 7.2rem; */
    line-height: 4.8rem;
    color: #fff;
    padding: 1.2rem 0;
    padding-left: 1.6rem;
}

.Toolbar_Logo__3itUN {
    background-color: transparent;
    padding: 0;
    height: 3.4rem;
    box-sizing: border-box;
}



.Toolbar_Bold__3p3HO {
    font-weight: 800;
}

.Toolbar_LogoWrapper__2nVJU {
    align-items: center;
    display: flex;
    position: relative;
}

.Toolbar_Logo__3itUN {
    display: inline-block;
    font-family: ''Roboto'', Arial, sans-serif;
    font-size: 24px;
    line-height: 24px;
    padding-left: 8px;
    position: relative;
    top: -1.5px;
    vertical-align: middle;
}

.Toolbar_bold__2pp6Y {
    font-weight: 600;
}

.Toolbar_gb_Ec__2wd8X {
    vertical-align: middle;
    white-space: nowrap;
    align-items: center;
    display: flex;
    -webkit-user-select: none;
}

.Toolbar_gb_dc__3GqHJ {
    line-height: normal;
    position: relative;
    padding-left: 16px;
}

.Toolbar_gb_dc__3GqHJ a {
    outline: none;
    color: inherit;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
}

.Toolbar_gb_Ic__1mAir {
    height: 24px;
    width: 74px;
    display: inline-block;
    vertical-align: middle;
}

.Toolbar_SelectionWrapper__2D-Ae {
    vertical-align: middle;
    white-space: nowrap;
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    justify-content: flex-start;
}

.Toolbar_gb_Sd__2gAJj {
    padding-right: 16px;
    max-width: 100%;
    flex: 1 1 auto;
    width: 100%;
    height: 48px;
}

.Toolbar_SearchForm__1jVQU {
    background: rgba(255, 255, 255, 0.36);
    color: white;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
    margin-left: 0;
    margin-right: 0;
    max-width: 60rem;
    position: relative;
    -webkit-transition: background 100ms ease-in, width 100ms ease-out;
    transition: background 100ms ease-in, width 100ms ease-out;
}

.Toolbar_SearchFormInputWrapper__3vlvc {
    margin-left: 50px;
    margin-right: 50px;
}

.Toolbar_SearchFormInput__1J6Pm {
    background: transparent;
    border: none;
    font: normal 16px 'Roboto', sans-serif;
    -webkit-font-feature-settings: none;
            font-feature-settings: none;
    -webkit-font-variant-ligatures: none;
            font-variant-ligatures: none;
    outline: none;
    padding: 1.4rem;
    padding-right: 0;
    padding-left: 2px;
    width: 51rem;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 1);
}

.Toolbar_SearchFormInput__1J6Pm::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
}

.Toolbar_SearchFormInput__1J6Pm::-moz-placeholder {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
}

.Toolbar_SearchFormInput__1J6Pm::-ms-input-placeholder {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
}

.Toolbar_SearchFormInput__1J6Pm::placeholder {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
}

.Toolbar_SearchFormIcon__1c-_Q {
    position: absolute;
    padding-left: 2px;
    padding-right: 2px;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0 4px;
    line-height: 0;
    float: left;
    top: 0;
    font: 400 13px 'Roboto', arial, sans-serif;
}

.Toolbar_SearchIconWrapper__2dSoZ {
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    line-height: 0;
    margin: 1.2rem;
    height: 24px;
    width: 24px;
}

.Toolbar_SearchIconWrapper__2dSoZ svg {
    fill: rgba(0, 0, 0, 0.5);
    opacity: 1;
}

.Toolbar_UserControls__BRZ6v {
    order: 3;
    color: #fff;
    height: 48px;
    vertical-align: middle;
    white-space: nowrap;
    align-items: center;
    display: flex;
    height: 48px;
    line-height: normal;
    padding: 0 4px;
    box-sizing: border-box;
    /* padding-left: 32px; */
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Toolbar_ToolbarHelpWrapper__2ZxXs {
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    padding: 12px;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Toolbar_ToolbarHelpWrapper__2ZxXs span {
    padding-right: 12px;
}



.Toolbar_Notifications__20eVx {
    order: 2;
    flex: 1 1 100%;
    /* padding: 0 5rem; */
    position: relative;
    min-width: 242px;
}

.Toolbar_NotificationsWrapper__3Lfp9 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.Toolbar_TimeContainer__F2r8K {
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    cursor: pointer;
    display: inline-block;
    text-align: left;
    white-space: nowrap;
}



.Toolbar_Time__oKwr9 {
    display: inline-block;
    font-size: 38px;
    position: relative;
    vertical-align: middle;
    z-index: 5;
    color: #fff;
    font-weight: 300;
    letter-spacing: 1.5px;
    font-family: 'Open Sans';
}


.Toolbar_TimeHours__6biMV {
    display: inline-block;
    vertical-align: top;
}

.Toolbar_TimeSemicolon__2fM1P {
    display: inline-block;
    vertical-align: top;
}

.Toolbar_TimeMinutes__1oAad {
    display: inline-block;
    vertical-align: top;
}

.Toolbar_TimeAmPm__mE-4i {
    display: inline-block;
    font-size: 12px;
    line-height: 24px;
    margin-left: 0;
    vertical-align: top;
}

.Toolbar_TimemanRightSide__1upmN {
    color: #fff;
}

.Toolbar_TimemanRightSide__1upmN {
    display: inline-block;
    font-size: 12px;
    font-family: "OpenSans-Regular", sans-serif;
    padding: 0 0 0 13px;
    position: relative;
    text-transform: uppercase;
    vertical-align: middle;
    z-index: 5;
}

.Toolbar_TimemanContainer__U4VJj .Toolbar_TimemanBackground__3xu4t {
    width: auto;
    left: 0;
    right: 0;
}

.Toolbar_TimemanBackground__3xu4t {
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.Toolbar_TimemanInfo__5Zv3n {
    display: block;
    margin-bottom: 8px;
}

.Toolbar_TimemanTaskTime__2348O {
    padding-left: 17px;
}

.Toolbar_TimemanBeginningBut__3y1E-,
.Toolbar_TimemanTaskTime__2348O {
    display: block;
    padding-left: 20px;
    position: relative;
}

.Toolbar_planner-wrap__1Ou2V .Toolbar_timeman-event__3_tw8,
.Toolbar_planner-wrap__1Ou2V .Toolbar_timeman-tasks__1xX3h {
    display: block;
    margin: 3px 11px 3px 0;
    padding-left: 15px;
}

.Toolbar_TimemanTasks__dLN1Q {
    display: inline-block;
    vertical-align: top;
    position: relative;
}

.Toolbar_TimemanTaskTime__2348O {
    padding-left: 17px;
}

.Toolbar_TimemanBeginningBut__3y1E-,
.Toolbar_TimemanTaskTime__2348O {
    display: block;
    padding-left: 20px;
    position: relative;
}

.Toolbar_TimemanTasks__dLN1Q:before {
    background: url('data:image/svg+xml;charset=US-ASCII,<svg%20xmlns%3D"http%3A//www.w3.org/2000/svg"%20width%3D"6"%20height%3D"9"%20viewBox%3D"0%200%206%209">%0A%20%20<polygon%20fill%3D"%23fff"%20points%3D"6%200%200%200%200%209%201%209%201%205%206%205"/>%0A</svg>%0A') center center no-repeat;
}

.Toolbar_TimemanTasks__dLN1Q:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 6px;
    height: 9px;
    margin: 0 5px;
}
.SignedInMenu_Bold__3MS8X {
    font-weight: 800;
}

.SignedInMenu_bold__3kVr4 {
    font-weight: 600;
}


.SignedInMenu_LoginWrapper__BJzZU {
    border-right: solid 1px #E6E8EB;
    display: inline-block;
    margin: 0 4px;
    padding: 12px;
    vertical-align: middle;
    cursor: pointer;
    flex: 0 0 auto;
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    font-weight: 300;
    color: #002060;
}

.SignedInMenu_LoginWrapper__BJzZU .SignedInMenu_LoggedUser__2SI59 {
    font-weight: 600;
}

.SignedInMenu_SettingsHelpIconWrapper__13-G9 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.SignedInMenu_SettingsIconWrapper__3U17N {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}
.SignedOutMenu_Bold__C4sWI {
    font-weight: 800;
}

.SignedOutMenu_bold__2LePR {
    font-weight: 600;
}


.SignedOutMenu_LoginWrapper__2cYbK {
    border-right: solid 1px #E6E8EB;
    display: inline-block;
    margin: 0 4px;
    padding: 12px;
    vertical-align: middle;
    cursor: pointer;
    flex: 0 0 auto;
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    font-weight: 300;
    color: #002060;
}

.SignedOutMenu_LoginWrapper__2cYbK .SignedOutMenu_LoggedUser__1q1rF {
    font-weight: 600;
    color: #fff;
}

.SignedOutMenu_SettingsHelpIconWrapper__Y8IDB {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.SignedOutMenu_SettingsIconWrapper__97M1Z {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}
.NavigationItems_NavigationItems__33FTM {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    /* height: 100%; */
    /* padding-left: 24px; */
    color: rgb(0, 0, 0);
    line-height: 24px;
}

@media (min-width: 500px) {
    .NavigationItems_NavigationItems__33FTM {
        flex-flow: column;
    }
}

.NavigationItems_NavLink__3Wb6_ {
    /* margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%; */
    display: flex;
    line-height: 24px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'Roboto';
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}
/* 
 a {

    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    padding: 1.3rem 1.8rem;
    border-left: 6px solid transparent;
}


 a.hover {
    color: #292B2C;
    border-left: 6px solid #FFCC00;
}

 a:hover {
    background-color: #e8eaed;
    color: #292B2C;
}


a.Selected {
    color: #292B2C;
    border-left: 6px solid #FFCC00;
    background-color: #e8eaed;
    color: #292B2C;
} */
.NavigationItem_NavigationItem__33Eft {
    display: flex;
    line-height: 24px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'Roboto';
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.NavigationItem_NavigationItem__33Eft a {
    margin-left: 7.5px;
        text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    padding: 1.3rem 1.8rem;
    border-left: 6px solid transparent;
    color: #292B2C;
}


.NavigationItem_NavigationItem__33Eft a.NavigationItem_hover__3oj5c {
    color: #d7effb
}

.NavigationItem_NavigationItem__33Eft a:hover { 
    /* color: #292B2C;
    border-left: 6px solid #FFCC00;
    background-color: #d7effb; */
    margin-left: 7.5px;
    color: #292B2C;
    border: 0;
    border-left: 6px solid #4680A0;
    background-color: #FFF;
    border-color: #4680A0;
}
/* 
@media (min-width: 500px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }

    .NavigationItem a {
        color: #292B2C;
        height: 100%;
    }
} */

.NavigationItem_NavigationItem__33Eft a.NavigationItem_Selected__3QRGP {
    /* color: #292B2C;
    border-left: 6px solid #FFCC00;
    background-color: #d7effb; */
    margin-left: 7.5px;
    color: #292B2C;
    border: 0;
    /* border-left: 6px solid rgb(230, 236, 241);
    background-color: #FFF;
    border-color: rgb(230, 236, 241); */
    border-left: 6px solid #4680A0;
    background-color: #FFF;
    border-color: #4680A0;
}

.Logo_Logo__1IVKV {
    background-color: transparent;
    padding: 0;
    height: 4.8rem;
    box-sizing: border-box;
}

.Logo_Logo__1IVKV img {
    height: 4.8rem;
}
.Sidebar_flex-children__3wd1o {
    display: flex;
}

.Sidebar_left-nav-container__3F5qF {
    position: absolute;
    height: 100%;
    z-index: 2;
}

.Sidebar_flex-columns__3Xb0H>.Sidebar_flex-fixed__2oSu2 {
    flex: 0 0 auto;
}

.Sidebar_SidebarContainer__1XwUx {
    /* height: 100vh; */
    display: flex;
    flex: 0 0 auto;
    background-color: #f9f9f9;
    /* border-right: 1px solid #f1f3f4; */
    border-right: 1px solid #ddd;
    
}

.Sidebar_SidebarView__15qd5 {
    flex: auto;
    overflow-y: auto;
    overflow-x: hidden;
    width: 21.6rem;
    margin-top: 9.6rem;
}

.Sidebar_CompanyLogo__1tFW3 {
    /* height: 7.2rem; */
    line-height: 4.8rem;
    color: #fff;
    padding: 1.2rem 0;
    padding-left: 1.6rem;
}

.Sidebar_Bold__3eiLU {
    font-weight: 800;
}

.Sidebar_left-nav-view__ZbcS5 .Sidebar_primaryNavItem__1Au8d {
    font-family: "Avenir Next forINTUIT", "Arial", -apple-system, "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    width: 165px;
}
.Modal_close__2thuX {
    float: right;
    font-size: 1.5rem;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.Modal_close__2thuX {
    font-weight: 700;
    line-height: 1;
}

 .Modal_close__2thuX:not(:disabled):not(.Modal_disabled__26WP_) {
    cursor: pointer;
}

.Modal_pg-close__1Mu0f:before {
    content: "\E60A";
}

button.Modal_close__2thuX {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}

.MerchantForm_Wizard__3g5FK ul.MerchantForm_Progress__3mFTw {
    position: relative;
    list-style-type: none;
    margin: 20px 0px 0px;
    padding: 0px;
}

.MerchantForm_cf__1hfoD {
    zoom: 1;
}



.MerchantForm_Wizard__3g5FK ul.MerchantForm_Progress__3mFTw li {
    /* margin: 0px 2% 0px 0px;
    float: left;
    text-align: center;
    width: 32%;
    position: relative;
    z-index: 3; */

    margin: 0px 2% 0px 0px;
    float: left;
    text-align: center;
    width: 31.25%;
    position: relative;
    z-index: 3;
}

.MerchantForm_Active__3Fq_H {
    color: rgb(96, 96, 96);
    font-weight:500;
}



.MerchantForm_Step__MC5W9 {
    display: block;
    padding: 15px 12px 10px;
    font-size: 1.4rem;
    line-height: 23px;
    text-decoration: none;
    color: rgb(106, 106, 106);
    float: none;
}

.MerchantForm_Wizard__3g5FK ul.MerchantForm_Progress__3mFTw span.MerchantForm_Number__p_maN {
    display: block;
    margin: 0px auto 1rem;
    border-radius:  3.2rem;
    height:  3.2rem;
    width:  3.2rem;
    text-align: center;
    color: rgb(153, 153, 153);
    font-size: 18px;
    background: rgb(225, 225, 225);
    line-height:  3.2rem;
}

.MerchantForm_Wizard__3g5FK ul.MerchantForm_Progress__3mFTw li.MerchantForm_Future__12g1w span.MerchantForm_Number__p_maN {
    color: rgb(181, 181, 181);
}

.MerchantForm_Wizard__3g5FK ul.MerchantForm_Progress__3mFTw span.MerchantForm_Number__p_maN {
    background: rgb(243, 243, 243);
}

ul.MerchantForm_Progress__3mFTw li.MerchantForm_Active__3Fq_H span.MerchantForm_Number__p_maN {
    background: rgb(102, 51, 153);
    color: rgb(255, 255, 255);
}

em {
    font-style: normal;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    display: block;
    color: rgb(153, 153, 153);
}

.MerchantForm_Active__3Fq_H em {
    color: rgb(102, 102, 102);
}

.MerchantForm_Wizard__3g5FK ul.MerchantForm_Progress__3mFTw li.MerchantForm_Split__HDU4V {
    margin: 0px;
    float: left;
    width: 24%;
    position: absolute;
    top: 30px;
    height: 1px;
    background: rgb(225, 225, 225);
}

ul.MerchantForm_Progress__3mFTw li.MerchantForm_split__1ol-z {
    background: rgb(225, 225, 225);
}

.MerchantForm_Wizard__3g5FK ul.MerchantForm_Progress__3mFTw li.MerchantForm_Split__HDU4V.MerchantForm_One__bZId4 {
    left: 20%;
}

.MerchantForm_Wizard__3g5FK ul.MerchantForm_Progress__3mFTw li.MerchantForm_Split__HDU4V.MerchantForm_Two__2gjHn {
    left: 54%;
}

.MerchantForm_TabContent__3d6xZ {
    overflow: hidden;
    padding: 0.1rem;
}
.Merchants_Container__2JGX6 {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    /* height: 100%; */
}

.Merchants_BreadcrumbWrapper__2IAmQ {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Merchants_Content__3ie7C {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Merchants_CardBody__2HyTH {
    margin-top: 1rem;
}

.Merchants_Alert__3Wdak {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Merchants_Breadcrumb__2gLup {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Merchants_Header__3tPoq {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Merchants_ByLine__3A1KQ {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Merchants_Activity__3TcNR {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Merchants_WelcomeContainer__2my1C {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Merchants_LoggedUser__27qaD {
    display: flex;
    background: transparent;
    flex: 0 0 40rem;
}

@media (max-width: 1366px) {
    .Merchants_LoggedUser__27qaD {
        flex: 0 0 30rem;
    }
}

.Merchants_ProfilePic__3a-Mp {
    flex: 0 0 10.4rem;
}

.Merchants_SummaryContainer__2xeWG {
    display: flex;
    flex: 1 1 auto;
    margin-left: 2.4rem;
}

.Merchants_MembershipSummary__UjPMk {
    flex: 1 1 25.6rem;
}

.Merchants_SubscriptionSummary__1bpqB {
    flex: 1 1 25.6rem;
    margin-left: 2.4rem;
}

.Merchants_ClaimSummary__sdqmB {
    flex: 1 1 25.6rem;
    margin-left: 2.4rem;
}

.Merchants_ProfileImage__3X3Qf {
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    padding: 12px;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}


.Merchants_MerchantProfile__2hqDI {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}


.Merchants_Row__3Ydnq {

    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Merchants_Row__3Ydnq>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

/* .Container {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    border: 1px solid rgba(221,221,221,.7);
    flex-direction: column;
    font-size: 1.4rem;
} */

.Merchants_Tools__2jlmQ {
    display: flex;
    /* margin: 0 2.5rem;
    padding-top: 1.4rem; */
}

.Merchants_PageFilter__1jzAD {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

/* .ToolsWrapper {
    height: 6rem;
    width: 100%;

} */

.Merchants_PrintToolsWrapper__3trKV {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Merchants_AddNewMerchant__qEumW {
    /* // font-family: Cambay; */
}

.Merchants_AddNewMerchantBtn__35wmX {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Merchants_AddNewMerchantBtn__35wmX:focus,
.Merchants_AddNewMerchantBtn__35wmX:hover,
.Merchants_AddNewMerchantBtn__35wmX:active {
    background-color: rgb(51, 137, 255);
}


.Merchants_ToolsDataExportIconWrapper__3-z2S {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Merchants_ToolsPrintIconWrapper___1TUw {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Merchants_FilterInput__1zUTk {
    margin-right: 2rem;
}

.Merchants_FormGroup__277ZV {
    margin-bottom: 10px;
}

.Merchants_FormGroupDefault__sfKAp.Merchants_has-error__3s8nf {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Merchants_FormGroupDefault__sfKAp {

    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Merchants_FormGroupDefault__sfKAp label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Merchants_FormGroup__277ZV label:not(.Merchants_error__1_WMb) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Merchants_FormControl__Br5cq {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Merchants_MerchantTableWrapper__3SBss {
    margin-top: 3rem;
    white-space: nowrap;
}

.Merchants_TableWrapper__rlHOw {
    margin: 0 2.5rem;
}

.Merchants_MerchantTable__3vc47 {
    width: 100%;
    border-collapse: collapse;
}

.Merchants_MerchantTable__3vc47 th {
    text-align: left;
}

.Merchants_MerchantTable__3vc47 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Merchants_MerchantTable__3vc47 td,
.Merchants_MerchantTable__3vc47 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Merchants_MerchantTable__3vc47 td:last-child {

    border-right: 1px solid #e8e8e8;
}

.Merchants_RevealMoreHeader__2OtS6 {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Merchants_dataTables_paginate__ETJNy {
    float: right;
}

.Merchants_dataTables_paginate__ETJNy {
    /* margin-top: 25px !important; */
}

div.Merchants_dataTables_paginate__ETJNy {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Merchants_dataTables_paginate__ETJNy ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}


.Merchants_dataTables_paginate__ETJNy ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Merchants_dataTables_paginate__ETJNy ul>li.Merchants_disabled__2Papg a {
    opacity: .5;
}

.Merchants_dataTables_paginate__ETJNy ul>li.Merchants_disabled__2Papg a {
    opacity: .35;
}

.Merchants_dataTables_paginate__ETJNy ul>li.Merchants_active__uT6WH>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Merchants_dataTables_paginate__ETJNy ul>li.Merchants_next__FKxQc>a,
.Merchants_dataTables_paginate__ETJNy ul>li.Merchants_prev__d8_Fr>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Merchants_pg_arrow_right__3o9OV {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Merchants_pg_arrow_left__3z_rS {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Merchants_pg_arrow_right__3o9OV:before {
    content: "\E631";
}

.Merchants_pg_arrow_left__3z_rS:before {
    content: "\E629";
}

.Merchants_btn__10MRI {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Merchants_btn_primary__1b1f4,
.Merchants_btn_primary__1b1f4:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Merchants_btn_cons__3rhcv {
    min-width: 120px;
}



.Merchants_TabHeader__FyCVi {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Merchants_PageHeader__11Las {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Merchants_AddUser__257H4 {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Merchants_AddUserBtn__mEvze {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}
.MerchantList_Container__3MLlY {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    border: 1px solid rgba(221, 221, 221, .7);
    flex-direction: column;
    font-size: 1.4rem;
}

.MerchantList_Tools__3WPTC {
    display: flex;
    /* margin: 0 2.5rem;
    padding-top: 1.4rem; */
}

.MerchantList_PageFilter__20Mld {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

/* .ToolsWrapper {
    height: 6rem;
    width: 100%;

} */

.MerchantList_PrintToolsWrapper__2r1HM {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.MerchantList_AddNewMerchant__3i0IJ {
    /* // font-family: Cambay; */
}

.MerchantList_AddNewMerchantBtn__3c_PN {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.MerchantList_AddNewMerchantBtn__3c_PN:focus,
.MerchantList_AddNewMerchantBtn__3c_PN:hover,
.MerchantList_AddNewMerchantBtn__3c_PN:active {
    background-color: rgb(51, 137, 255);
}


.MerchantList_ToolsDataExportIconWrapper__3wKAe {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.MerchantList_ToolsPrintIconWrapper__1aLlP {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.MerchantList_FilterInput__2Mn8- {
    margin-right: 2rem;
}

.MerchantList_FormGroup__jnoWw {
    /* margin-bottom: 10px; */
}

.MerchantList_FormGroupDefault__15omO.MerchantList_has-error__3Lg6r {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.MerchantList_FormGroupDefault__15omO {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.MerchantList_FormGroupDefault__15omO label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.MerchantList_FormGroup__jnoWw label:not(.MerchantList_error__13bRy) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.MerchantList_FormControl__TDNLQ {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.MerchantList_MerchantTableWrapper__3_HAy {
    margin-top: 3rem;
    white-space: nowrap;
}

.MerchantList_TableWrapper__3hiVU {
    margin: 0 2.5rem;
}

.MerchantList_MerchantTable__1LWTp {
    width: 100%;
    border-collapse: collapse;
}

.MerchantList_MerchantTable__1LWTp th {
    text-align: left;
}

.MerchantList_MerchantTable__1LWTp th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.MerchantList_MerchantTable__1LWTp td,
.MerchantList_MerchantTable__1LWTp th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.MerchantList_MerchantTable__1LWTp td:last-child {

    border-right: 1px solid #e8e8e8;
}

.MerchantList_MerchantTable__1LWTp td:first-child {

    border-left: 1px solid #e8e8e8;
}

.MerchantList_RevealMoreHeader__3kOVe {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.MerchantList_Row__3KjTV {

    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.MerchantList_Row__3KjTV>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.MerchantList_dataTables_paginate__3vCYF {
    float: right;
}

.MerchantList_dataTables_paginate__3vCYF {
    /* margin-top: 25px !important; */
}

div.MerchantList_dataTables_paginate__3vCYF {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.MerchantList_dataTables_paginate__3vCYF ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.MerchantList_dataTables_paginate__3vCYF ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.MerchantList_dataTables_paginate__3vCYF ul>li.MerchantList_disabled__25jeh a {
    opacity: .5;
}

.MerchantList_dataTables_paginate__3vCYF ul>li.MerchantList_disabled__25jeh a {
    opacity: .35;
}

.MerchantList_dataTables_paginate__3vCYF ul>li.MerchantList_active__2vkda>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.MerchantList_dataTables_paginate__3vCYF ul>li.MerchantList_next__y13M0>a,
.MerchantList_dataTables_paginate__3vCYF ul>li.MerchantList_prev__1ye1w>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MerchantList_pg_arrow_right__2tszQ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MerchantList_pg_arrow_left__b8s7o {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MerchantList_pg_arrow_right__2tszQ:before {
    content: "\E631";
}

.MerchantList_pg_arrow_left__b8s7o:before {
    content: "\E629";
}

.MerchantList_btn__ma2ZP {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.MerchantList_btn_primary__1xkgU,
.MerchantList_btn_primary__1xkgU:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.MerchantList_btn_cons__sgDC8 {
    min-width: 120px;
}



.MerchantList_TabHeader__Zrvp_ {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.MerchantList_PageHeader__19uU5 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.MerchantList_AddUser__gWYe5 {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.MerchantList_AddUserBtn__3TgwF {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}
/* .Odd {
}

.Even {
} */

.MerchantListItem_MemberName__28wW5 {
    font-weight: 500;
}

.MerchantListItem_Icn__1h_zm {
    cursor: pointer;
    /* fill:#000; */
}

.MerchantListItem_Icn__1h_zm:hover {
    cursor: pointer;

}



.MerchantListItem_RevealMore__1jmeZ {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.MerchantListItem_RevealMore__1jmeZ:hover {
    background: transparent;
    color:#707070;
    fill:#fff;
    }

.MerchantListItem_RevealMore__1jmeZ div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.MerchantListItem_RevealMoreExpanded__2i11m {
    background: transparent;
    fill:#fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

 .MerchantListItem_Expandabled__250YT {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.MerchantListItem_Expandabled__250YT td {
    padding: 2rem 2rem 2rem 5.6rem ;
    border-bottom: 1px solid #e8e8e8;
}




 .MerchantListItem_MemberRow__3Mnze {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.MerchantListItem_MemberRow__3Mnze:hover {
    background-color: #267788;
    color:#fff;

    }

    .MerchantListItem_MemberRow__3Mnze:hover a {
        color:#fff;
        }

    .MerchantListItem_MemberRowExpanded__1BU6W {
        background: #267788;
        color:#fff;
    }

.MerchantListItem_table__29PX6.MerchantListItem_table-condensed__17-ck.MerchantListItem_table-detailed__1g0FU > tbody > tr.MerchantListItem_shown__2_Hmi > td {
    background: #fdf5df;
}

.Merchant_Container__1EbxX {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Merchant_BreadcrumbWrapper__3Ip_Z {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Merchant_Content__2O1O4 {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Merchant_Alert__1_AKv {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Merchant_Breadcrumb__1fyRA {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Merchant_Header__9GcBb {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Merchant_ByLine__2qM-U {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Merchant_Activity__1rhsb {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Merchant_WelcomeContainer__2kVNB {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Merchant_MerchantProfile__3aBeY {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

@media (max-width: 1366px) {
    .Merchant_LoggedUser__1O_1o {
        flex: 0 0 30rem;
    }
}

.Merchant_ProfilePic__1NmeH {
    flex: 0 0 10.4rem;
}

.Merchant_SummaryContainer__33INo {
    display: flex;
    flex: 1 1 auto;
    margin-left: 2.4rem;
}

.Merchant_MembershipSummary__9qUg3 {
    flex: 1 1 25.6rem;
}

.Merchant_SubscriptionSummary__269y8 {
    flex: 1 1 25.6rem;
    margin-left: 2.4rem;
}

.Merchant_ClaimSummary__36gnl {
    flex: 1 1 25.6rem;
    margin-left: 2.4rem;
}

.Merchant_ProfileImage__ku8VH {
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    padding: 12px;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}



.OutletInventory_Container__1vslS {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.OutletInventory_Tools__3_g5n {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.OutletInventory_PageFilter__3y-ro {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.OutletInventory_ToolsWrapper__3cOYx {
    height: 6rem;
    width: 100%;

}

.OutletInventory_PrintToolsWrapper__3oPwt {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.OutletInventory_AddNewMerchant__20hDo {
    /* // font-family: Cambay; */
}

.OutletInventory_AddNewMerchantBtn__1aAQA {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.OutletInventory_AddNewMerchantBtn__1aAQA:focus,
.OutletInventory_AddNewMerchantBtn__1aAQA:hover,
.OutletInventory_AddNewMerchantBtn__1aAQA:active {
    background-color: rgb(51, 137, 255);
}


.OutletInventory_ToolsDataExportIconWrapper__G3l5r {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.OutletInventory_ToolsPrintIconWrapper__c48jL {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.OutletInventory_FilterInput__u_SnR {
    margin-right: 2rem;
}

.OutletInventory_FormGroup__2r7Wy {
    /* margin-bottom: 10px; */
}

.OutletInventory_FormGroupDefault__xK_dh.OutletInventory_has-error__DiJcC {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.OutletInventory_FormGroupDefault__xK_dh {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.OutletInventory_FormGroupDefault__xK_dh label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.OutletInventory_FormGroup__2r7Wy label:not(.OutletInventory_error__NUMyK) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.OutletInventory_FormControl__3fIdY {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.OutletInventory_MerchantTableWrapper__3Mymi {
    margin-top: 2.5rem;
    white-space: nowrap;
}

/* 
.TableWrapper {
}

.MerchantTable {
    width: 100%;
    border-collapse: collapse;
} */


.OutletInventory_TableWrapper__V2jqH {
    display: flex;
}

.OutletInventory_MerchantTable__2sA23 {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.OutletInventory_MerchantTable__2sA23 th {
    text-align: left;
}

.OutletInventory_MerchantTable__2sA23 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.OutletInventory_MerchantTable__2sA23 td,
.OutletInventory_MerchantTable__2sA23 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.OutletInventory_MerchantTable__2sA23 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.OutletInventory_MerchantTable__2sA23 td:first-child {

    border-left: 1px solid #e8e8e8;
}

.OutletInventory_RevealMoreHeader__36nKu {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.OutletInventory_Row__3XyHk {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.OutletInventory_Row__3XyHk>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.OutletInventory_dataTables_paginate__3bnO- {
    float: right;
}

.OutletInventory_dataTables_paginate__3bnO- {
    /* margin-top: 25px !important; */
}

div.OutletInventory_dataTables_paginate__3bnO- {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.OutletInventory_dataTables_paginate__3bnO- ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.OutletInventory_dataTables_paginate__3bnO- ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.OutletInventory_dataTables_paginate__3bnO- ul>li.OutletInventory_disabled__1O-hM a {
    opacity: .5;
}

.OutletInventory_dataTables_paginate__3bnO- ul>li.OutletInventory_disabled__1O-hM a {
    opacity: .35;
}

.OutletInventory_dataTables_paginate__3bnO- ul>li.OutletInventory_active__1555b>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.OutletInventory_dataTables_paginate__3bnO- ul>li.OutletInventory_next__X3eDM>a,
.OutletInventory_dataTables_paginate__3bnO- ul>li.OutletInventory_prev__1z_Zv>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.OutletInventory_pg_arrow_right__3b96C {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.OutletInventory_pg_arrow_left__naTWa {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.OutletInventory_pg_arrow_right__3b96C:before {
    content: "\E631";
}

.OutletInventory_pg_arrow_left__naTWa:before {
    content: "\E629";
}

.OutletInventory_btn__2yT-8 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.OutletInventory_btn_primary__3xYLH,
.OutletInventory_btn_primary__3xYLH:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.OutletInventory_btn_cons__AHUgl {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.OutletInventory_MerchantRow__ImEk9 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.OutletInventory_MerchantRow__ImEk9:hover {
    background-color: #267788;
    color: #fff;

}

.OutletInventory_MerchantRow__ImEk9:hover a {
    color: #fff;
}

.OutletInventory_MerchantRowExpanded__1Hizz {
    background: #267788;
    color: #fff;
}

.OutletInventory_table__2UZ-A.OutletInventory_table-condensed__RVSZP.OutletInventory_table-detailed__F8_Jn>tbody>tr.OutletInventory_shown__1_6Bh>td {
    background: #fdf5df;
}

.OutletInventory_EnableDevice__2IMez {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.OutletInventory_EnableDeviceHeader__svKUd {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.OutletInventory_EnableDeviceBody__Dvp6q {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}
.OutletComputers_Container__1CpuT {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.OutletComputers_Tools__2hPh5 {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.OutletComputers_PageFilter__2u6rm {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.OutletComputers_ToolsWrapper__2NBgD {
    height: 6rem;
    width: 100%;

}

.OutletComputers_PrintToolsWrapper__3IA4A {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.OutletComputers_AddNewMerchant__SlRmy {
    /* // font-family: Cambay; */
}

.OutletComputers_AddNewMerchantBtn__2pL8T {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.OutletComputers_AddNewMerchantBtn__2pL8T:focus,
.OutletComputers_AddNewMerchantBtn__2pL8T:hover,
.OutletComputers_AddNewMerchantBtn__2pL8T:active {
    background-color: rgb(51, 137, 255);
}


.OutletComputers_ToolsDataExportIconWrapper__2FIk9 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.OutletComputers_ToolsPrintIconWrapper__1U4fI {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.OutletComputers_FilterInput__3QI3Y {
    margin-right: 2rem;
}

.OutletComputers_FormGroup__3_rab {
    /* margin-bottom: 10px; */
}

.OutletComputers_FormGroupDefault__2BKYY.OutletComputers_has-error__1fNXQ {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.OutletComputers_FormGroupDefault__2BKYY {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.OutletComputers_FormGroupDefault__2BKYY label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.OutletComputers_FormGroup__3_rab label:not(.OutletComputers_error__21wn4) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.OutletComputers_FormControl__2Ra-5 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.OutletComputers_MerchantTableWrapper__1qAVe {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.OutletComputers_TableWrapper__3aMAZ {
    display: flex;
}

.OutletComputers_MerchantTable__1gc90 {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.OutletComputers_MerchantTable__1gc90 th {
    text-align: left;
}





.OutletComputers_MerchantTable__1gc90 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.OutletComputers_MerchantTable__1gc90 td,
.OutletComputers_MerchantTable__1gc90 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.OutletComputers_MerchantTable__1gc90 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.OutletComputers_MerchantTable__1gc90 td:first-child {

    border-left: 1px solid #e8e8e8;
}

.OutletComputers_RevealMoreHeader__3iz6_ {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.OutletComputers_Row__3Lrlt {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.OutletComputers_Row__3Lrlt>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.OutletComputers_dataTables_paginate__1hWtA {
    float: right;
}

.OutletComputers_dataTables_paginate__1hWtA {
    /* margin-top: 25px !important; */
}

div.OutletComputers_dataTables_paginate__1hWtA {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.OutletComputers_dataTables_paginate__1hWtA ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.OutletComputers_dataTables_paginate__1hWtA ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.OutletComputers_dataTables_paginate__1hWtA ul>li.OutletComputers_disabled__3l3NI a {
    opacity: .5;
}

.OutletComputers_dataTables_paginate__1hWtA ul>li.OutletComputers_disabled__3l3NI a {
    opacity: .35;
}

.OutletComputers_dataTables_paginate__1hWtA ul>li.OutletComputers_active__3rmMJ>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.OutletComputers_dataTables_paginate__1hWtA ul>li.OutletComputers_next__3r2OG>a,
.OutletComputers_dataTables_paginate__1hWtA ul>li.OutletComputers_prev__Cpb4T>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.OutletComputers_pg_arrow_right__18wPD {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.OutletComputers_pg_arrow_left__NsUq2 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.OutletComputers_pg_arrow_right__18wPD:before {
    content: "\E631";
}

.OutletComputers_pg_arrow_left__NsUq2:before {
    content: "\E629";
}

.OutletComputers_btn__fRuev {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.OutletComputers_btn_primary__1FLbB,
.OutletComputers_btn_primary__1FLbB:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.OutletComputers_btn_cons__3YJje {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.OutletComputers_MerchantRow__2P5TD {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.OutletComputers_MerchantRow__2P5TD:hover {
    background-color: #267788;
    color: #fff;

}

.OutletComputers_MerchantRow__2P5TD:hover a {
    color: #fff;
}

.OutletComputers_MerchantRowExpanded__haHGB {
    background: #267788;
    color: #fff;
}

.OutletComputers_table__1J06C.OutletComputers_table-condensed__gGXRm.OutletComputers_table-detailed__3n9-D>tbody>tr.OutletComputers_shown__1ggT3>td {
    background: #fdf5df;
}

.OutletComputers_EnableComputer__jbcny {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.OutletComputers_EnableComputerHeader__z55Rt {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.OutletComputers_EnableComputerBody__2b6_F {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}




.Users_Container__kZpPy {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Users_Tools__1Glh2 {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Users_PageFilter__iTavF {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Users_ToolsWrapper__20CQQ {
    height: 6rem;
    width: 100%;

}

.Users_PrintToolsWrapper__M7c_B {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Users_AddNewMerchant__1fNnW {
    /* // font-family: Cambay; */
}

.Users_AddNewMerchantBtn__pBe5U {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.Users_AddNewMerchantBtn__pBe5U:focus,
.Users_AddNewMerchantBtn__pBe5U:hover,
.Users_AddNewMerchantBtn__pBe5U:active {
    background-color: rgb(51, 137, 255);
}

.Users_ToolsDataExportIconWrapper__PmCvK {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Users_ToolsPrintIconWrapper__3ylNc {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Users_FilterInput__lbQnh {
    margin-right: 2rem;
}

.Users_FormGroup__RL2s3 {
    /* margin-bottom: 10px; */
}

.Users_FormGroupDefault__22lkj.Users_has-error__3cgqr {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Users_FormGroupDefault__22lkj {
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Users_FormGroupDefault__22lkj label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Users_FormGroup__RL2s3 label:not(.Users_error__FDwsy) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Users_FormControl__30NKX {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Users_MerchantTableWrapper__2Sbj7 {
    margin-top: 2.5rem;
    white-space: nowrap;

}

.Users_TableWrapper__3IcLy {
    display: flex;
    /* background-color: #f5f5f5;
    border: 1px solid #e0e0e0; */
}


.Users_MerchantTable__4LR3c {
    flex: 1 1 70%;
    border-collapse: collapse;
    /* background-color: #f5f5f5; */
}

.Users_MerchantTable__4LR3c th {
    text-align: left;
}

.Users_MerchantTable__4LR3c th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
    height: 4rem;

}

.Users_MerchantTable__4LR3c td,
.Users_MerchantTable__4LR3c th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Users_MerchantTable__4LR3c td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Users_MerchantTable__4LR3c td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Users_EnableUser__345dE {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Users_EnableUserHeader__1ksWa {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Users_EnableUserBody__1UNyo {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}

/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Users_Row__4ZRXU {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Users_Row__4ZRXU>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Users_dataTables_paginate__3ybsj {
    float: right;
}

.Users_dataTables_paginate__3ybsj {
    /* margin-top: 25px !important; */
}

div.Users_dataTables_paginate__3ybsj {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Users_dataTables_paginate__3ybsj ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Users_dataTables_paginate__3ybsj ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Users_dataTables_paginate__3ybsj ul>li.Users_disabled__1SlYa a {
    opacity: .5;
}

.Users_dataTables_paginate__3ybsj ul>li.Users_disabled__1SlYa a {
    opacity: .35;
}

.Users_dataTables_paginate__3ybsj ul>li.Users_active__1E29C>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Users_dataTables_paginate__3ybsj ul>li.Users_next__1bwr3>a,
.Users_dataTables_paginate__3ybsj ul>li.Users_prev__1UfzA>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Users_pg_arrow_right__2qBdX {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Users_pg_arrow_left__1RCfn {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Users_pg_arrow_right__2qBdX:before {
    content: "\E631";
}

.Users_pg_arrow_left__1RCfn:before {
    content: "\E629";
}

.Users_btn__3w3vC {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Users_btn_primary__2Bw5T,
.Users_btn_primary__2Bw5T:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Users_btn_cons__Mncoc {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Users_MerchantRow__1TtW4 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Users_MerchantRow__1TtW4:hover {
    background-color: #267788;
    color: #fff;
    cursor: pointer;

}

.Users_MerchantRow__1TtW4:hover a {
    color: #fff;
}


.Users_SelectedTransaction__3GGAg {
    background-color: #267788;
    color: #fff;

}

.Users_MerchantRowExpanded__3wTlx {
    background: #267788;
    color: #fff;
}

.Users_table__20QJx.Users_table-condensed__3KoIY.Users_table-detailed__3vk2v>tbody>tr.Users_shown__2PR2P>td {
    background: #fdf5df;
}


.Tabs_Tabs__3COxo {
  background: transparent;
  margin-bottom: 3rem;
}

.Tabs_TabList__1Wczv {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.Tabs_TabListItem__3svwv {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Tabs_TabListActive__Qwbgq {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.Tabs_TabContent__21M57 {
  background: transparent;
  margin-right: 0;
  margin-left: 0;
}

.Tabs_FuneralSchemeMember__3B_vW {
  /* color: #666; */
  /* vertical-align: top; */
  font-size: 1.2rem;
  /* font-family: Raleway; */
  /* font-weight: 600; */
  /* vertical-align: middle; */
  padding: 1rem;
}
.Tab_TabList__2a0FT {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.Tab_TabListItem__3fLCU {
  /* display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem; */

  display: block;
  border-radius: 0;
  padding: 0.5rem 2rem;
  margin-right: 0;
  font-weight: 500;
  letter-spacing: .06em;
  color: #000;
  font-size: 85%;
  font-weight: 300;
  min-width: 14rem;
  text-transform: uppercase;
  text-align: center;
  border-color: transparent;
  position: relative;
  line-height: 1.8em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}



.Tab_TabListItem__3fLCU:after {
  position: absolute;
  -webkit-transition: all .1s linear 0s;
  transition: all .1s linear 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  display: block;
  background-color: #524f4f;
  height: 0;
  content: '';
  left: 0;
  z-index: 125;
  top: 100%;
}



.Tab_TabListActive__28yqF {
  background-color: white;
  font-weight: 700;
  /* border: solid #ccc;
    border-width: 1px 1px 0 1px; */
}

.Tab_TabListActive__28yqF:after,
.Tab_TabListItem__3fLCU:hover:after {
  height: 3px;
  font-weight: 700;
}

/* .nav-tabs>li>a {
    display: block;
    border-radius: 0;
    padding: 13px 20px;
    margin-right: 0;
    font-weight: 500;
    letter-spacing: .06em;
    color: rgba(87,87,87,.7);
    font-size: 10.5px;
    min-width: 70px;
    text-transform: uppercase;
    border-color: transparent;
    position: relative;
    line-height: 1.7em;
} */
.Outlet_Container__3cqlT {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Outlet_BreadcrumbWrapper__lMOGj {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Outlet_Content__1vq9t {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Outlet_Alert__W6UrB {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Outlet_Breadcrumb__1-5YD {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Outlet_Header__3YlUy {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Outlet_ByLine__3xvMq {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Outlet_Activity__1HMAQ {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Outlet_WelcomeContainer__RETSo {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Outlet_MerchantProfile__1f4ML {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

@media (max-width: 1366px) {
    .Outlet_LoggedUser__1CNxE {
        flex: 0 0 30rem;
    }
}

.Outlet_ProfilePic__aSxqH {
    flex: 0 0 10.4rem;
}

.Outlet_SummaryContainer__3iQ4i {
    display: flex;
    flex: 1 1 auto;
    margin-left: 2.4rem;
}

.Outlet_MembershipSummary__3YPRt {
    flex: 1 1 25.6rem;
}

.Outlet_SubscriptionSummary__A9mkG {
    flex: 1 1 25.6rem;
    margin-left: 2.4rem;
}

.Outlet_ClaimSummary__28SOk {
    flex: 1 1 25.6rem;
    margin-left: 2.4rem;
}

.Outlet_ProfileImage__2jPFL {
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    padding: 12px;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}


.Outlet_TabHeader__1hKfS {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Outlet_PageHeader__2m6Nz {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Outlet_AddUser__1Sudv {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Outlet_AddUserBtn__3-KKe {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.Outlet_Details__3gWZ4 {
    display: flex;
    margin-bottom: 1rem;
}

.Outlet_DetailsContent__1IgIN {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Outlet_DetailsEdit__3m3bj {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.025);
    /* border-left: 1px solid rgba(0, 0, 0, 0.05); */
    -webkit-animation: Outlet_slide__3RnNq 0.5s forwards;
            animation: Outlet_slide__3RnNq 0.5s forwards;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
}

.Outlet_FieldWrapper__OyoZD:hover {
    background-color: rgba(0, 0, 0, .12);
    padding: 0 1rem;
    cursor: pointer;

}
.Outlets_Container__1qw7- {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Outlets_Tools__1KXDn {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Outlets_PageFilter__2UlsC {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Outlets_ToolsWrapper__3HdeG {
    height: 6rem;
    width: 100%;

}

.Outlets_PrintToolsWrapper__j2cbO {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Outlets_AddNewMerchant__2_R9V {
    /* // font-family: Cambay; */
}

.Outlets_AddNewMerchantBtn__KmT6p {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Outlets_AddNewMerchantBtn__KmT6p:focus,
.Outlets_AddNewMerchantBtn__KmT6p:hover,
.Outlets_AddNewMerchantBtn__KmT6p:active {
    background-color: rgb(51, 137, 255);
}


.Outlets_ToolsDataExportIconWrapper__2Tp2s {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Outlets_ToolsPrintIconWrapper__kc34n {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Outlets_FilterInput__2xIwj {
    margin-right: 2rem;
}

.Outlets_FormGroup__1Vv21 {
    /* margin-bottom: 10px; */
}

.Outlets_FormGroupDefault__1xbj2.Outlets_has-error__2eRK8 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Outlets_FormGroupDefault__1xbj2 {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Outlets_FormGroupDefault__1xbj2 label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Outlets_FormGroup__1Vv21 label:not(.Outlets_error__3Vgo_) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Outlets_FormControl__3TZ7b {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Outlets_MerchantTableWrapper__3nF3a {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Outlets_TableWrapper__2mZ2j {
    /* margin: 0 2.5rem; */
}

.Outlets_MerchantTable__3F1iq {
    width: 100%;
    border-collapse: collapse;
}

.Outlets_MerchantTable__3F1iq th {
    text-align: left;
}

.Outlets_MerchantTable__3F1iq th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Outlets_MerchantTable__3F1iq td,
.Outlets_MerchantTable__3F1iq th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Outlets_MerchantTable__3F1iq td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Outlets_MerchantTable__3F1iq td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Outlets_RevealMoreHeader__3TfMD {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Outlets_Row__38Eh0 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Outlets_Row__38Eh0>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Outlets_dataTables_paginate__Oozcv {
    float: right;
}

.Outlets_dataTables_paginate__Oozcv {
    /* margin-top: 25px !important; */
}

div.Outlets_dataTables_paginate__Oozcv {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Outlets_dataTables_paginate__Oozcv ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Outlets_dataTables_paginate__Oozcv ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Outlets_dataTables_paginate__Oozcv ul>li.Outlets_disabled__2fmgc a {
    opacity: .5;
}

.Outlets_dataTables_paginate__Oozcv ul>li.Outlets_disabled__2fmgc a {
    opacity: .35;
}

.Outlets_dataTables_paginate__Oozcv ul>li.Outlets_active__2A9GQ>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Outlets_dataTables_paginate__Oozcv ul>li.Outlets_next__xA4yf>a,
.Outlets_dataTables_paginate__Oozcv ul>li.Outlets_prev__3UU2a>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Outlets_pg_arrow_right__meoJs {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Outlets_pg_arrow_left__3e3v4 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Outlets_pg_arrow_right__meoJs:before {
    content: "\E631";
}

.Outlets_pg_arrow_left__3e3v4:before {
    content: "\E629";
}

.Outlets_btn__2tXYM {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Outlets_btn_primary__1QQ0N,
.Outlets_btn_primary__1QQ0N:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Outlets_btn_cons__3fM3c {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Outlets_MerchantRow__1aBYj {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Outlets_MerchantRow__1aBYj:hover {
    background-color: #267788;
    color: #fff;

}

.Outlets_MerchantRow__1aBYj:hover a {
    color: #fff;
}

.Outlets_MerchantRowExpanded__3x7aP {
    background: #267788;
    color: #fff;
}

.Outlets_table__5GdUZ.Outlets_table-condensed__1giNR.Outlets_table-detailed__2mszS>tbody>tr.Outlets_shown__31fpF>td {
    background: #fdf5df;
}
.Documents_Container__3wJiV {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Documents_Tools__1xQt8 {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Documents_PageFilter__3aMHy {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Documents_ToolsWrapper__2du9g {
    height: 6rem;
    width: 100%;

}

.Documents_PrintToolsWrapper__1HZ6m {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Documents_AddNewMerchant__3qKtl {
    /* // font-family: Cambay; */
}

.Documents_AddNewMerchantBtn__5jkSc {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Documents_AddNewMerchantBtn__5jkSc:focus,
.Documents_AddNewMerchantBtn__5jkSc:hover,
.Documents_AddNewMerchantBtn__5jkSc:active {
    background-color: rgb(51, 137, 255);
}


.Documents_ToolsDataExportIconWrapper__o-J7M {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Documents_ToolsPrintIconWrapper__2GJ1k {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Documents_FilterInput__2Qu8R {
    margin-right: 2rem;
}

.Documents_FormGroup__jD1tW {
    /* margin-bottom: 10px; */
}

.Documents_FormGroupDefault__3srcv.Documents_has-error__1uL0G {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Documents_FormGroupDefault__3srcv {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Documents_FormGroupDefault__3srcv label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Documents_FormGroup__jD1tW label:not(.Documents_error__1TqGn) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Documents_FormControl__1TCiX {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Documents_MerchantTableWrapper__1oLuu {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Documents_TableWrapper__1LBDn {
    /* margin: 0 2.5rem; */
}

.Documents_MerchantTable__3kAqM {
    width: 100%;
    border-collapse: collapse;
}

.Documents_MerchantTable__3kAqM th {
    text-align: left;
}

.Documents_MerchantTable__3kAqM th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Documents_MerchantTable__3kAqM td,
.Documents_MerchantTable__3kAqM th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Documents_MerchantTable__3kAqM td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Documents_MerchantTable__3kAqM td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Documents_RevealMoreHeader__1pTT0 {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Documents_Row__wFzpU {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Documents_Row__wFzpU>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Documents_dataTables_paginate__2MusR {
    float: right;
}

.Documents_dataTables_paginate__2MusR {
    /* margin-top: 25px !important; */
}

div.Documents_dataTables_paginate__2MusR {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Documents_dataTables_paginate__2MusR ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Documents_dataTables_paginate__2MusR ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Documents_dataTables_paginate__2MusR ul>li.Documents_disabled__1gdKX a {
    opacity: .5;
}

.Documents_dataTables_paginate__2MusR ul>li.Documents_disabled__1gdKX a {
    opacity: .35;
}

.Documents_dataTables_paginate__2MusR ul>li.Documents_active__2o_fq>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Documents_dataTables_paginate__2MusR ul>li.Documents_next__1Yjx0>a,
.Documents_dataTables_paginate__2MusR ul>li.Documents_prev__3WA7D>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Documents_pg_arrow_right__3FlyN {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Documents_pg_arrow_left__1CSlH {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Documents_pg_arrow_right__3FlyN:before {
    content: "\E631";
}

.Documents_pg_arrow_left__1CSlH:before {
    content: "\E629";
}

.Documents_btn__2V4FP {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Documents_btn_primary__78d-G,
.Documents_btn_primary__78d-G:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Documents_btn_cons__hCkXt {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Documents_MerchantRow__3_0Nu {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Documents_MerchantRow__3_0Nu:hover {
    background-color: #267788;
    color: #fff;

}

.Documents_MerchantRow__3_0Nu:hover a {
    color: #fff;
}

.Documents_MerchantRowExpanded__3fmXx {
    background: #267788;
    color: #fff;
}

.Documents_table__5T-Y3.Documents_table-condensed__3nl4R.Documents_table-detailed__1k-6n>tbody>tr.Documents_shown__2NJrv>td {
    background: #fdf5df;
}
.CreditLimit_Container__33AmZ {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.CreditLimit_Tools__d7yBS {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.CreditLimit_PageFilter__1MPfX {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.CreditLimit_ToolsWrapper__2HNBI {
    height: 6rem;
    width: 100%;

}

.CreditLimit_PrintToolsWrapper__2vVth {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.CreditLimit_AddNewMerchant__2MbB3 {
    /* // font-family: Cambay; */
}

.CreditLimit_AddNewMerchantBtn__3FpgV {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.CreditLimit_AddNewMerchantBtn__3FpgV:focus,
.CreditLimit_AddNewMerchantBtn__3FpgV:hover,
.CreditLimit_AddNewMerchantBtn__3FpgV:active {
    background-color: rgb(51, 137, 255);
}


.CreditLimit_ToolsDataExportIconWrapper__1Hf2E {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.CreditLimit_ToolsPrintIconWrapper__1jiOy {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.CreditLimit_FilterInput__1-R-h {
    margin-right: 2rem;
}

.CreditLimit_FormGroup__1WrGD {
    /* margin-bottom: 10px; */
}

.CreditLimit_FormGroupDefault__2t2QL.CreditLimit_has-error__3f7e5 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.CreditLimit_FormGroupDefault__2t2QL {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.CreditLimit_FormGroupDefault__2t2QL label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.CreditLimit_FormGroup__1WrGD label:not(.CreditLimit_error__C1d8G) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.CreditLimit_FormControl__1f_Xg {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.CreditLimit_MerchantTableWrapper__3ErjT {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.CreditLimit_TableWrapper__27WSJ {
    /* margin: 0 2.5rem; */
}

.CreditLimit_MerchantTable__3FL1h {
    width: 100%;
    border-collapse: collapse;
}

.CreditLimit_MerchantTable__3FL1h th {
    text-align: left;
}

.CreditLimit_MerchantTable__3FL1h th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.CreditLimit_MerchantTable__3FL1h td,
.CreditLimit_MerchantTable__3FL1h th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.CreditLimit_MerchantTable__3FL1h td:last-child {

    border-right: 1px solid #e8e8e8;
}


.CreditLimit_MerchantTable__3FL1h td:first-child {

    border-left: 1px solid #e8e8e8;
}

.CreditLimit_RevealMoreHeader__1mFy- {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.CreditLimit_Row__3Tmv0 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.CreditLimit_Row__3Tmv0>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.CreditLimit_dataTables_paginate__ZTplf {
    float: right;
}

.CreditLimit_dataTables_paginate__ZTplf {
    /* margin-top: 25px !important; */
}

div.CreditLimit_dataTables_paginate__ZTplf {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.CreditLimit_dataTables_paginate__ZTplf ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.CreditLimit_dataTables_paginate__ZTplf ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.CreditLimit_dataTables_paginate__ZTplf ul>li.CreditLimit_disabled__2X5KL a {
    opacity: .5;
}

.CreditLimit_dataTables_paginate__ZTplf ul>li.CreditLimit_disabled__2X5KL a {
    opacity: .35;
}

.CreditLimit_dataTables_paginate__ZTplf ul>li.CreditLimit_active__3imrt>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.CreditLimit_dataTables_paginate__ZTplf ul>li.CreditLimit_next__1wdU2>a,
.CreditLimit_dataTables_paginate__ZTplf ul>li.CreditLimit_prev__3M91S>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.CreditLimit_pg_arrow_right__3Mnb6 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.CreditLimit_pg_arrow_left__2KADs {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.CreditLimit_pg_arrow_right__3Mnb6:before {
    content: "\E631";
}

.CreditLimit_pg_arrow_left__2KADs:before {
    content: "\E629";
}

.CreditLimit_btn__NBs0M {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.CreditLimit_btn_primary__mQ2sv,
.CreditLimit_btn_primary__mQ2sv:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.CreditLimit_btn_cons__3AzLu {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.CreditLimit_MerchantRow__zV8oz {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.CreditLimit_MerchantRow__zV8oz:hover {
    background-color: #267788;
    color: #fff;

}

.CreditLimit_MerchantRow__zV8oz:hover a {
    color: #fff;
}

.CreditLimit_MerchantRowExpanded__9hVtD {
    background: #267788;
    color: #fff;
}

.CreditLimit_table__eUke3.CreditLimit_table-condensed__l3Zjc.CreditLimit_table-detailed__2kEId>tbody>tr.CreditLimit_shown__2eWF0>td {
    background: #fdf5df;
}

.Providers_Container__zOl5F {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Providers_Tools__Ml31z {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Providers_PageFilter__2Y8eJ {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Providers_ToolsWrapper__2kVkR {
    height: 6rem;
    width: 100%;

}

.Providers_PrintToolsWrapper__1c01i {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Providers_AddNewMerchant__2ZM12 {
    /* // font-family: Cambay; */
}

.Providers_AddNewMerchantBtn__1vrQj {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Providers_AddNewMerchantBtn__1vrQj:focus,
.Providers_AddNewMerchantBtn__1vrQj:hover,
.Providers_AddNewMerchantBtn__1vrQj:active {
    background-color: rgb(51, 137, 255);
}


.Providers_ToolsDataExportIconWrapper__3jRw0 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Providers_ToolsPrintIconWrapper__2KAsR {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Providers_FilterInput__3NLIv {
    margin-right: 2rem;
}


.Providers_FormGroupDefault__pJZnp.Providers_has-error__TA4Jn {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Providers_FormGroupDefault__pJZnp {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Providers_FormGroupDefault__pJZnp label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Providers_FormGroup__va1tq label:not(.Providers_error__2J1zS) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Providers_FormControl__2za0R {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}




.Providers_MerchantTableWrapper__2OqKe {
    margin-top: 2.5rem;
    white-space: nowrap;

}

.Providers_TableWrapper__3YNkn {
    display: flex;
    /* background-color: #f5f5f5;
    border: 1px solid #e0e0e0; */
}


.Providers_MerchantTable__1agMR {
    flex: 1 1 70%;
    border-collapse: collapse;
    /* background-color: #f5f5f5; */
}

.Providers_MerchantTable__1agMR th {
    text-align: left;
}



.Providers_MerchantTable__1agMR th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Providers_MerchantTable__1agMR td,
.Providers_MerchantTable__1agMR th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Providers_MerchantTable__1agMR td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Providers_MerchantTable__1agMR td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Providers_RevealMoreHeader__1iWoK {
    padding: 0;
    vertical-align: middle;
}


.Providers_EnableUser__1F9a3 {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Providers_EnableUserHeader__1qdgv {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Providers_EnableUserBody__31z4c {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}



.Providers_Row__aZams {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Providers_Row__aZams>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Providers_dataTables_paginate__19R7F {
    float: right;
}

.Providers_dataTables_paginate__19R7F {
    /* margin-top: 25px !important; */
}

div.Providers_dataTables_paginate__19R7F {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Providers_dataTables_paginate__19R7F ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Providers_dataTables_paginate__19R7F ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Providers_dataTables_paginate__19R7F ul>li.Providers_disabled__3siKR a {
    opacity: .5;
}

.Providers_dataTables_paginate__19R7F ul>li.Providers_disabled__3siKR a {
    opacity: .35;
}

.Providers_dataTables_paginate__19R7F ul>li.Providers_active__y6jla>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Providers_dataTables_paginate__19R7F ul>li.Providers_next__PZj9a>a,
.Providers_dataTables_paginate__19R7F ul>li.Providers_prev__3DFgu>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Providers_pg_arrow_right__2j56V {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Providers_pg_arrow_left__1VdwQ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Providers_pg_arrow_right__2j56V:before {
    content: "\E631";
}

.Providers_pg_arrow_left__1VdwQ:before {
    content: "\E629";
}

.Providers_btn__2muJs {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Providers_btn_primary__xs8lY,
.Providers_btn_primary__xs8lY:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Providers_btn_cons__1aa6v {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Providers_MerchantRow__31s0E {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Providers_MerchantRow__31s0E:hover {
    background-color: #267788;
    color: #fff;

}

.Providers_MerchantRow__31s0E:hover a {
    color: #fff;
}

.Providers_MerchantRowExpanded__3kqRk {
    background: #267788;
    color: #fff;
}

.Providers_table__A775i.Providers_table-condensed__1iCJz.Providers_table-detailed__2iznp>tbody>tr.Providers_shown__2fKB3>td {
    background: #fdf5df;
}

.Providers_SelectedTransaction__8AUxJ {
    background-color: #267788;
    color: #fff;
}

.Providers_MerchantRowExpanded__3kqRk {
    background: #267788;
    color: #fff;
}

.Providers_table__A775i.Providers_table-condensed__1iCJz.Providers_table-detailed__2iznp>tbody>tr.Providers_shown__2fKB3>td {
    background: #fdf5df;
}



.User_Container__2Gb6j {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.User_Tools__13vb8 {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.User_PageFilter__2MOFA {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.User_ToolsWrapper__2_zig {
    height: 6rem;
    width: 100%;

}

.User_PrintToolsWrapper__15HIJ {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.User_AddNewMerchant__2SegF {
    /* // font-family: Cambay; */
}

.User_AddNewMerchantBtn__1EZq_ {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.User_AddNewMerchantBtn__1EZq_:focus,
.User_AddNewMerchantBtn__1EZq_:hover,
.User_AddNewMerchantBtn__1EZq_:active {
    background-color: rgb(51, 137, 255);
}

.User_ToolsDataExportIconWrapper__1CxP9 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.User_ToolsPrintIconWrapper__1Is12 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.User_FilterInput__vz9PT {
    margin-right: 2rem;
}

.User_FormGroup__15pii {
    /* margin-bottom: 10px; */
}

.User_FormGroupDefault__1cA9p.User_has-error__1ilDn {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.User_FormGroupDefault__1cA9p {
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.User_FormGroupDefault__1cA9p label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.User_FormGroup__15pii label:not(.User_error__2NtkU) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.User_FormControl__9oFCn {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.User_MerchantTableWrapper__2izn_ {
    margin-top: 2.5rem;
    white-space: nowrap;

}

.User_TableWrapper__3Xlly {
    display: flex;
    /* background-color: #f5f5f5;
    border: 1px solid #e0e0e0; */
}


.User_MerchantTable__ub2TX {
    flex: 1 1 70%;
    border-collapse: collapse;
    /* background-color: #f5f5f5; */
}

.User_MerchantTable__ub2TX th {
    text-align: left;
}

.User_MerchantTable__ub2TX th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
    height: 4rem;

}

.User_MerchantTable__ub2TX td,
.User_MerchantTable__ub2TX th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.User_MerchantTable__ub2TX td:last-child {

    border-right: 1px solid #e8e8e8;
}


.User_MerchantTable__ub2TX td:first-child {

    border-left: 1px solid #e8e8e8;
}

.User_EnableUser__eLDG8 {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.User_EnableUserHeader__2zDBp {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.User_EnableUserBody__34VZZ {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}

/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.User_Row__2fmHf {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.User_Row__2fmHf>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.User_dataTables_paginate__pB2OV {
    float: right;
}

.User_dataTables_paginate__pB2OV {
    /* margin-top: 25px !important; */
}

div.User_dataTables_paginate__pB2OV {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.User_dataTables_paginate__pB2OV ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.User_dataTables_paginate__pB2OV ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.User_dataTables_paginate__pB2OV ul>li.User_disabled__2lq6s a {
    opacity: .5;
}

.User_dataTables_paginate__pB2OV ul>li.User_disabled__2lq6s a {
    opacity: .35;
}

.User_dataTables_paginate__pB2OV ul>li.User_active__2p3k1>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.User_dataTables_paginate__pB2OV ul>li.User_next__12uL2>a,
.User_dataTables_paginate__pB2OV ul>li.User_prev__e4tWg>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.User_pg_arrow_right__3Tb7g {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.User_pg_arrow_left__FKGBt {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.User_pg_arrow_right__3Tb7g:before {
    content: "\E631";
}

.User_pg_arrow_left__FKGBt:before {
    content: "\E629";
}

.User_btn__IPdgX {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.User_btn_primary__3SPDQ,
.User_btn_primary__3SPDQ:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.User_btn_cons__1CoXc {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.User_MerchantRow__2B25L {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.User_MerchantRow__2B25L:hover {
    background-color: #267788;
    color: #fff;
    cursor: pointer;

}

.User_MerchantRow__2B25L:hover a {
    color: #fff;
}


.User_SelectedTransaction__1AwOu {
    background-color: #267788;
    color: #fff;

}

.User_MerchantRowExpanded__MScAT {
    background: #267788;
    color: #fff;
}

.User_table__3-9AM.User_table-condensed__11UPN.User_table-detailed__2rATy>tbody>tr.User_shown__3Xwjl>td {
    background: #fdf5df;
}
.MerchantProfile_CardBody__2Ji-1 {
    margin-top: 1rem;
}

.MerchantProfile_LabelStyle__Numkv {
    color: #000;
    font-weight: 400;
}

.MerchantProfile_IconWrapper__1VH-X {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 36px;
    width: auto;
}

.MerchantProfile_ExpandableOutlet__36xCP {
    float: left;
    text-decoration: none;
}

.MerchantProfile_ExpandableOutletName__3p3Bx {
    font-weight: 600;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle;
}

.MerchantProfile_AddNewOutlet__1XNg3 {
    float: right;
    margin-right: 8.8rem;
}

.MerchantProfile_AddNewOutletBtn__24um2 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.MerchantProfile_Outlets__3lOt6 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    /* margin-left: 0.6rem; */
}

.MerchantProfile_Outlets__3lOt6 li {
    display: inline-block;
    list-style: none;
}

.MerchantProfile_Outlet__nwtRz {
    display: inline-block;
    width: 15rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #FFF;
    margin-right: 1rem;
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
}

.MerchantProfile_Outlet__nwtRz:hover {
    color: #FFF;
    background: #5BA6E6;

}

.MerchantProfile_OutletName__3WdBL {
    font-weight: 600;
}

.MerchantProfile_OutletLocation__1DJ0V {
    font-weight: 400;

}

.MerchantProfile_TabHeader__30xWh {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.MerchantProfile_CardBody__2Ji-1 {
    margin-top: 3rem;
}

.MerchantProfile_PageHeader__2m1lM {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.MerchantProfile_AddUser__2l7Wx {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.MerchantProfile_AddUserBtn__2gstT {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.MerchantProfile_Details__1Ahyf {
    display: flex;
    margin-bottom: 1rem;
}

.MerchantProfile_DetailsContent__3QEeQ {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.MerchantProfile_DetailsEdit__1ehlV {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.025);
    /* border-left: 1px solid rgba(0, 0, 0, 0.05); */
    -webkit-animation: MerchantProfile_slide__1jA1l 0.5s forwards;
            animation: MerchantProfile_slide__1jA1l 0.5s forwards;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
}

.MerchantProfile_FieldWrapper__3ZlA0:hover {
    background-color: rgba(0, 0, 0, .12);
    padding: 0 1rem;
    cursor: pointer;

}

@-webkit-keyframes MerchantProfile_slide__1jA1l {
    100% {
        left: 0;
    }
}

@keyframes MerchantProfile_slide__1jA1l {
    100% {
        left: 0;
    }
}

.MerchantProfile_MerchantProfile__3UEkb {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}


.MerchantProfile_FilterInput__1ZIOg {
    margin-right: 2rem;
}

.MerchantProfile_FormGroup__1L471 {
    margin-bottom: 10px;
}

.MerchantProfile_FormGroupDefault__1h_tt.MerchantProfile_has-error__2Tp65 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.MerchantProfile_FormGroupDefault__1h_tt {

    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

.MerchantProfile_PageFilter__1rYDj {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
    padding-left: 2rem;
    margin-top: 10px;
}

.MerchantProfile_PageTitle__3YVYj {
    border-right: 1px solid #707070;
    padding-right: 2rem;
    font-weight: 700;
    margin-top: 10px;
}

.MerchantProfile_FilterInput__1ZIOg {
    margin-right: 2rem;
}

.MerchantProfile_FormGroup__1L471 {
    margin-bottom: 10px;
}

.MerchantProfile_FormGroupDefault__1h_tt.MerchantProfile_has-error__2Tp65 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.MerchantProfile_FormGroupDefault__1h_tt {

    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.MerchantProfile_FormGroupDefault__1h_tt label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.MerchantProfile_FormGroup__1L471 label:not(.MerchantProfile_error__Z8DET) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.MerchantProfile_FormControl__q6noB {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}
.financials_Container__2Q_pL {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.financials_BreadcrumbWrapper__3QjQN {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.financials_Content__1X83f {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.financials_CardBody__IfcVQ {
    margin-top: 1rem;
}

.financials_Alert__g3_VI {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.financials_Breadcrumb__2zWkS {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.financials_Header__cnfJW {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.financials_ByLine__z2x8_ {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.financials_Activity__E3nC9 {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.financials_WelcomeContainer__9TTZ8 {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.financials_MerchantProfile__20rVT {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.financials_TabHeader__3legx {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.financials_PageHeader__28r8L {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.financials_Header__cnfJW {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.financials_ExportReport__3x143 {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.financials_ExportIconWrapper__1vVy9 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.financials_EmailIconWrapper__ehYFf {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.financials_AddUser__kc7Na {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.financials_AddUserBtn__57UvV {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.financials_Tools__1GKEI {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.financials_PageFilter__B3VOV {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.financials_PrintToolsWrapper__28XeG {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.financials_FilterReportBtn__2I89k {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.financials_AddNewMerchantBtn__1vqfQ:focus,
.financials_AddNewMerchantBtn__1vqfQ:hover,
.financials_AddNewMerchantBtn__1vqfQ:active {
    background-color: rgb(51, 137, 255);
}


.financials_ToolsDataExportIconWrapper__10FHI {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.financials_ToolsPrintIconWrapper__2xsU6 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.financials_FilterInput__2xw4c {
    margin-right: 2rem;
}

.financials_FormGroupDefault__1g94E.financials_has-error__5_nxI {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.financials_FormGroupDefault__1g94E {

    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.financials_FormGroupDefault__1g94E label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.financials_FormGroup__2G7Z_ label:not(.financials_error__3HCC1) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.financials_FormControl__yAObN {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.financials_MerchantTableWrapper__2koHl {
    margin-top: 3rem;
    white-space: nowrap;
}

.financials_MerchantTable__388ka {
    width: 100%;
    border-collapse: collapse;
}

.financials_MerchantTable__388ka th {
    text-align: left;
}

.financials_MerchantTable__388ka th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.financials_MerchantTable__388ka td,
.financials_MerchantTable__388ka th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.financials_RevealMoreHeader__1Lft5 {
    padding: 0;
    vertical-align: middle;
}

.financials_Row__2kA3x {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.financials_Row__2kA3x>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.financials_dataTables_paginate__299Sp {
    float: right;
}

div.financials_dataTables_paginate__299Sp {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.financials_dataTables_paginate__299Sp ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.financials_dataTables_paginate__299Sp ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.financials_dataTables_paginate__299Sp ul>li.financials_disabled__1vrCF a {
    opacity: .5;
}

.financials_dataTables_paginate__299Sp ul>li.financials_disabled__1vrCF a {
    opacity: .35;
}

.financials_dataTables_paginate__299Sp ul>li.financials_active__2mItr>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.financials_dataTables_paginate__299Sp ul>li.financials_next__6tBKr>a,
.financials_dataTables_paginate__299Sp ul>li.financials_prev__1lsrq>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.financials_pg_arrow_right__14nXm {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.financials_pg_arrow_left__nJvXj {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.financials_pg_arrow_right__14nXm:before {
    content: "\E631";
}

.financials_pg_arrow_left__nJvXj:before {
    content: "\E629";
}

.financials_btn__2wx64 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.financials_btn_primary__3pDaR,
.financials_btn_primary__3pDaR:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.financials_btn_cons__3pabH {
    min-width: 120px;
}




.financials_MerchantName__3LLKV {
    font-weight: 500;
}

.financials_Icn__DDoIy {
    cursor: pointer;
    /* fill:#000; */
}

.financials_Icn__DDoIy:hover {
    cursor: pointer;

}



.financials_RevealMore__3maG8 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.financials_RevealMore__3maG8:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.financials_RevealMore__3maG8 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.financials_RevealMoreExpanded__38cRQ {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.financials_Expandabled__tpk47 {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.financials_Expandabled__tpk47 td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.financials_MerchantTable__388ka td:last-child {

    border-right: 1px solid #e8e8e8;
}


.financials_MerchantTable__388ka td:first-child {

    border-left: 1px solid #e8e8e8;
}


.financials_MerchantRow__31auB {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.financials_MerchantRow__31auB:hover {
    background-color: #267788;
    color: #fff;

}

.financials_MerchantRow__31auB:hover a {
    color: #fff;
}

.financials_MerchantRowExpanded__2sX4n {
    background: #267788;
    color: #fff;
}

.financials_table__5NI1b.financials_table-condensed__Gccy4.financials_table-detailed__2vYxx>tbody>tr.financials_shown__E9eSy>td {
    background: #fdf5df;
}


.financials_Totals__LhUM9 td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.financials_MoneyTotalLabel__9kwby {
    float: right;

}

.financials_FilterWrapper__3DXEr {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.financials_FilterPeriods__3oFES {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.financials_FilterPeriods__3oFES li {
    font-weight: 600;

    padding-left: 4.8rem;
    line-height: 38px;
}

.financials_FinancialsIntro__2pG0y {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}
.Deposits_Container__2GuVk {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Deposits_Tools__8gJj_ {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Deposits_PageFilter__3Dj91 {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Deposits_ToolsWrapper__30R1m {
    height: 6rem;
    width: 100%;

}

.Deposits_TabHeader__NT9Bh {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Deposits_Header__3V65g {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Deposits_ExportReport__3sIsb {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Deposits_ExportIconWrapper__3ShFo {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Deposits_EmailIconWrapper__1fGBn {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Deposits_PrintToolsWrapper__2aHVZ {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Deposits_AddNewMerchant__9Lri0 {
    /* // font-family: Cambay; */
}

.Deposits_AddNewMerchantBtn__3-S65 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Deposits_AddNewMerchantBtn__3-S65:focus,
.Deposits_AddNewMerchantBtn__3-S65:hover,
.Deposits_AddNewMerchantBtn__3-S65:active {
    background-color: rgb(51, 137, 255);
}


.Deposits_ToolsDataExportIconWrapper__24hH9 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Deposits_ToolsPrintIconWrapper__wWjZJ {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Deposits_FilterInput__j0bOe {
    margin-right: 2rem;
}

.Deposits_FormGroup__3HDQQ {
    /* margin-bottom: 10px; */
}

.Deposits_FormGroupDefault__21Jez.Deposits_has-error__DB_DQ {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Deposits_FormGroupDefault__21Jez {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Deposits_FormGroupDefault__21Jez label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Deposits_FormGroup__3HDQQ label:not(.Deposits_error__2MOkY) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Deposits_FormControl__147a3 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Deposits_MerchantTableWrapper__2Lzkf {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Deposits_TableWrapper__LdXVV {
    display: flex;
}

.Deposits_MerchantTable__3DZOE {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Deposits_MerchantTable__3DZOE th {
    text-align: left;
}

.Deposits_MerchantTable__3DZOE th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Deposits_MerchantTable__3DZOE td,
.Deposits_MerchantTable__3DZOE th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Deposits_MerchantTable__3DZOE td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Deposits_MerchantTable__3DZOE td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Deposits_RevealMoreHeader__1KKeB {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Deposits_Row__1jmgF {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Deposits_Row__1jmgF>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Deposits_dataTables_paginate__1jo2d {
    float: right;
}

.Deposits_dataTables_paginate__1jo2d {
    /* margin-top: 25px !important; */
}

div.Deposits_dataTables_paginate__1jo2d {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Deposits_dataTables_paginate__1jo2d ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Deposits_dataTables_paginate__1jo2d ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Deposits_dataTables_paginate__1jo2d ul>li.Deposits_disabled__yCQU4 a {
    opacity: .5;
}

.Deposits_dataTables_paginate__1jo2d ul>li.Deposits_disabled__yCQU4 a {
    opacity: .35;
}

.Deposits_dataTables_paginate__1jo2d ul>li.Deposits_active__1b1ng>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Deposits_dataTables_paginate__1jo2d ul>li.Deposits_next__1Te7q>a,
.Deposits_dataTables_paginate__1jo2d ul>li.Deposits_prev__2-Oal>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_right__1iipp {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_left__2ZVmd {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_right__1iipp:before {
    content: "\E631";
}

.Deposits_pg_arrow_left__2ZVmd:before {
    content: "\E629";
}

.Deposits_btn__1A5AK {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Deposits_btn_primary__1_SFV,
.Deposits_btn_primary__1_SFV:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Deposits_btn_cons__2tLK3 {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Deposits_MerchantRow__3oGPZ {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Deposits_MerchantRow__3oGPZ:hover {
    background-color: #267788;
    color: #fff;

}

.Deposits_MerchantRow__3oGPZ:hover a {
    color: #fff;
}

.Deposits_MerchantRowExpanded__25xIn {
    background: #267788;
    color: #fff;
}

.Deposits_table__17tvv.Deposits_table-condensed__2-0mP.Deposits_table-detailed__2HAIR>tbody>tr.Deposits_shown__2MaSx>td {
    background: #fdf5df;
}

.Deposits_EnableMerchant__1C_u5 {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Deposits_EnableMerchantHeader__3whqH {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Deposits_EnableMerchantBody__22HEB {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}


.Deposits_MoneyTotalLabel__1yPan {
    float: right;
    font-weight: 600;
}

.Deposits_MoneyTotal__1RNay {
    font-weight: 600;
}
.Reversals_Container__2h7ty {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Reversals_Tools__3qN-c {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Reversals_PageFilter__2bNMF {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Reversals_ToolsWrapper__2-QJk {
    height: 6rem;
    width: 100%;

}

.Reversals_TabHeader__3UuR8 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Reversals_Header__2GODk {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Reversals_ExportReport__380UM {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Reversals_ExportIconWrapper__2xYU1 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Reversals_EmailIconWrapper__2V-VG {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Reversals_PrintToolsWrapper__s0geE {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Reversals_AddNewMerchant__3aDwl {
    /* // font-family: Cambay; */
}

.Reversals_AddNewMerchantBtn__1W9Xr {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Reversals_AddNewMerchantBtn__1W9Xr:focus,
.Reversals_AddNewMerchantBtn__1W9Xr:hover,
.Reversals_AddNewMerchantBtn__1W9Xr:active {
    background-color: rgb(51, 137, 255);
}


.Reversals_ToolsDataExportIconWrapper__1KZN1 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Reversals_ToolsPrintIconWrapper__1L7l7 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Reversals_FilterInput__OL1TZ {
    margin-right: 2rem;
}

.Reversals_FormGroup__3kBYY {
    /* margin-bottom: 10px; */
}

.Reversals_FormGroupDefault__1nUFj.Reversals_has-error__32HnV {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Reversals_FormGroupDefault__1nUFj {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Reversals_FormGroupDefault__1nUFj label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Reversals_FormGroup__3kBYY label:not(.Reversals_error__22eV4) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Reversals_FormControl__caRuC {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Reversals_MerchantTableWrapper__1qJr0 {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Reversals_TableWrapper__3FHUG {
    display: flex;
}

.Reversals_MerchantTable__3IJjb {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Reversals_MerchantTable__3IJjb th {
    text-align: left;
}

.Reversals_MerchantTable__3IJjb th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Reversals_MerchantTable__3IJjb td,
.Reversals_MerchantTable__3IJjb th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Reversals_MerchantTable__3IJjb td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Reversals_MerchantTable__3IJjb td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Reversals_RevealMoreHeader__1W2MU {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Reversals_Row__3pg7m {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Reversals_Row__3pg7m>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Reversals_dataTables_paginate__3SbeY {
    float: right;
}

.Reversals_dataTables_paginate__3SbeY {
    /* margin-top: 25px !important; */
}

div.Reversals_dataTables_paginate__3SbeY {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Reversals_dataTables_paginate__3SbeY ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Reversals_dataTables_paginate__3SbeY ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Reversals_dataTables_paginate__3SbeY ul>li.Reversals_disabled__2fLeg a {
    opacity: .5;
}

.Reversals_dataTables_paginate__3SbeY ul>li.Reversals_disabled__2fLeg a {
    opacity: .35;
}

.Reversals_dataTables_paginate__3SbeY ul>li.Reversals_active__3aDhf>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Reversals_dataTables_paginate__3SbeY ul>li.Reversals_next__1ipiB>a,
.Reversals_dataTables_paginate__3SbeY ul>li.Reversals_prev__3T-8a>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Reversals_pg_arrow_right__3d5wC {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Reversals_pg_arrow_left__d-EYz {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Reversals_pg_arrow_right__3d5wC:before {
    content: "\E631";
}

.Reversals_pg_arrow_left__d-EYz:before {
    content: "\E629";
}

.Reversals_btn__5IuKK {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Reversals_btn_primary__rj1iv,
.Reversals_btn_primary__rj1iv:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Reversals_btn_cons__3m26c {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Reversals_MerchantRow__Nqyzc {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Reversals_MerchantRow__Nqyzc:hover {
    background-color: #267788;
    color: #fff;

}

.Reversals_MerchantRow__Nqyzc:hover a {
    color: #fff;
}

.Reversals_MerchantRowExpanded__1nkbr {
    background: #267788;
    color: #fff;
}

.Reversals_table__1iGlS.Reversals_table-condensed__15rVc.Reversals_table-detailed__34LxH>tbody>tr.Reversals_shown__2WMKl>td {
    background: #fdf5df;
}

.Reversals_EnableMerchant__slxpm {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Reversals_EnableMerchantHeader__3luaf {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Reversals_EnableMerchantBody__2BRyk {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}


.Reversals_MoneyTotalLabel__lsYwi {
    float: right;
    font-weight: 600;
}

.Reversals_MoneyTotal__19W24 {
    font-weight: 600;
}
.Summary_Container__2xbz9 {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Summary_Tools__VwItL {
    display: flex;
    /* margin: 0 2.5rem; */
    /* padding-top: 1.4rem; */
}

.Summary_PageFilter__jMIdi {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.Summary_ToolsWrapper__12Ahk {
    height: 6rem;
    width: 100%;

}

.Summary_PrintToolsWrapper__U3SYQ {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Summary_AddNewMerchant__byeE_ {
    /* // font-family: Cambay; */
}

.Summary_FilterReportBtn__2dwUa {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Summary_AddNewMerchantBtn__1m2J9:focus,
.Summary_AddNewMerchantBtn__1m2J9:hover,
.Summary_AddNewMerchantBtn__1m2J9:active {
    background-color: rgb(51, 137, 255);
}


.Summary_ToolsDataExportIconWrapper__yPk3I {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Summary_ToolsPrintIconWrapper__3wgy6 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Summary_FilterInput__DcGFI {
    margin-right: 2rem;
}

.Summary_FormGroup__2VweL {
    /* margin-bottom: 10px; */
}

.Summary_FormGroupDefault__360KB.Summary_has-error__17gSv {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Summary_FormGroupDefault__360KB {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Summary_FormGroupDefault__360KB label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Summary_FormGroup__2VweL label:not(.Summary_error__1f6Ll) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Summary_FormControl__26u8y {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Summary_MerchantTableWrapper__2MOA6 {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Summary_TableWrapper__1HNX6 {
    /* margin: 0 2.5rem; */
}

.Summary_MerchantTable__6C6js {
    width: 50%;
    border-collapse: collapse;
}

.Summary_MerchantTable__6C6js th {
    text-align: left;
}

.Summary_MerchantTable__6C6js th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Summary_MerchantTable__6C6js td,
.Summary_MerchantTable__6C6js th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Summary_RevealMoreHeader__8C0U9 {
    padding: 0;
    vertical-align: middle;
}





.Summary_Row__13J7X {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Summary_Row__13J7X>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Summary_dataTables_paginate__2RCqw {
    float: right;
}

.Summary_dataTables_paginate__2RCqw {
    /* margin-top: 25px !important; */
}

div.Summary_dataTables_paginate__2RCqw {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Summary_dataTables_paginate__2RCqw ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Summary_dataTables_paginate__2RCqw ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Summary_dataTables_paginate__2RCqw ul>li.Summary_disabled__34wIo a {
    opacity: .5;
}

.Summary_dataTables_paginate__2RCqw ul>li.Summary_disabled__34wIo a {
    opacity: .35;
}

.Summary_dataTables_paginate__2RCqw ul>li.Summary_active__1Ogb7>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Summary_dataTables_paginate__2RCqw ul>li.Summary_next__3hTvf>a,
.Summary_dataTables_paginate__2RCqw ul>li.Summary_prev__3olk7>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Summary_pg_arrow_right__8Houo {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Summary_pg_arrow_left__2tlTt {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Summary_pg_arrow_right__8Houo:before {
    content: "\E631";
}

.Summary_pg_arrow_left__2tlTt:before {
    content: "\E629";
}

.Summary_btn__LImAO {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Summary_btn_primary__OND0I,
.Summary_btn_primary__OND0I:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Summary_btn_cons__2ZPzS {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Summary_MerchantName__23DC7 {
    font-weight: 500;
}

.Summary_Icn__2Epkv {
    cursor: pointer;
    /* fill:#000; */
}

.Summary_Icn__2Epkv:hover {
    cursor: pointer;

}



.Summary_RevealMore__3UEvL {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Summary_RevealMore__3UEvL:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Summary_RevealMore__3UEvL div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Summary_RevealMoreExpanded__2mPW3 {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Summary_Expandabled__cPa8g {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Summary_Expandabled__cPa8g td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Summary_MerchantTable__6C6js td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Summary_MerchantTable__6C6js td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Summary_MerchantRow__1a0fl {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Summary_MerchantRow__1a0fl:hover {
    background-color: #267788;
    color: #fff;

}

.Summary_MerchantRow__1a0fl:hover a {
    color: #fff;
}

.Summary_MerchantRowExpanded__3fhZS {
    background: #267788;
    color: #fff;
}

.Summary_table__cGk5I.Summary_table-condensed__3T2Fg.Summary_table-detailed__3DFMM>tbody>tr.Summary_shown__1UjSJ>td {
    background: #fdf5df;
}


.Summary_Totals__2JWdo td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Summary_MoneyTotalLabel__7NeRx {
    float: right;

}
.Commissions_Container__ipJEK {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Commissions_Tools__vX5IB {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Commissions_PageFilter__2wcDf {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Commissions_ToolsWrapper__37mtr {
    height: 6rem;
    width: 100%;

}

.Commissions_TabHeader__1vl7L {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Commissions_Header__hf-AJ {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Commissions_ExportReport__Yn1r_ {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Commissions_ExportIconWrapper__gWsJF {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Commissions_EmailIconWrapper__mfZF7 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Commissions_PrintToolsWrapper__381kb {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Commissions_AddNewMerchant__2TZfW {
    /* // font-family: Cambay; */
}

.Commissions_AddNewMerchantBtn__djqUb {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Commissions_AddNewMerchantBtn__djqUb:focus,
.Commissions_AddNewMerchantBtn__djqUb:hover,
.Commissions_AddNewMerchantBtn__djqUb:active {
    background-color: rgb(51, 137, 255);
}


.Commissions_ToolsDataExportIconWrapper__2xnhE {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Commissions_ToolsPrintIconWrapper__1tLKj {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Commissions_FilterInput__1fb_0 {
    margin-right: 2rem;
}

.Commissions_FormGroup__1_VLG {
    /* margin-bottom: 10px; */
}

.Commissions_FormGroupDefault__2oLRd.Commissions_has-error__39aKU {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Commissions_FormGroupDefault__2oLRd {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Commissions_FormGroupDefault__2oLRd label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Commissions_FormGroup__1_VLG label:not(.Commissions_error__3Rmzz) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Commissions_FormControl__Ckilg {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Commissions_MerchantTableWrapper__38Vdx {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Commissions_TableWrapper__1bGtS {
    display: flex;
}

.Commissions_MerchantTable__2Y9kF {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Commissions_MerchantTable__2Y9kF th {
    text-align: left;
}

.Commissions_MerchantTable__2Y9kF th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Commissions_MerchantTable__2Y9kF td,
.Commissions_MerchantTable__2Y9kF th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Commissions_MerchantTable__2Y9kF td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Commissions_MerchantTable__2Y9kF td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Commissions_RevealMoreHeader__v0t6u {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Commissions_Row__1tUMS {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Commissions_Row__1tUMS>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Commissions_dataTables_paginate__31wfK {
    float: right;
}

.Commissions_dataTables_paginate__31wfK {
    /* margin-top: 25px !important; */
}

div.Commissions_dataTables_paginate__31wfK {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Commissions_dataTables_paginate__31wfK ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Commissions_dataTables_paginate__31wfK ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Commissions_dataTables_paginate__31wfK ul>li.Commissions_disabled__tNXbm a {
    opacity: .5;
}

.Commissions_dataTables_paginate__31wfK ul>li.Commissions_disabled__tNXbm a {
    opacity: .35;
}

.Commissions_dataTables_paginate__31wfK ul>li.Commissions_active__1bEks>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Commissions_dataTables_paginate__31wfK ul>li.Commissions_next__1H04J>a,
.Commissions_dataTables_paginate__31wfK ul>li.Commissions_prev__lWdL0>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commissions_pg_arrow_right__2kKMm {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commissions_pg_arrow_left__3XjpC {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commissions_pg_arrow_right__2kKMm:before {
    content: "\E631";
}

.Commissions_pg_arrow_left__3XjpC:before {
    content: "\E629";
}

.Commissions_btn__1mWry {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Commissions_btn_primary__27Zl0,
.Commissions_btn_primary__27Zl0:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Commissions_btn_cons__277nG {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Commissions_MerchantRow__3qSYy {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Commissions_MerchantRow__3qSYy:hover {
    background-color: #267788;
    color: #fff;

}

.Commissions_MerchantRow__3qSYy:hover a {
    color: #fff;
}

.Commissions_MerchantRowExpanded__25Xr0 {
    background: #267788;
    color: #fff;
}

.Commissions_table__26MgX.Commissions_table-condensed__mGHyX.Commissions_table-detailed__3sXB2>tbody>tr.Commissions_shown__QQRoX>td {
    background: #fdf5df;
}

.Commissions_EnableMerchant__3zUcR {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Commissions_EnableMerchantHeader__qEI4g {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Commissions_EnableMerchantBody__2JyC- {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}


.Commissions_MoneyTotalLabel__2dhLF {
    /* float: right; */
    font-weight: 600;
}

.Commissions_MoneyTotal__3klBm {
    font-weight: 600;
}
.Withdrawals_Container__2ks44 {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Withdrawals_Tools__30915 {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Withdrawals_PageFilter__20_aY {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Withdrawals_ToolsWrapper__-WkLS {
    height: 6rem;
    width: 100%;

}

.Withdrawals_TabHeader__1LiDC {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Withdrawals_Header__3MTSA {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Withdrawals_ExportReport__17wuj {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Withdrawals_ExportIconWrapper__367eq {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Withdrawals_EmailIconWrapper__3txuh {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Withdrawals_PrintToolsWrapper__VkHsC {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Withdrawals_AddNewMerchant__1zIWU {
    /* // font-family: Cambay; */
}

.Withdrawals_AddNewMerchantBtn__3BdVI {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Withdrawals_AddNewMerchantBtn__3BdVI:focus,
.Withdrawals_AddNewMerchantBtn__3BdVI:hover,
.Withdrawals_AddNewMerchantBtn__3BdVI:active {
    background-color: rgb(51, 137, 255);
}


.Withdrawals_ToolsDataExportIconWrapper__2WEOd {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Withdrawals_ToolsPrintIconWrapper__3375n {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Withdrawals_FilterInput__1iKQt {
    margin-right: 2rem;
}

.Withdrawals_FormGroup__107ul {
    /* margin-bottom: 10px; */
}

.Withdrawals_FormGroupDefault__HMD_g.Withdrawals_has-error__3BL7f {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Withdrawals_FormGroupDefault__HMD_g {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Withdrawals_FormGroupDefault__HMD_g label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Withdrawals_FormGroup__107ul label:not(.Withdrawals_error__3rg5z) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Withdrawals_FormControl__3i6Xy {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Withdrawals_MerchantTableWrapper__7T8bF {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Withdrawals_TableWrapper__1CTj7 {
    display: flex;
}

.Withdrawals_MerchantTable__3pKPO {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Withdrawals_MerchantTable__3pKPO th {
    text-align: left;
}

.Withdrawals_MerchantTable__3pKPO th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Withdrawals_MerchantTable__3pKPO td,
.Withdrawals_MerchantTable__3pKPO th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Withdrawals_MerchantTable__3pKPO td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Withdrawals_MerchantTable__3pKPO td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Withdrawals_RevealMoreHeader__82VjL {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Withdrawals_Row__FFsdZ {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Withdrawals_Row__FFsdZ>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Withdrawals_dataTables_paginate__1GBGn {
    float: right;
}

.Withdrawals_dataTables_paginate__1GBGn {
    /* margin-top: 25px !important; */
}

div.Withdrawals_dataTables_paginate__1GBGn {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Withdrawals_dataTables_paginate__1GBGn ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Withdrawals_dataTables_paginate__1GBGn ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Withdrawals_dataTables_paginate__1GBGn ul>li.Withdrawals_disabled__lpjuk a {
    opacity: .5;
}

.Withdrawals_dataTables_paginate__1GBGn ul>li.Withdrawals_disabled__lpjuk a {
    opacity: .35;
}

.Withdrawals_dataTables_paginate__1GBGn ul>li.Withdrawals_active__22iCp>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Withdrawals_dataTables_paginate__1GBGn ul>li.Withdrawals_next__1VnJB>a,
.Withdrawals_dataTables_paginate__1GBGn ul>li.Withdrawals_prev__1UUYn>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Withdrawals_pg_arrow_right__t-lfG {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Withdrawals_pg_arrow_left__RUa8W {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Withdrawals_pg_arrow_right__t-lfG:before {
    content: "\E631";
}

.Withdrawals_pg_arrow_left__RUa8W:before {
    content: "\E629";
}

.Withdrawals_btn__6ftwi {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Withdrawals_btn_primary__3KAYb,
.Withdrawals_btn_primary__3KAYb:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Withdrawals_btn_cons__iau8r {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Withdrawals_MerchantRow__3PQwb {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Withdrawals_MerchantRow__3PQwb:hover {
    background-color: #267788;
    color: #fff;

}

.Withdrawals_MerchantRow__3PQwb:hover a {
    color: #fff;
}

.Withdrawals_MerchantRowExpanded__pYUz1 {
    background: #267788;
    color: #fff;
}

.Withdrawals_table__10O0l.Withdrawals_table-condensed__ztNHN.Withdrawals_table-detailed__1tNXJ>tbody>tr.Withdrawals_shown__1Thmi>td {
    background: #fdf5df;
}

.Withdrawals_EnableMerchant__xy6jB {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Withdrawals_EnableMerchantHeader__2r4Fq {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Withdrawals_EnableMerchantBody__AEafy {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.Sales_Container__2y8Rb {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Sales_TabHeader__2SYN3 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Sales_Header__9IpNa {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Sales_ExportReport__3ZPLv {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Sales_ExportIconWrapper__PSJfk {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Sales_EmailIconWrapper__vQlxX {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Sales_Tools__3BKwT {
    display: flex;
    /* margin: 0 2.5rem; */
    /* padding-top: 1.4rem; */
}

.Sales_PageFilter__uEKXb {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.Sales_ToolsWrapper__phZhP {
    height: 6rem;
    width: 100%;

}

.Sales_PrintToolsWrapper__2DOdP {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Sales_AddNewMerchant__3NVDp {
    /* // font-family: Cambay; */
}

.Sales_FilterReportBtn__78-M9 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Sales_AddNewMerchantBtn__10rhV:focus,
.Sales_AddNewMerchantBtn__10rhV:hover,
.Sales_AddNewMerchantBtn__10rhV:active {
    background-color: rgb(51, 137, 255);
}


.Sales_ToolsDataExportIconWrapper__2Kp9Y {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Sales_ToolsPrintIconWrapper__2hcsl {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Sales_FilterInput__1PSAW {
    margin-right: 2rem;
}

.Sales_FormGroup__1u2qE {
    /* margin-bottom: 10px; */
}

.Sales_FormGroupDefault__1nwlF.Sales_has-error__3ubTY {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Sales_FormGroupDefault__1nwlF {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Sales_FormGroupDefault__1nwlF label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Sales_FormGroup__1u2qE label:not(.Sales_error__1r_5-) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Sales_FormControl__19qma {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Sales_MerchantTableWrapper__2almP {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Sales_TableWrapper__2-vmK {
    /* margin: 0 2.5rem; */
}

.Sales_MerchantTable__1IwMw {
    width: 100%;
    border-collapse: collapse;
}

.Sales_MerchantTable__1IwMw th {
    text-align: left;
}

.Sales_MerchantTable__1IwMw th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Sales_MerchantTable__1IwMw td,
.Sales_MerchantTable__1IwMw th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Sales_RevealMoreHeader__1SmyQ {
    padding: 0;
    vertical-align: middle;
}





.Sales_Row__1Lekm {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Sales_Row__1Lekm>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Sales_dataTables_paginate__haj0R {
    float: right;
}

.Sales_dataTables_paginate__haj0R {
    /* margin-top: 25px !important; */
}

div.Sales_dataTables_paginate__haj0R {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Sales_dataTables_paginate__haj0R ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Sales_dataTables_paginate__haj0R ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Sales_dataTables_paginate__haj0R ul>li.Sales_disabled__3SdJi a {
    opacity: .5;
}

.Sales_dataTables_paginate__haj0R ul>li.Sales_disabled__3SdJi a {
    opacity: .35;
}

.Sales_dataTables_paginate__haj0R ul>li.Sales_active__1jmwC>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Sales_dataTables_paginate__haj0R ul>li.Sales_next__3vN4b>a,
.Sales_dataTables_paginate__haj0R ul>li.Sales_prev__2J-Mn>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Sales_pg_arrow_right__2WSA3 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Sales_pg_arrow_left__3faOa {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Sales_pg_arrow_right__2WSA3:before {
    content: "\E631";
}

.Sales_pg_arrow_left__3faOa:before {
    content: "\E629";
}

.Sales_btn__3uh6h {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Sales_btn_primary__33BYI,
.Sales_btn_primary__33BYI:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Sales_btn_cons__wDtkh {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Sales_MerchantName__n8xE0 {
    font-weight: 500;
}

.Sales_Icn__2hvBq {
    cursor: pointer;
    /* fill:#000; */
}

.Sales_Icn__2hvBq:hover {
    cursor: pointer;

}



.Sales_RevealMore__2FimX {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Sales_RevealMore__2FimX:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Sales_RevealMore__2FimX div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Sales_RevealMoreExpanded__3TJUR {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Sales_Expandabled__2ok0C {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Sales_Expandabled__2ok0C td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Sales_MerchantTable__1IwMw td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Sales_MerchantTable__1IwMw td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Sales_MerchantRow__Mp4WV {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Sales_MerchantRow__Mp4WV:hover {
    background-color: #267788;
    color: #fff;

}

.Sales_MerchantRow__Mp4WV:hover a {
    color: #fff;
}

.Sales_MerchantRowExpanded__HJ3RL {
    background: #267788;
    color: #fff;
}

.Sales_table__3K6Lg.Sales_table-condensed__3IV5J.Sales_table-detailed__1eGTu>tbody>tr.Sales_shown__1e4mq>td {
    background: #fdf5df;
}


.Sales_Totals__3v0rF td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Sales_MoneyTotalLabel__3V0JA {
    float: right;

}

.Sales_MoneyTotal__3Q1Jl {
    font-weight: 600;
}
.UserList_Container__14Q_R {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.UserList_Tools__bJNIJ {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.UserList_PageFilter__37LIq {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.UserList_ToolsWrapper__2VgaU {
    height: 6rem;
    width: 100%;

}

.UserList_PrintToolsWrapper__34L-t {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.UserList_AddNewMerchant__ee3jg {
    /* // font-family: Cambay; */
}

.UserList_AddNewMerchantBtn__PZBl4 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.UserList_AddNewMerchantBtn__PZBl4:focus,
.UserList_AddNewMerchantBtn__PZBl4:hover,
.UserList_AddNewMerchantBtn__PZBl4:active {
    background-color: rgb(51, 137, 255);
}


.UserList_ToolsDataExportIconWrapper__344Jv {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.UserList_ToolsPrintIconWrapper__2gqx3 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.UserList_FilterInput__16yRE {
    margin-right: 2rem;
}

.UserList_FormGroup__1whsS {
    /* margin-bottom: 10px; */
}

.UserList_FormGroupDefault__18Jny.UserList_has-error__3HFtv {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.UserList_FormGroupDefault__18Jny {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.UserList_FormGroupDefault__18Jny label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.UserList_FormGroup__1whsS label:not(.UserList_error__brixp) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.UserList_FormControl__1x9TH {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.UserList_MerchantTableWrapper__161Jv {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.UserList_TableWrapper__2X0d8 {
    /* margin: 0 2.5rem; */
}

.UserList_MerchantTable__2L4Ah {
    width: 100%;
    border-collapse: collapse;
}

.UserList_MerchantTable__2L4Ah th {
    text-align: left;
}

.UserList_MerchantTable__2L4Ah th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.UserList_MerchantTable__2L4Ah td,
.UserList_MerchantTable__2L4Ah th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.UserList_MerchantTable__2L4Ah td:last-child {

    border-right: 1px solid #e8e8e8;
}


.UserList_MerchantTable__2L4Ah td:first-child {

    border-left: 1px solid #e8e8e8;
}

.UserList_RevealMoreHeader__30H9b {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.UserList_Row__Pny0h {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.UserList_Row__Pny0h>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.UserList_dataTables_paginate__qMh5C {
    float: right;
}

.UserList_dataTables_paginate__qMh5C {
    /* margin-top: 25px !important; */
}

div.UserList_dataTables_paginate__qMh5C {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.UserList_dataTables_paginate__qMh5C ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.UserList_dataTables_paginate__qMh5C ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.UserList_dataTables_paginate__qMh5C ul>li.UserList_disabled__1Qzf0 a {
    opacity: .5;
}

.UserList_dataTables_paginate__qMh5C ul>li.UserList_disabled__1Qzf0 a {
    opacity: .35;
}

.UserList_dataTables_paginate__qMh5C ul>li.UserList_active__jN3Zw>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.UserList_dataTables_paginate__qMh5C ul>li.UserList_next__HhWSl>a,
.UserList_dataTables_paginate__qMh5C ul>li.UserList_prev__3Z1NL>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.UserList_pg_arrow_right__17v9s {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.UserList_pg_arrow_left__32Srn {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.UserList_pg_arrow_right__17v9s:before {
    content: "\E631";
}

.UserList_pg_arrow_left__32Srn:before {
    content: "\E629";
}

.UserList_btn__1bttp {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.UserList_btn_primary__cGnui,
.UserList_btn_primary__cGnui:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.UserList_btn_cons__Omun6 {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.UserList_MerchantRow__30lGU {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.UserList_MerchantRow__30lGU:hover {
    background-color: #267788;
    color: #fff;

}

.UserList_MerchantRow__30lGU:hover a {
    color: #fff;
}

.UserList_MerchantRowExpanded__ur45P {
    background: #267788;
    color: #fff;
}

.UserList_table__2-9M2.UserList_table-condensed__3WLUI.UserList_table-detailed__2GThU>tbody>tr.UserList_shown__1_rkZ>td {
    background: #fdf5df;
}
.Users_Container__1Tcpj {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Users_BreadcrumbWrapper__2HfDS {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Users_Content__2HZls {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Users_Alert__1X90I {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Users_Breadcrumb__3eA4E {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Users_Header__3FMWO {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Users_ByLine__3750S {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Users_Activity__12c_T {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Users_WelcomeContainer__17_8d {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Users_MerchantProfile__3US9V {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}




.Users_TabHeader__1cfkB {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Users_PageHeader__2I1ch {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Users_AddUser__1qBe2 {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Users_AddUserBtn__3Gj9K {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}
.LoginForm_FullHeight__2yA1x {
    height: 100% !important;
}

.LoginForm_LoginContainer__1taES {
    width: 40rem;;
    margin: auto;
    height: 100%;
    margin-top: 7.2rem;
}

.LoginForm_LoginWrapper__3mtcz {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    margin-top: 7.2rem;
}

.LoginForm_Logo__3bCDz {
    background-color: transparent;
    padding: 0;
    height: 4rem;
    box-sizing: border-box;
}

.LoginForm_Logo__3bCDz {
    display: inline-block;
    font-size: 24px;
    line-height: 24px;
    vertical-align: middle;
}

.LoginForm_PaddingTop40__NF_zF {
    padding-top: 4rem;
}

.LoginForm_PaddingTop15__FfrJn {
    padding-top: 1.5rem;
}

.LoginForm_FormGroup__KWtFv {
    margin-bottom: 1.5rem;
}

.LoginForm_FormGroupDefault__3UOpL.LoginForm_has-error__2zBev {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.LoginForm_FormGroupDefault__3UOpL {
    background-color: #fff;
    position: relative;
    border: 1px solid rgba(0, 0, 0, .07);
    border-radius: 2px;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;
    overflow: hidden;
    width: 100%;
    -webkit-transition: background-color .2s ease;
    transition: background-color .2s ease;
}

label,
input,
button,
select,
textarea {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.LoginForm_FormGroupDefault__3UOpL label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease;
}

.LoginForm_FormGroup__KWtFv label:not(.LoginForm_error__1W48F) {
    font-size: 10.5px;
    letter-spacing: .06em;
    text-transform: uppercase;
    font-weight: 500;
}

.LoginForm_FormControl__Db-yp {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.LoginForm_FormControl__Db-yp {
    background-color: #fff;
    background-image: none;
    border: 1px solid rgba(0, 0, 0, .07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    padding: 8px 12px;
    line-height: normal;
    font-size: 14px;
    font-weight: 400;
    vertical-align: middle;
    min-height: 35px;
    -webkit-transition: all .12s ease;
    transition: all .12s ease;
    box-shadow: none;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-transition: background .2s linear 0s;
    transition: background .2s linear 0s;
}

.LoginForm_FormGroupDefault__3UOpL .LoginForm_FormControl__Db-yp {
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: 0 0;
}

.LoginForm_Error__3kJyw {
    font-size: 12px;
    color: #f35958;
    display: block;
}

.LoginForm_HasError__qHbR3 .LoginForm_FormControl__Db-yp {
    border-color: #f35958;
    box-shadow: none;
}



.LoginForm_Row__3RCd0 {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

form .LoginForm_Row__3RCd0 {
    margin-left: 0;
    margin-right: 0;
}

.LoginForm_SignIn__1D5qq {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 !important;
}

.LoginForm_Help__Ztvsf {
    display: flex;
    flex: 0 0 50%;
    max-width: 50%;
    justify-content: flex-end;
    align-items: center!important;
    padding-left: 7px;
    padding-right: 0;
}

.LoginForm_Radio__1q5AF label:before, .LoginForm_Checkbox__IPeUY label:before {
    box-sizing: border-box;
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 10px;
    position: absolute;
    left: 0;
    background-color: #fff;
    border: 1px solid #cdcdcd;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.LoginForm_Checkbox__IPeUY label:before {
    top: 1.4px;
    border-radius: 3px;
    -webkit-transition: border .2s linear 0s,color .2s linear 0s;
    transition: border .2s linear 0s,color .2s linear 0s;
}

label, input, button, select, textarea {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.LoginForm_Radio__1q5AF, .LoginForm_Checkbox__IPeUY {
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 0;
}

.LoginForm_Checkbox__IPeUY label {
    -webkit-transition: border .2s linear 0s,color .2s linear 0s;
    transition: border .2s linear 0s,color .2s linear 0s;
}

.LoginForm_Radio__1q5AF label, .LoginForm_Checkbox__IPeUY label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 15px;
    min-width: 17px;
    min-height: 17px;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

label, input, button, select, textarea {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}


input[type=radio], input[type=checkbox] {
    margin-top: 1px 0 0;
    line-height: normal;
    cursor: pointer;
    box-sizing: border-box;
}



input[type=radio], input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
}

.LoginForm_Checkbox__IPeUY input[type=checkbox] {
    opacity: 0;
    width: 0;
    height: 0;
}

input, input:focus {
    -webkit-transition: none!important;
}

.LoginForm_ButtonPrimary__2aDIK {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .01em;
    -webkit-font-smoothing: antialiased;
    margin-top: 10px;
    margin-bottom: 0;
    margin-right: 5px;
    min-width: 120px;
    border: 1px solid #e7e7e7;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-border-radius: 3px;
    background-image: none!important;
    color: #fff;
    background-color: #6d5eac;
    border-color: #6d5eac;
    text-shadow: none;
    box-shadow: none;
    line-height: 21px;
    position: relative;
    -webkit-transition: color .1s linear 0s,background-color .1s linear 0s,opacity .2s linear 0s!important;
    transition: color .1s linear 0s,background-color .1s linear 0s,opacity .2s linear 0s!important;
    padding: 6px 17px;
}



p {
    display: block;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .01em;
    line-height: 22px;
    margin: 0 0 10px;
    font-style: normal;
    white-space: normal;
}
.InventoryList_Container__Bj0v2 {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.InventoryList_Tools__2Hlla {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.InventoryList_PageFilter__1Lnz- {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.InventoryList_ToolsWrapper__Afclh {
    height: 6rem;
    width: 100%;

}

.InventoryList_PrintToolsWrapper__u93NQ {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.InventoryList_AddNewMerchant__3Zy9v {
    /* // font-family: Cambay; */
}

.InventoryList_AddNewMerchantBtn__JnrcZ {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.InventoryList_AddNewMerchantBtn__JnrcZ:focus,
.InventoryList_AddNewMerchantBtn__JnrcZ:hover,
.InventoryList_AddNewMerchantBtn__JnrcZ:active {
    background-color: rgb(51, 137, 255);
}


.InventoryList_ToolsDataExportIconWrapper__3Lk19 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.InventoryList_ToolsPrintIconWrapper__27M39 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.InventoryList_FilterInput__2IrxT {
    margin-right: 2rem;
}

.InventoryList_FormGroup__2re9B {
    /* margin-bottom: 10px; */
}

.InventoryList_FormGroupDefault__1A22F.InventoryList_has-error__3DF8u {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.InventoryList_FormGroupDefault__1A22F {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.InventoryList_FormGroupDefault__1A22F label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.InventoryList_FormGroup__2re9B label:not(.InventoryList_error__2LnAj) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.InventoryList_FormControl__3cs3D {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.InventoryList_MerchantTableWrapper__3YfJT {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.InventoryList_TableWrapper__1xgGD {
    display: flex;
}

.InventoryList_MerchantTable__1ZSVx {
    width: 100%;
    border-collapse: collapse;
}

.InventoryList_MerchantTable__1ZSVx th {
    text-align: left;
}

.InventoryList_MerchantTable__1ZSVx th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.InventoryList_MerchantTable__1ZSVx td,
.InventoryList_MerchantTable__1ZSVx th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.InventoryList_MerchantTable__1ZSVx td:last-child {

    border-right: 1px solid #e8e8e8;
}


.InventoryList_MerchantTable__1ZSVx td:first-child {

    border-left: 1px solid #e8e8e8;
}

.InventoryList_RevealMoreHeader__jPJjx {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.InventoryList_Row__3KwGc {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.InventoryList_Row__3KwGc>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.InventoryList_dataTables_paginate__ZabQ4 {
    float: right;
}

.InventoryList_dataTables_paginate__ZabQ4 {
    /* margin-top: 25px !important; */
}

div.InventoryList_dataTables_paginate__ZabQ4 {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.InventoryList_dataTables_paginate__ZabQ4 ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.InventoryList_dataTables_paginate__ZabQ4 ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.InventoryList_dataTables_paginate__ZabQ4 ul>li.InventoryList_disabled__33cSG a {
    opacity: .5;
}

.InventoryList_dataTables_paginate__ZabQ4 ul>li.InventoryList_disabled__33cSG a {
    opacity: .35;
}

.InventoryList_dataTables_paginate__ZabQ4 ul>li.InventoryList_active__f-7mP>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.InventoryList_dataTables_paginate__ZabQ4 ul>li.InventoryList_next__3Cm2a>a,
.InventoryList_dataTables_paginate__ZabQ4 ul>li.InventoryList_prev__Ace2B>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.InventoryList_pg_arrow_right__oEhqo {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.InventoryList_pg_arrow_left__2qlLT {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.InventoryList_pg_arrow_right__oEhqo:before {
    content: "\E631";
}

.InventoryList_pg_arrow_left__2qlLT:before {
    content: "\E629";
}

.InventoryList_btn__1PJ1n {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.InventoryList_btn_primary__3LdW7,
.InventoryList_btn_primary__3LdW7:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.InventoryList_btn_cons__KTmvg {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.InventoryList_MerchantRow__2P9tc {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.InventoryList_MerchantRow__2P9tc:hover {
    background-color: #267788;
    color: #fff;

}

.InventoryList_MerchantRow__2P9tc:hover a {
    color: #fff;
}

.InventoryList_MerchantRowExpanded__Vkz1z {
    background: #267788;
    color: #fff;
}

.InventoryList_table__1Ybg9.InventoryList_table-condensed__39Ja-.InventoryList_table-detailed__2gSk4>tbody>tr.InventoryList_shown__3XtMD>td {
    background: #fdf5df;
}
.SIMCard_Container__693T8 {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.SIMCard_Tools__q0bxr {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.SIMCard_PageFilter__13jOF {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.SIMCard_ToolsWrapper__fqCdz {
    height: 6rem;
    width: 100%;

}

.SIMCard_PrintToolsWrapper__19va0 {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.SIMCard_AddNewMerchant__qeJ21 {
    /* // font-family: Cambay; */
}

.SIMCard_AddNewMerchantBtn__2UY7s {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.SIMCard_AddNewMerchantBtn__2UY7s:focus,
.SIMCard_AddNewMerchantBtn__2UY7s:hover,
.SIMCard_AddNewMerchantBtn__2UY7s:active {
    background-color: rgb(51, 137, 255);
}


.SIMCard_ToolsDataExportIconWrapper__1OuXA {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.SIMCard_ToolsPrintIconWrapper__2M3Ej {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.SIMCard_FilterInput__tjjEq {
    margin-right: 2rem;
}

.SIMCard_FormGroup__JfIID {
    /* margin-bottom: 10px; */
}

.SIMCard_FormGroupDefault__3tOWt.SIMCard_has-error__ha3xA {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.SIMCard_FormGroupDefault__3tOWt {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.SIMCard_FormGroupDefault__3tOWt label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.SIMCard_FormGroup__JfIID label:not(.SIMCard_error__jyyhi) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.SIMCard_FormControl__22z5E {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.SIMCard_MerchantTableWrapper__m-BRG {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.SIMCard_TableWrapper__1vhsJ {
    /* margin: 0 2.5rem; */
}

.SIMCard_MerchantTable__20SQs {
    width: 100%;
    border-collapse: collapse;
}

.SIMCard_MerchantTable__20SQs th {
    text-align: left;
}

.SIMCard_MerchantTable__20SQs th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.SIMCard_MerchantTable__20SQs td,
.SIMCard_MerchantTable__20SQs th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.SIMCard_MerchantTable__20SQs td:last-child {

    border-right: 1px solid #e8e8e8;
}


.SIMCard_MerchantTable__20SQs td:first-child {

    border-left: 1px solid #e8e8e8;
}

.SIMCard_RevealMoreHeader__298hD {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.SIMCard_Row__28vFK {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.SIMCard_Row__28vFK>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.SIMCard_dataTables_paginate__vwwVj {
    float: right;
}

.SIMCard_dataTables_paginate__vwwVj {
    /* margin-top: 25px !important; */
}

div.SIMCard_dataTables_paginate__vwwVj {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.SIMCard_dataTables_paginate__vwwVj ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.SIMCard_dataTables_paginate__vwwVj ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.SIMCard_dataTables_paginate__vwwVj ul>li.SIMCard_disabled__RlGcO a {
    opacity: .5;
}

.SIMCard_dataTables_paginate__vwwVj ul>li.SIMCard_disabled__RlGcO a {
    opacity: .35;
}

.SIMCard_dataTables_paginate__vwwVj ul>li.SIMCard_active__3MwJn>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.SIMCard_dataTables_paginate__vwwVj ul>li.SIMCard_next__1CEYe>a,
.SIMCard_dataTables_paginate__vwwVj ul>li.SIMCard_prev__2qkvx>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.SIMCard_pg_arrow_right__J0kfi {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.SIMCard_pg_arrow_left__3mxsY {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.SIMCard_pg_arrow_right__J0kfi:before {
    content: "\E631";
}

.SIMCard_pg_arrow_left__3mxsY:before {
    content: "\E629";
}

.SIMCard_btn__3Qcnj {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.SIMCard_btn_primary__eGAMK,
.SIMCard_btn_primary__eGAMK:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.SIMCard_btn_cons__1aVYo {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.SIMCard_MerchantRow__1rVFq {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.SIMCard_MerchantRow__1rVFq:hover {
    background-color: #267788;
    color: #fff;

}

.SIMCard_MerchantRow__1rVFq:hover a {
    color: #fff;
}

.SIMCard_MerchantRowExpanded__2KgpE {
    background: #267788;
    color: #fff;
}

.SIMCard_table__1YTah.SIMCard_table-condensed__3I3WS.SIMCard_table-detailed__3racy>tbody>tr.SIMCard_shown__29ogk>td {
    background: #fdf5df;
}
.SelectedDevice_Note__1Qclq {
    display: block;
    word-wrap: break-word;
    white-space: normal;
}
.Devices_Container__fU8vc {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Devices_Tools__yfZhd {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Devices_PageFilter__1zcDE {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Devices_ToolsWrapper__1w7Ua {
    height: 6rem;
    width: 100%;

}

.Devices_PrintToolsWrapper__1P2gO {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Devices_AddNewMerchant__2cYz0 {
    /* // font-family: Cambay; */
}

.Devices_AddNewMerchantBtn__3QTcO {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Devices_AddNewMerchantBtn__3QTcO:focus,
.Devices_AddNewMerchantBtn__3QTcO:hover,
.Devices_AddNewMerchantBtn__3QTcO:active {
    background-color: rgb(51, 137, 255);
}


.Devices_ToolsDataExportIconWrapper__1aH8v {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Devices_ToolsPrintIconWrapper__3JfAO {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Devices_FilterInput__3NCYl {
    margin-right: 2rem;
}

.Devices_FormGroup__12f5w {
    /* margin-bottom: 10px; */
}

.Devices_FormGroupDefault__1vMFv.Devices_has-error__25bMa {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Devices_FormGroupDefault__1vMFv {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Devices_FormGroupDefault__1vMFv label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Devices_FormGroup__12f5w label:not(.Devices_error__284_L) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Devices_FormControl__3v7-f {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Devices_MerchantTableWrapper__2rV2D {
    margin-top: 2.5rem;
    white-space: nowrap;
}

/* .TableWrapper {
    display: flex;
} */

.Devices_MerchantTable__2CHyC {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Devices_MerchantTable__2CHyC th {
    text-align: left;
}

.Devices_MerchantTable__2CHyC th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Devices_MerchantTable__2CHyC td,
.Devices_MerchantTable__2CHyC th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Devices_MerchantTable__2CHyC td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Devices_MerchantTable__2CHyC td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Devices_RevealMoreHeader__TFQxI {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Devices_Row__3YOpc {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Devices_Row__3YOpc>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Devices_dataTables_paginate__1EI7I {
    float: right;
}

.Devices_dataTables_paginate__1EI7I {
    /* margin-top: 25px !important; */
}

div.Devices_dataTables_paginate__1EI7I {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Devices_dataTables_paginate__1EI7I ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Devices_dataTables_paginate__1EI7I ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Devices_dataTables_paginate__1EI7I ul>li.Devices_disabled__3POmP a {
    opacity: .5;
}

.Devices_dataTables_paginate__1EI7I ul>li.Devices_disabled__3POmP a {
    opacity: .35;
}

.Devices_dataTables_paginate__1EI7I ul>li.Devices_active__1Uscj>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Devices_dataTables_paginate__1EI7I ul>li.Devices_next__13CYN>a,
.Devices_dataTables_paginate__1EI7I ul>li.Devices_prev__3Derh>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Devices_pg_arrow_right__i4e31 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Devices_pg_arrow_left__2jcEp {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Devices_pg_arrow_right__i4e31:before {
    content: "\E631";
}

.Devices_pg_arrow_left__2jcEp:before {
    content: "\E629";
}

.Devices_btn__2Cc38 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Devices_btn_primary__373P4,
.Devices_btn_primary__373P4:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Devices_btn_cons__3Ni_k {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Devices_MerchantRow__2kp4n {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Devices_MerchantRow__2kp4n:hover {
    background-color: #267788;
    color: #fff;

}

.Devices_MerchantRow__2kp4n:hover a {
    color: #fff;
}

.Devices_MerchantRowExpanded__3uY9H {
    background: #267788;
    color: #fff;
}

.Devices_table__3PEkC.Devices_table-condensed__2v364.Devices_table-detailed__sb5qO>tbody>tr.Devices_shown__10gtL>td {
    background: #fdf5df;
}


.Devices_SelectedDevice__1gP4P {
    background-color: #267788;
    color: #fff;

}

.Devices_DeviceDetail__1iMHl {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}
.Computers_Container__31w0x {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Computers_Tools__1V0Oe {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Computers_PageFilter___CvnV {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Computers_ToolsWrapper__3lscj {
    height: 6rem;
    width: 100%;

}

.Computers_PrintToolsWrapper__ZeynC {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Computers_AddNewMerchant__1NYLK {
    /* // font-family: Cambay; */
}

.Computers_AddNewMerchantBtn__3ozqe {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Computers_AddNewMerchantBtn__3ozqe:focus,
.Computers_AddNewMerchantBtn__3ozqe:hover,
.Computers_AddNewMerchantBtn__3ozqe:active {
    background-color: rgb(51, 137, 255);
}


.Computers_ToolsDataExportIconWrapper__ZF1uA {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Computers_ToolsPrintIconWrapper__35bah {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Computers_FilterInput__2diSb {
    margin-right: 2rem;
}

.Computers_FormGroup__3MFk- {
    /* margin-bottom: 10px; */
}

.Computers_FormGroupDefault__2s4Dh.Computers_has-error__1zy7g {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Computers_FormGroupDefault__2s4Dh {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Computers_FormGroupDefault__2s4Dh label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Computers_FormGroup__3MFk- label:not(.Computers_error__6FKWi) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Computers_FormControl__TYTkE {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Computers_MerchantTableWrapper__38yXx {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Computers_TableWrapper__1XtUY {
    /* margin: 0 2.5rem; */
}

.Computers_MerchantTable__3ScpG {
    width: 100%;
    border-collapse: collapse;
}

.Computers_MerchantTable__3ScpG th {
    text-align: left;
}

.Computers_MerchantTable__3ScpG th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Computers_MerchantTable__3ScpG td,
.Computers_MerchantTable__3ScpG th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Computers_MerchantTable__3ScpG td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Computers_MerchantTable__3ScpG td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Computers_RevealMoreHeader__3X6wk {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Computers_Row__2XGq9 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Computers_Row__2XGq9>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Computers_dataTables_paginate__8eHCd {
    float: right;
}

.Computers_dataTables_paginate__8eHCd {
    /* margin-top: 25px !important; */
}

div.Computers_dataTables_paginate__8eHCd {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Computers_dataTables_paginate__8eHCd ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Computers_dataTables_paginate__8eHCd ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Computers_dataTables_paginate__8eHCd ul>li.Computers_disabled__3RNVz a {
    opacity: .5;
}

.Computers_dataTables_paginate__8eHCd ul>li.Computers_disabled__3RNVz a {
    opacity: .35;
}

.Computers_dataTables_paginate__8eHCd ul>li.Computers_active__1zI8R>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Computers_dataTables_paginate__8eHCd ul>li.Computers_next__c4OxV>a,
.Computers_dataTables_paginate__8eHCd ul>li.Computers_prev__31j7w>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Computers_pg_arrow_right__3yrFC {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Computers_pg_arrow_left__28fOw {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Computers_pg_arrow_right__3yrFC:before {
    content: "\E631";
}

.Computers_pg_arrow_left__28fOw:before {
    content: "\E629";
}

.Computers_btn__2BtzW {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Computers_btn_primary__a354o,
.Computers_btn_primary__a354o:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Computers_btn_cons__HvnDr {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Computers_MerchantRow__1iNhK {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Computers_MerchantRow__1iNhK:hover {
    background-color: #267788;
    color: #fff;

}

.Computers_MerchantRow__1iNhK:hover a {
    color: #fff;
}

.Computers_MerchantRowExpanded__1my-J {
    background: #267788;
    color: #fff;
}

.Computers_table__D9IEG.Computers_table-condensed__2YB6w.Computers_table-detailed__2l5tV>tbody>tr.Computers_shown__-CCEV>td {
    background: #fdf5df;
}
.Inventory_Container__1efVZ {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Inventory_BreadcrumbWrapper__JHd8b {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Inventory_Content__3G1u1 {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Inventory_Alert__AJIJn {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Inventory_Breadcrumb__3gwuY {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Inventory_Header__1aeVg {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Inventory_ByLine__HVcAz {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Inventory_Activity__lFO_w {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Inventory_WelcomeContainer__3LC9x {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Inventory_MerchantProfile__3Igxk {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Inventory_TabHeader__1q2LQ {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Inventory_PageHeader__VFupK {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Inventory_AddUser__KDGcO {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Inventory_AddUserBtn__2lQRF {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Inventory_FilterInput__be2uH {
    margin-right: 2rem;
}

.Inventory_FormGroup__25hBR {
    margin-bottom: 10px;
}

.Inventory_FormGroupDefault__19lwJ.Inventory_has-error__1ix-V {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Inventory_FormGroupDefault__19lwJ {

    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

.Inventory_PageFilter__2V4TZ {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
    padding-left: 2rem;
    margin-top: 10px;
}

.Inventory_PageTitle__3AcJW {
    border-right: 1px solid #707070;
    padding-right: 2rem;
    font-weight: 700;
    margin-top: 10px;
}

.Inventory_FilterInput__be2uH {
    margin-right: 2rem;
}

.Inventory_FormGroup__25hBR {
    margin-bottom: 10px;
}

.Inventory_FormGroupDefault__19lwJ.Inventory_has-error__1ix-V {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Inventory_FormGroupDefault__19lwJ {

    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Inventory_FormGroupDefault__19lwJ label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Inventory_FormGroup__25hBR label:not(.Inventory_error__1BaFu) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Inventory_FormControl__JDUIY {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}
.authorisation_Container__a7EgG {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.authorisation_BreadcrumbWrapper__AaHB7 {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.authorisation_Content__3pRCa {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.authorisation_Alert__3k3XU {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.authorisation_Breadcrumb__bACc3 {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.authorisation_Header__3DW-h {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.authorisation_ByLine__3nx_a {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.authorisation_Activity__EeBmp {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.authorisation_WelcomeContainer__3Hdsc {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.authorisation_MerchantProfile__30BXk {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.authorisation_TabHeader__aHXM4 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.authorisation_PageHeader__2QUrQ {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.authorisation_AddUser__3lBSx {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.authorisation_AddUserBtn__7BKaa {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}
.Merchant_Container__1mMbE {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Merchant_Tools__bHjNf {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Merchant_PageFilter__2Lh6w {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Merchant_ToolsWrapper__2ahy6 {
    height: 6rem;
    width: 100%;

}

.Merchant_PrintToolsWrapper__1GeE1 {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Merchant_AddNewMerchant__gwFMG {
    /* // font-family: Cambay; */
}

.Merchant_AddNewMerchantBtn__27dAZ {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Merchant_AddNewMerchantBtn__27dAZ:focus,
.Merchant_AddNewMerchantBtn__27dAZ:hover,
.Merchant_AddNewMerchantBtn__27dAZ:active {
    background-color: rgb(51, 137, 255);
}


.Merchant_ToolsDataExportIconWrapper__1d3Rs {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Merchant_ToolsPrintIconWrapper__26MMu {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Merchant_FilterInput__2Ifb1 {
    margin-right: 2rem;
}

.Merchant_FormGroup__2oMSV {
    /* margin-bottom: 10px; */
}

.Merchant_FormGroupDefault__Ad_NU.Merchant_has-error__2O2S2 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Merchant_FormGroupDefault__Ad_NU {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Merchant_FormGroupDefault__Ad_NU label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Merchant_FormGroup__2oMSV label:not(.Merchant_error__3GbdJ) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Merchant_FormControl__7mhz3 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Merchant_MerchantTableWrapper__2B9D0 {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Merchant_TableWrapper__2bOIo {
    display: flex;
}

.Merchant_MerchantTable__1mUaT {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Merchant_MerchantTable__1mUaT th {
    text-align: left;
}

.Merchant_MerchantTable__1mUaT th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Merchant_MerchantTable__1mUaT td,
.Merchant_MerchantTable__1mUaT th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Merchant_MerchantTable__1mUaT td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Merchant_MerchantTable__1mUaT td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Merchant_RevealMoreHeader__3z_lu {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Merchant_Row__33feu {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Merchant_Row__33feu>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Merchant_dataTables_paginate__ojB2x {
    float: right;
}

.Merchant_dataTables_paginate__ojB2x {
    /* margin-top: 25px !important; */
}

div.Merchant_dataTables_paginate__ojB2x {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Merchant_dataTables_paginate__ojB2x ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Merchant_dataTables_paginate__ojB2x ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Merchant_dataTables_paginate__ojB2x ul>li.Merchant_disabled__3zybl a {
    opacity: .5;
}

.Merchant_dataTables_paginate__ojB2x ul>li.Merchant_disabled__3zybl a {
    opacity: .35;
}

.Merchant_dataTables_paginate__ojB2x ul>li.Merchant_active__2BeNL>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Merchant_dataTables_paginate__ojB2x ul>li.Merchant_next__2v_c_>a,
.Merchant_dataTables_paginate__ojB2x ul>li.Merchant_prev__18U9v>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Merchant_pg_arrow_right__1J83a {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Merchant_pg_arrow_left__3o2DZ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Merchant_pg_arrow_right__1J83a:before {
    content: "\E631";
}

.Merchant_pg_arrow_left__3o2DZ:before {
    content: "\E629";
}

.Merchant_btn__ETXh8 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Merchant_btn_primary__3VTTu,
.Merchant_btn_primary__3VTTu:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Merchant_btn_cons__3uOJo {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Merchant_MerchantRow__2JQCs {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Merchant_MerchantRow__2JQCs:hover {
    background-color: #267788;
    color: #fff;

}

.Merchant_MerchantRow__2JQCs:hover a {
    color: #fff;
}

.Merchant_MerchantRowExpanded__1BxeZ {
    background: #267788;
    color: #fff;
}

.Merchant_table__hoJwf.Merchant_table-condensed__1-dZi.Merchant_table-detailed__2sqtD>tbody>tr.Merchant_shown__2LFzd>td {
    background: #fdf5df;
}

.Merchant_EnableMerchant__loFnC {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Merchant_EnableMerchantHeader__CeJVU {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Merchant_EnableMerchantBody__2_IF7 {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}


.Devices_Container__3D8bw {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Devices_Tools__32rgl {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Devices_PageFilter__29wOs {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Devices_ToolsWrapper__2Nt7d {
    height: 6rem;
    width: 100%;

}

.Devices_PrintToolsWrapper__2KmPN {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Devices_AddNewMerchant__2IHNa {
    /* // font-family: Cambay; */
}

.Devices_AddNewMerchantBtn__67shd {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Devices_AddNewMerchantBtn__67shd:focus,
.Devices_AddNewMerchantBtn__67shd:hover,
.Devices_AddNewMerchantBtn__67shd:active {
    background-color: rgb(51, 137, 255);
}


.Devices_ToolsDataExportIconWrapper__2LvLZ {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Devices_ToolsPrintIconWrapper__1tMWB {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Devices_FilterInput__OjK3g {
    margin-right: 2rem;
}

.Devices_FormGroup__3dbbM {
    /* margin-bottom: 10px; */
}

.Devices_FormGroupDefault__1lApe.Devices_has-error__1w_vy {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Devices_FormGroupDefault__1lApe {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Devices_FormGroupDefault__1lApe label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Devices_FormGroup__3dbbM label:not(.Devices_error__1aVYN) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Devices_FormControl__3t4Pa {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Devices_MerchantTableWrapper__rnT6f {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Devices_TableWrapper__zQ1Tt {
    display: flex;
}

.Devices_MerchantTable__T9QWl {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Devices_MerchantTable__T9QWl th {
    text-align: left;
}

.Devices_MerchantTable__T9QWl th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Devices_MerchantTable__T9QWl td,
.Devices_MerchantTable__T9QWl th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Devices_MerchantTable__T9QWl td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Devices_MerchantTable__T9QWl td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Devices_RevealMoreHeader__ALS3W {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Devices_Row__1CEjW {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Devices_Row__1CEjW>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Devices_dataTables_paginate__2dAJJ {
    float: right;
}

.Devices_dataTables_paginate__2dAJJ {
    /* margin-top: 25px !important; */
}

div.Devices_dataTables_paginate__2dAJJ {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Devices_dataTables_paginate__2dAJJ ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Devices_dataTables_paginate__2dAJJ ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Devices_dataTables_paginate__2dAJJ ul>li.Devices_disabled__3BSrq a {
    opacity: .5;
}

.Devices_dataTables_paginate__2dAJJ ul>li.Devices_disabled__3BSrq a {
    opacity: .35;
}

.Devices_dataTables_paginate__2dAJJ ul>li.Devices_active__101jL>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Devices_dataTables_paginate__2dAJJ ul>li.Devices_next__3Np4v>a,
.Devices_dataTables_paginate__2dAJJ ul>li.Devices_prev__3uRro>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Devices_pg_arrow_right__3T6zA {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Devices_pg_arrow_left__3jtsN {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Devices_pg_arrow_right__3T6zA:before {
    content: "\E631";
}

.Devices_pg_arrow_left__3jtsN:before {
    content: "\E629";
}

.Devices_btn__1e9AB {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Devices_btn_primary__3yb8r,
.Devices_btn_primary__3yb8r:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Devices_btn_cons__2ZOng {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Devices_MerchantRow__1rG_W {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Devices_MerchantRow__1rG_W:hover {
    background-color: #267788;
    color: #fff;

}

.Devices_MerchantRow__1rG_W:hover a {
    color: #fff;
}

.Devices_MerchantRowExpanded__30LKn {
    background: #267788;
    color: #fff;
}

.Devices_table__1VHaJ.Devices_table-condensed__2rp9D.Devices_table-detailed__2dvJe>tbody>tr.Devices_shown__10WVt>td {
    background: #fdf5df;
}

.Devices_ActivateDevice__7jK9q {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Devices_ActivateDeviceHeader__1oY8L {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Devices_ActivateDeviceBody__2O9BD {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}


.Devices_EnableDevice__uAkrr {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Devices_EnableDeviceHeader__30DBz {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Devices_EnableDeviceBody__2dna- {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}

.Commission_Container__38c5G {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Commission_Tools__3xtFT {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Commission_PageFilter__24mVp {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Commission_ToolsWrapper__sPxfG {
    height: 6rem;
    width: 100%;

}

.Commission_PrintToolsWrapper__1lXGT {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}



.Commission_AddNewMerchantBtn__5iCxv {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Commission_AddNewMerchantBtn__5iCxv:focus,
.Commission_AddNewMerchantBtn__5iCxv:hover,
.Commission_AddNewMerchantBtn__5iCxv:active {
    background-color: rgb(51, 137, 255);
}


.Commission_ToolsDataExportIconWrapper__2-Ig4 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Commission_ToolsPrintIconWrapper__bpeYO {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Commission_FilterInput__3ZMxN {
    margin-right: 2rem;
}



.Commission_FormGroupDefault__fPz_y.Commission_has-error__2y3AX {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Commission_FormGroupDefault__fPz_y {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Commission_FormGroupDefault__fPz_y label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Commission_FormGroup__XFQcL label:not(.Commission_error__3nzZh) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Commission_FormControl__2ou5l {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Commission_MerchantTableWrapper__nZil9 {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Commission_TableWrapper__3K7OK {
    display: flex;
}

.Commission_MerchantTable__1mG0W {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Commission_MerchantTable__1mG0W th {
    text-align: left;
}

.Commission_MerchantTable__1mG0W th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Commission_MerchantTable__1mG0W td,
.Commission_MerchantTable__1mG0W th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Commission_MerchantTable__1mG0W td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Commission_MerchantTable__1mG0W td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Commission_RevealMoreHeader__xvbYs {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Commission_Row__3nBAO {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Commission_Row__3nBAO>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Commission_dataTables_paginate__2ZuQD {
    float: right;
}



div.Commission_dataTables_paginate__2ZuQD {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Commission_dataTables_paginate__2ZuQD ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Commission_dataTables_paginate__2ZuQD ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Commission_dataTables_paginate__2ZuQD ul>li.Commission_disabled__1J_r- a {
    opacity: .5;
}

.Commission_dataTables_paginate__2ZuQD ul>li.Commission_disabled__1J_r- a {
    opacity: .35;
}

.Commission_dataTables_paginate__2ZuQD ul>li.Commission_active__Ct1Ob>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Commission_dataTables_paginate__2ZuQD ul>li.Commission_next__EZCHm>a,
.Commission_dataTables_paginate__2ZuQD ul>li.Commission_prev__2rqDh>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commission_pg_arrow_right__1QocX {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commission_pg_arrow_left__1QfGh {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commission_pg_arrow_right__1QocX:before {
    content: "\E631";
}

.Commission_pg_arrow_left__1QfGh:before {
    content: "\E629";
}

.Commission_btn__kO0Fu {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Commission_btn_primary__38k9M,
.Commission_btn_primary__38k9M:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Commission_btn_cons__XZvCw {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Commission_MerchantRow__25SZ3 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Commission_MerchantRow__25SZ3:hover {
    background-color: #267788;
    color: #fff;

}

.Commission_MerchantRow__25SZ3:hover a {
    color: #fff;
}

.Commission_MerchantRowExpanded__21mkA {
    background: #267788;
    color: #fff;
}

.Commission_table__1Ozei.Commission_table-condensed__1qYG5.Commission_table-detailed__3Vj7M>tbody>tr.Commission_shown__2xR70>td {
    background: #fdf5df;
}

.Commission_ActivateDevice__QUh3b {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Commission_ActivateDeviceHeader__33GpC {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Commission_ActivateDeviceBody__2hp2A {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}


.Commission_EnableDevice__3wQI8 {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Commission_EnableDeviceHeader__3BQqC {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Commission_EnableDeviceBody__XaESd {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.Credit_Container__34TMq {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Credit_Tools__20fb5 {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Credit_PageFilter__16oz7 {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Credit_ToolsWrapper__3MhTY {
    height: 6rem;
    width: 100%;

}

.Credit_PrintToolsWrapper__1XYqw {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Credit_AddNewMerchant__1qr-a {
    /* // font-family: Cambay; */
}

.Credit_AddNewMerchantBtn__1x7mh {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Credit_AddNewMerchantBtn__1x7mh:focus,
.Credit_AddNewMerchantBtn__1x7mh:hover,
.Credit_AddNewMerchantBtn__1x7mh:active {
    background-color: rgb(51, 137, 255);
}


.Credit_ToolsDataExportIconWrapper__3WQ2H {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Credit_ToolsPrintIconWrapper__BlPyt {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Credit_FilterInput__21GDi {
    margin-right: 2rem;
}

.Credit_FormGroup__3YW7E {
    /* margin-bottom: 10px; */
}

.Credit_FormGroupDefault__1mv6-.Credit_has-error__3vcXm {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Credit_FormGroupDefault__1mv6- {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Credit_FormGroupDefault__1mv6- label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Credit_FormGroup__3YW7E label:not(.Credit_error__3JheY) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Credit_FormControl__C0boQ {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Credit_MerchantTableWrapper__eZ3Gf {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Credit_TableWrapper__3gIES {
    display: flex;
}

.Credit_MerchantTable__2W7La {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Credit_MerchantTable__2W7La th {
    text-align: left;
}

.Credit_MerchantTable__2W7La th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Credit_MerchantTable__2W7La td,
.Credit_MerchantTable__2W7La th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Credit_MerchantTable__2W7La td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Credit_MerchantTable__2W7La td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Credit_RevealMoreHeader__31MWF {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Credit_Row__1B2_B {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Credit_Row__1B2_B>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Credit_dataTables_paginate__1uztq {
    float: right;
}

.Credit_dataTables_paginate__1uztq {
    /* margin-top: 25px !important; */
}

div.Credit_dataTables_paginate__1uztq {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Credit_dataTables_paginate__1uztq ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Credit_dataTables_paginate__1uztq ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Credit_dataTables_paginate__1uztq ul>li.Credit_disabled__2uXie a {
    opacity: .5;
}

.Credit_dataTables_paginate__1uztq ul>li.Credit_disabled__2uXie a {
    opacity: .35;
}

.Credit_dataTables_paginate__1uztq ul>li.Credit_active__2QaWt>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Credit_dataTables_paginate__1uztq ul>li.Credit_next__aYGdZ>a,
.Credit_dataTables_paginate__1uztq ul>li.Credit_prev__2kWD9>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Credit_pg_arrow_right__k13bk {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Credit_pg_arrow_left__Ck_XR {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Credit_pg_arrow_right__k13bk:before {
    content: "\E631";
}

.Credit_pg_arrow_left__Ck_XR:before {
    content: "\E629";
}

.Credit_btn__2yMxE {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Credit_btn_primary__3vhMq,
.Credit_btn_primary__3vhMq:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Credit_btn_cons__3_8zh {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Credit_TableRow__lpVWE {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Credit_TableRow__lpVWE:hover {
    background-color: #267788;
    color: #fff;

}

.Credit_TableRow__lpVWE:hover a {
    color: #fff;
}

.Credit_TableRowExpanded__2ccPv {
    background: #267788;
    color: #fff;
}

.Credit_SelectedTableRow__2D4MA {
    background-color: #267788;
    color: #fff;

}

.Credit_table__xZ4rD.Credit_table-condensed__2CqD-.Credit_table-detailed__3Z_wy>tbody>tr.Credit_shown__3XG4b>td {
    background: #fdf5df;
}

.Credit_EnableMerchant__1bOhX {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Credit_EnableMerchantHeader__2fL-u {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Credit_EnableMerchantBody__1Vumx {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}

.settings_Container__3swYu {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.settings_BreadcrumbWrapper__2XkON {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.settings_Content__QcWHt {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.settings_Alert__1IQ13 {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.settings_Breadcrumb__jenB0 {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.settings_Header__3Q0bf {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.settings_ByLine__2MiCE {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.settings_Activity__39HqN {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.settings_WelcomeContainer__3gMDA {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.settings_MerchantProfile__36WFu {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.settings_TabHeader__3WIPm {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.settings_PageHeader__uz8Cp {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.settings_AddUser__fF6LS {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.settings_AddUserBtn__1Rtfh {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}
.Merchant_Container__dHPld {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Merchant_BreadcrumbWrapper__2RgY9 {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Merchant_Content__9jr3d {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Merchant_Alert__12U1T {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Merchant_Breadcrumb__3HfAu {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Merchant_Header__3srXc {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Merchant_ByLine__1mDpW {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Merchant_Activity__WiTEw {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Merchant_WelcomeContainer__2x5AM {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Merchant_MerchantProfile__3NccW {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Merchant_TabHeader__1dOR6 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Merchant_PageHeader__L3LJY {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Merchant_AddUser__3I9of {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Merchant_AddUserBtn__2Ek0u {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Merchant_ReportContainer__36R93 {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
}

.Merchant_ReportTiles__16b4X {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.Merchant_TileContainer__2etJN {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    -webkit-transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    /* width: 11.2rem; */
}

.Merchant_Tile__22Iiz {
    cursor: pointer;
    padding: 4px;
    display: flex;
    width: 20rem;
    background-color: #f2f2f2;
    justify-content: center;
    flex-direction: column;
    height: 100%;

}


.Merchant_Tile__22Iiz:hover {
    background-color: #e6e6e6;
}

.Merchant_TileInner__2degZ {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
}

.Merchant_TileIcon__2mtd5 {
    margin-bottom: 5px;
}

.Merchant_TileText__J3x37 {
    font-size: 15px;
    color: #262626;
}
.Cachet_Container__QRHUi {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Cachet_BreadcrumbWrapper__2f0_1 {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Cachet_Content__KcyMV {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Cachet_Alert__BGi82 {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Cachet_Breadcrumb__3ErLJ {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Cachet_Header__rqKsc {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Cachet_ByLine__1TZuf {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Cachet_Activity__1bRDt {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Cachet_WelcomeContainer__2IdTp {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Cachet_MerchantProfile__2AjBY {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Cachet_TabHeader__3UCgg {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Cachet_PageHeader__1hH-K {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Cachet_AddUser__3JhZi {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Cachet_AddUserBtn__2usDL {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Cachet_ReportContainer__1SBkr {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
}

.Cachet_ReportTiles__3QV5_ {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.Cachet_TileContainer__1WvMA {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    -webkit-transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}


.Cachet_Tile__2-2Hq {
    cursor: pointer;
    padding: 4px;
    display: flex;
    width: 20rem;
    background-color: #f2f2f2;
    justify-content: center;
    flex-direction: column;
    height: 100%;

}


.Cachet_Tile__2-2Hq:hover {
    background-color: #e6e6e6;
}

.Cachet_TileInner__1U9vz {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
}

.Cachet_TileIcon__HWs5Q {
    margin-bottom: 5px;
}

.Cachet_TileText__37qBp {
    font-size: 15px;
    color: #262626;
}
.ItemisedReport_Container__1-Rc_ {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.ItemisedReport_BreadcrumbWrapper__S1Xcd {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.ItemisedReport_Content__1GN2e {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.ItemisedReport_Alert__1S0ja {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.ItemisedReport_Breadcrumb__1P181 {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.ItemisedReport_Header__1MfJ5 {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.ItemisedReport_ByLine__2tioc {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.ItemisedReport_Activity__1s2um {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.ItemisedReport_WelcomeContainer__3wuo1 {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.ItemisedReport_MerchantProfile__1yWoI {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.ItemisedReport_TabHeader__5k0OR {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.ItemisedReport_PageHeader__1NCtI {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.ItemisedReport_AddUser__2Fnx_ {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.ItemisedReport_AddUserBtn__-iRvb {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.ItemisedReport_ReportContainer__1DvqD {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
}

.ItemisedReport_ReportTiles__3vARR {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.ItemisedReport_TileContainer__2ZunG {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    -webkit-transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.ItemisedReport_Tile__2OTZe {
    cursor: pointer;
    padding: 4px;
}

.ItemisedReport_TileInner__3cRlK {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.Itemised_Container__cFoKt {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Itemised_Tools__2fdaZ {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}

.Itemised_PageFilter__3tJJ2 {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.Itemised_ToolsWrapper__2cK66 {
    height: 6rem;
    width: 100%;

}

.Itemised_PrintToolsWrapper__3eHuR {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Itemised_AddNewMerchant__-g0Dy {
    /* // font-family: Cambay; */
}

.Itemised_FilterReportBtn__27tyc {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Itemised_AddNewMerchantBtn__1PX2t:focus,
.Itemised_AddNewMerchantBtn__1PX2t:hover,
.Itemised_AddNewMerchantBtn__1PX2t:active {
    background-color: rgb(51, 137, 255);
}


.Itemised_ToolsDataExportIconWrapper__1dT6b {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Itemised_ToolsPrintIconWrapper__2R2Qu {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Itemised_FilterInput__3ArJL {
    margin-right: 2rem;
}

.Itemised_FormGroup__3hUky {
    /* margin-bottom: 10px; */
}

.Itemised_FormGroupDefault__1x-tj.Itemised_has-error__2FIRt {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Itemised_FormGroupDefault__1x-tj {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Itemised_FormGroupDefault__1x-tj label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Itemised_FormGroup__3hUky label:not(.Itemised_error__2VVeV) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Itemised_FormControl__2kmPy {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Itemised_MerchantTableWrapper__2UA1Y {
    margin-top: 3rem;
    white-space: nowrap;
}

.Itemised_TableWrapper__2-j2X {
    /* margin: 0 2.5rem; */
}

.Itemised_MerchantTable__29DB4 {
    width: 100%;
    border-collapse: collapse;
}

.Itemised_MerchantTable__29DB4 th {
    text-align: left;
}

.Itemised_MerchantTable__29DB4 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Itemised_MerchantTable__29DB4 td,
.Itemised_MerchantTable__29DB4 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Itemised_RevealMoreHeader__1WS2n {
    padding: 0;
    vertical-align: middle;
}





.Itemised_Row__2KuQZ {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Itemised_Row__2KuQZ>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Itemised_dataTables_paginate__32bu6 {
    float: right;
}

.Itemised_dataTables_paginate__32bu6 {
    /* margin-top: 25px !important; */
}

div.Itemised_dataTables_paginate__32bu6 {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Itemised_dataTables_paginate__32bu6 ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Itemised_dataTables_paginate__32bu6 ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Itemised_dataTables_paginate__32bu6 ul>li.Itemised_disabled__2_Q9y a {
    opacity: .5;
}

.Itemised_dataTables_paginate__32bu6 ul>li.Itemised_disabled__2_Q9y a {
    opacity: .35;
}

.Itemised_dataTables_paginate__32bu6 ul>li.Itemised_active__2-YOu>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Itemised_dataTables_paginate__32bu6 ul>li.Itemised_next__RUrMY>a,
.Itemised_dataTables_paginate__32bu6 ul>li.Itemised_prev__aEypQ>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_right__2Yg_f {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_left__1xxzy {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_right__2Yg_f:before {
    content: "\E631";
}

.Itemised_pg_arrow_left__1xxzy:before {
    content: "\E629";
}

.Itemised_btn__iX1VJ {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Itemised_btn_primary__3wcWC,
.Itemised_btn_primary__3wcWC:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Itemised_btn_cons__2wfQJ {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Itemised_MerchantName__28u4e {
    font-weight: 500;
}

.Itemised_Icn__ziw0z {
    cursor: pointer;
    /* fill:#000; */
}

.Itemised_Icn__ziw0z:hover {
    cursor: pointer;

}



.Itemised_RevealMore__15_PI {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Itemised_RevealMore__15_PI:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Itemised_RevealMore__15_PI div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Itemised_RevealMoreExpanded__3DsiH {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Itemised_Expandabled__27jYE {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Itemised_Expandabled__27jYE td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Itemised_MerchantTable__29DB4 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Itemised_MerchantTable__29DB4 td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Itemised_MerchantRow__a6enO {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Itemised_MerchantRow__a6enO:hover {
    background-color: #267788;
    color: #fff;

}

.Itemised_MerchantRow__a6enO:hover a {
    color: #fff;
}

.Itemised_MerchantRowExpanded__3P1-J {
    background: #267788;
    color: #fff;
}

.Itemised_table__Z64Gg.Itemised_table-condensed__NrQF7.Itemised_table-detailed__2c8lZ>tbody>tr.Itemised_shown__3MFmJ>td {
    background: #fdf5df;
}


.Itemised_Totals__3wCbw td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Itemised_MoneyTotalLabel__1PdOh {
    float: right;

}
.Category_Container__3fv65 {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Category_BreadcrumbWrapper__39eIy {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Category_Content__2HXFS {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Category_Alert__Hvk_a {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Category_Breadcrumb__3m67J {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Category_Header__3znBj {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Category_ByLine__3rhn6 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Category_Activity__2NKMg {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Category_WelcomeContainer__2rtMe {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Category_MerchantProfile__2md3f {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Category_TabHeader__1JvGF {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Category_PageHeader__1vlS2 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Category_AddUser__lGFXI {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Category_AddUserBtn__1NUZ9 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Category_ReportContainer__gjpY0 {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
}

.Category_ReportTiles__2ZmQ4 {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.Category_TileContainer__1J1IR {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    -webkit-transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.Category_Tile__K35aj {
    cursor: pointer;
    padding: 4px;
}

.Category_TileInner__Ih_CJ {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.ReportBody_Container__3AviD {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.ReportBody_Tools__bKPxX {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}

.ReportBody_PageFilter__MQOAr {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.ReportBody_ToolsWrapper__2TMmI {
    height: 6rem;
    width: 100%;

}

.ReportBody_PrintToolsWrapper__m9Xf- {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.ReportBody_AddNewMerchant__2N1Q0 {
    /* // font-family: Cambay; */
}

.ReportBody_FilterReportBtn__2IdJw {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.ReportBody_AddNewMerchantBtn__1Y5_S:focus,
.ReportBody_AddNewMerchantBtn__1Y5_S:hover,
.ReportBody_AddNewMerchantBtn__1Y5_S:active {
    background-color: rgb(51, 137, 255);
}


.ReportBody_ToolsDataExportIconWrapper__2Gz3M {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ReportBody_ToolsPrintIconWrapper__oz_0U {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ReportBody_FilterInput__PNYok {
    margin-right: 2rem;
}

.ReportBody_FormGroup__1miDy {
    /* margin-bottom: 10px; */
}

.ReportBody_FormGroupDefault__2fT13.ReportBody_has-error__1NWhr {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReportBody_FormGroupDefault__2fT13 {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ReportBody_FormGroupDefault__2fT13 label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.ReportBody_FormGroup__1miDy label:not(.ReportBody_error__31EM7) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ReportBody_FormControl__N1yUu {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ReportBody_MerchantTableWrapper__1r-oh {
    margin-top: 3rem;
    white-space: nowrap;
}

.ReportBody_TableWrapper__2mMX9 {
    /* margin: 0 2.5rem; */
}

.ReportBody_MerchantTable__1fDge {
    width: 100%;
    border-collapse: collapse;
}

.ReportBody_MerchantTable__1fDge th {
    text-align: left;
}

.ReportBody_MerchantTable__1fDge th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ReportBody_MerchantTable__1fDge td,
.ReportBody_MerchantTable__1fDge th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.ReportBody_RevealMoreHeader__KB00X {
    padding: 0;
    vertical-align: middle;
}





.ReportBody_Row__3ubOB {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ReportBody_Row__3ubOB>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ReportBody_dataTables_paginate__Suqip {
    float: right;
}

.ReportBody_dataTables_paginate__Suqip {
    /* margin-top: 25px !important; */
}

div.ReportBody_dataTables_paginate__Suqip {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ReportBody_dataTables_paginate__Suqip ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.ReportBody_dataTables_paginate__Suqip ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.ReportBody_dataTables_paginate__Suqip ul>li.ReportBody_disabled__ecTe5 a {
    opacity: .5;
}

.ReportBody_dataTables_paginate__Suqip ul>li.ReportBody_disabled__ecTe5 a {
    opacity: .35;
}

.ReportBody_dataTables_paginate__Suqip ul>li.ReportBody_active__1truJ>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ReportBody_dataTables_paginate__Suqip ul>li.ReportBody_next__3IBs1>a,
.ReportBody_dataTables_paginate__Suqip ul>li.ReportBody_prev__1yCvS>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__xwmUM {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_left__3FT5a {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__xwmUM:before {
    content: "\E631";
}

.ReportBody_pg_arrow_left__3FT5a:before {
    content: "\E629";
}

.ReportBody_btn__3hn2Q {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReportBody_btn_primary__3tX1Y,
.ReportBody_btn_primary__3tX1Y:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ReportBody_btn_cons__3uJby {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.ReportBody_MerchantName__2zrUG {
    font-weight: 500;
}

.ReportBody_Icn__1VBtn {
    cursor: pointer;
    /* fill:#000; */
}

.ReportBody_Icn__1VBtn:hover {
    cursor: pointer;

}



.ReportBody_RevealMore__hZuHA {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ReportBody_RevealMore__hZuHA:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ReportBody_RevealMore__hZuHA div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ReportBody_RevealMoreExpanded__2M7_s {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ReportBody_Expandabled__3_Mdl {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ReportBody_Expandabled__3_Mdl td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReportBody_MerchantTable__1fDge td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ReportBody_MerchantTable__1fDge td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ReportBody_MerchantRow__1qgPP {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ReportBody_MerchantRow__1qgPP:hover {
    background-color: #267788;
    color: #fff;

}

.ReportBody_MerchantRow__1qgPP:hover a {
    color: #fff;
}

.ReportBody_MerchantRowExpanded__1Xfx7 {
    background: #267788;
    color: #fff;
}

.ReportBody_table__1H45c.ReportBody_table-condensed__3z9Gp.ReportBody_table-detailed__1XcQz>tbody>tr.ReportBody_shown__hp93q>td {
    background: #fdf5df;
}


.ReportBody_Totals__3i9rd td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ReportBody_MoneyTotalLabel__IFCwA {
    float: right;

}
.User_Container__fVr-9 {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.User_BreadcrumbWrapper__3RthZ {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.User_Content__Sff4x {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.User_Alert___UuRw {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.User_Breadcrumb__1w2iE {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.User_Header__1VKpv {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.User_ByLine__RWtxR {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.User_Activity__32e4v {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.User_WelcomeContainer__2rg1u {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.User_MerchantProfile__1mbeW {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.User_TabHeader__28AC2 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.User_PageHeader__2oyC3 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.User_AddUser__WsPzP {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.User_AddUserBtn__2yr0_ {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.User_ReportContainer__hdE4X {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
}

.User_ReportTiles__2bCn4 {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.User_TileContainer__3TfRh {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    -webkit-transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.User_Tile__bSzH2 {
    cursor: pointer;
    padding: 4px;
}

.User_TileInner__27vwy {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.ReportBody_Container__35-mk {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.ReportBody_Tools__B9zXy {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}

.ReportBody_PageFilter__JrUKy {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.ReportBody_ToolsWrapper__2yycd {
    height: 6rem;
    width: 100%;

}

.ReportBody_PrintToolsWrapper__26rKY {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.ReportBody_AddNewMerchant__1G7D1 {
    /* // font-family: Cambay; */
}

.ReportBody_FilterReportBtn__1gtyN {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.ReportBody_AddNewMerchantBtn__2Alae:focus,
.ReportBody_AddNewMerchantBtn__2Alae:hover,
.ReportBody_AddNewMerchantBtn__2Alae:active {
    background-color: rgb(51, 137, 255);
}


.ReportBody_ToolsDataExportIconWrapper__UgtB_ {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ReportBody_ToolsPrintIconWrapper__1Uup- {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ReportBody_FilterInput__33ncH {
    margin-right: 2rem;
}

.ReportBody_FormGroup__3UrIj {
    /* margin-bottom: 10px; */
}

.ReportBody_FormGroupDefault__1NMHt.ReportBody_has-error__2RlDo {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReportBody_FormGroupDefault__1NMHt {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ReportBody_FormGroupDefault__1NMHt label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.ReportBody_FormGroup__3UrIj label:not(.ReportBody_error__2mJ3U) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ReportBody_FormControl__8X8gi {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ReportBody_MerchantTableWrapper__1hgj1 {
    margin-top: 3rem;
    white-space: nowrap;
}

.ReportBody_TableWrapper__2vJwO {
    /* margin: 0 2.5rem; */
}

.ReportBody_MerchantTable__1FVEW {
    width: 100%;
    border-collapse: collapse;
}

.ReportBody_MerchantTable__1FVEW th {
    text-align: left;
}

.ReportBody_MerchantTable__1FVEW th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ReportBody_MerchantTable__1FVEW td,
.ReportBody_MerchantTable__1FVEW th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.ReportBody_RevealMoreHeader__3PL_L {
    padding: 0;
    vertical-align: middle;
}





.ReportBody_Row__28y8N {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ReportBody_Row__28y8N>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ReportBody_dataTables_paginate__1Ly_2 {
    float: right;
}

.ReportBody_dataTables_paginate__1Ly_2 {
    /* margin-top: 25px !important; */
}

div.ReportBody_dataTables_paginate__1Ly_2 {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ReportBody_dataTables_paginate__1Ly_2 ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.ReportBody_dataTables_paginate__1Ly_2 ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.ReportBody_dataTables_paginate__1Ly_2 ul>li.ReportBody_disabled__tCdoW a {
    opacity: .5;
}

.ReportBody_dataTables_paginate__1Ly_2 ul>li.ReportBody_disabled__tCdoW a {
    opacity: .35;
}

.ReportBody_dataTables_paginate__1Ly_2 ul>li.ReportBody_active__3a9Li>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ReportBody_dataTables_paginate__1Ly_2 ul>li.ReportBody_next__2unNj>a,
.ReportBody_dataTables_paginate__1Ly_2 ul>li.ReportBody_prev__33_mR>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__22_XT {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_left__tb0B3 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__22_XT:before {
    content: "\E631";
}

.ReportBody_pg_arrow_left__tb0B3:before {
    content: "\E629";
}

.ReportBody_btn__1TzqR {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReportBody_btn_primary__28eod,
.ReportBody_btn_primary__28eod:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ReportBody_btn_cons__1y7b2 {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.ReportBody_MerchantName__2bhd9 {
    font-weight: 500;
}

.ReportBody_Icn__3KCaq {
    cursor: pointer;
    /* fill:#000; */
}

.ReportBody_Icn__3KCaq:hover {
    cursor: pointer;

}



.ReportBody_RevealMore__1Gny6 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ReportBody_RevealMore__1Gny6:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ReportBody_RevealMore__1Gny6 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ReportBody_RevealMoreExpanded__3joto {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ReportBody_Expandabled__1stx_ {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ReportBody_Expandabled__1stx_ td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReportBody_MerchantTable__1FVEW td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ReportBody_MerchantTable__1FVEW td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ReportBody_MerchantRow__Dspy1 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ReportBody_MerchantRow__Dspy1:hover {
    background-color: #267788;
    color: #fff;

}

.ReportBody_MerchantRow__Dspy1:hover a {
    color: #fff;
}

.ReportBody_MerchantRowExpanded__QlZH0 {
    background: #267788;
    color: #fff;
}

.ReportBody_table__3llKa.ReportBody_table-condensed__2MYgz.ReportBody_table-detailed__16uPe>tbody>tr.ReportBody_shown__1QeOF>td {
    background: #fdf5df;
}


.ReportBody_Totals__2zXHk td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ReportBody_MoneyTotalLabel__2L-w0 {
    float: right;

}
.Date_Container__3HqmG {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Date_BreadcrumbWrapper__LgzAI {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Date_Content__3cXCz {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Date_Alert__O2EUg {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Date_Breadcrumb__nwDlU {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Date_Header__3P_zp {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Date_ByLine__2caO_ {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Date_Activity__ORe4w {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Date_WelcomeContainer__3sPI6 {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Date_MerchantProfile__2-eI1 {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Date_TabHeader__1ffpt {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Date_PageHeader__2dOPk {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Date_AddUser__2scoh {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Date_AddUserBtn__18nOQ {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Date_ReportContainer__2I-gT {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
}

.Date_ReportTiles__2EyJR {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.Date_TileContainer__2z1RS {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    -webkit-transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.Date_Tile__n-pnL {
    cursor: pointer;
    padding: 4px;
}

.Date_TileInner__3wLwA {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.ReportBody_Container__1diFo {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.ReportBody_Tools__2ze-- {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}

.ReportBody_PageFilter__1Muaj {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.ReportBody_ToolsWrapper__ZoWsr {
    height: 6rem;
    width: 100%;

}

.ReportBody_PrintToolsWrapper__20G4g {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.ReportBody_AddNewMerchant__QyBkL {
    /* // font-family: Cambay; */
}

.ReportBody_FilterReportBtn__PMZSV {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.ReportBody_AddNewMerchantBtn__Yl-Wq:focus,
.ReportBody_AddNewMerchantBtn__Yl-Wq:hover,
.ReportBody_AddNewMerchantBtn__Yl-Wq:active {
    background-color: rgb(51, 137, 255);
}


.ReportBody_ToolsDataExportIconWrapper__1uV7f {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ReportBody_ToolsPrintIconWrapper__2rJbg {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ReportBody_FilterInput__3-3ap {
    margin-right: 2rem;
}

.ReportBody_FormGroup__2GD9D {
    /* margin-bottom: 10px; */
}

.ReportBody_FormGroupDefault__27e5T.ReportBody_has-error__2vlfC {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReportBody_FormGroupDefault__27e5T {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ReportBody_FormGroupDefault__27e5T label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.ReportBody_FormGroup__2GD9D label:not(.ReportBody_error__1yQF6) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ReportBody_FormControl__1ce7A {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ReportBody_MerchantTableWrapper__1id89 {
    margin-top: 3rem;
    white-space: nowrap;
}

.ReportBody_TableWrapper__3RpOf {
    /* margin: 0 2.5rem; */
}

.ReportBody_MerchantTable__1LVPa {
    width: 100%;
    border-collapse: collapse;
}

.ReportBody_MerchantTable__1LVPa th {
    text-align: left;
}

.ReportBody_MerchantTable__1LVPa th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ReportBody_MerchantTable__1LVPa td,
.ReportBody_MerchantTable__1LVPa th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.ReportBody_RevealMoreHeader__151H8 {
    padding: 0;
    vertical-align: middle;
}





.ReportBody_Row__11vpx {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ReportBody_Row__11vpx>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ReportBody_dataTables_paginate__2WRsV {
    float: right;
}

.ReportBody_dataTables_paginate__2WRsV {
    /* margin-top: 25px !important; */
}

div.ReportBody_dataTables_paginate__2WRsV {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ReportBody_dataTables_paginate__2WRsV ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.ReportBody_dataTables_paginate__2WRsV ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.ReportBody_dataTables_paginate__2WRsV ul>li.ReportBody_disabled__4FAN5 a {
    opacity: .5;
}

.ReportBody_dataTables_paginate__2WRsV ul>li.ReportBody_disabled__4FAN5 a {
    opacity: .35;
}

.ReportBody_dataTables_paginate__2WRsV ul>li.ReportBody_active__3Tmrc>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ReportBody_dataTables_paginate__2WRsV ul>li.ReportBody_next__2FH4z>a,
.ReportBody_dataTables_paginate__2WRsV ul>li.ReportBody_prev__UkZGf>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__-SszD {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_left__3VXQJ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__-SszD:before {
    content: "\E631";
}

.ReportBody_pg_arrow_left__3VXQJ:before {
    content: "\E629";
}

.ReportBody_btn__hh5Re {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReportBody_btn_primary__2QxUl,
.ReportBody_btn_primary__2QxUl:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ReportBody_btn_cons__y5nRQ {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.ReportBody_MerchantName__MMPze {
    font-weight: 500;
}

.ReportBody_Icn__1pu6H {
    cursor: pointer;
    /* fill:#000; */
}

.ReportBody_Icn__1pu6H:hover {
    cursor: pointer;

}



.ReportBody_RevealMore__PeCM7 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ReportBody_RevealMore__PeCM7:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ReportBody_RevealMore__PeCM7 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ReportBody_RevealMoreExpanded__3SDUU {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ReportBody_Expandabled__27i3Y {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ReportBody_Expandabled__27i3Y td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReportBody_MerchantTable__1LVPa td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ReportBody_MerchantTable__1LVPa td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ReportBody_MerchantRow__26hAc {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ReportBody_MerchantRow__26hAc:hover {
    background-color: #267788;
    color: #fff;

}

.ReportBody_MerchantRow__26hAc:hover a {
    color: #fff;
}

.ReportBody_MerchantRowExpanded__iUJIj {
    background: #267788;
    color: #fff;
}

.ReportBody_table__26qas.ReportBody_table-condensed__23iS-.ReportBody_table-detailed__1eT5s>tbody>tr.ReportBody_shown__3Lbri>td {
    background: #fdf5df;
}


.ReportBody_Totals__n1_iA td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ReportBody_MoneyTotalLabel__18fNx {
    float: right;

}
.ItemisedReport_Container__gZIQy {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.ItemisedReport_BreadcrumbWrapper__3l_R_ {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.ItemisedReport_Content__2ct_k {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.ItemisedReport_Alert__3fKx7 {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.ItemisedReport_Breadcrumb__2vGp9 {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.ItemisedReport_Header__1l71e {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.ItemisedReport_ByLine__1wvPF {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.ItemisedReport_Activity__o3nRf {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.ItemisedReport_WelcomeContainer__3NUhy {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.ItemisedReport_MerchantProfile__2Sdd1 {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.ItemisedReport_TabHeader__3sYbs {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.ItemisedReport_PageHeader__1m5UW {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.ItemisedReport_AddUser__3Tmxh {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.ItemisedReport_AddUserBtn__36S7b {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.ItemisedReport_ReportContainer__Du-gr {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
}

.ItemisedReport_ReportTiles__1fTlW {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.ItemisedReport_TileContainer__2xaqr {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    -webkit-transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.ItemisedReport_Tile__1_m28 {
    cursor: pointer;
    padding: 4px;
}

.ItemisedReport_TileInner__3ug2U {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.Itemised_Container__bgaT6 {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Itemised_Tools__-PX9b {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}

.Itemised_PageFilter__1Tkcc {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.Itemised_ToolsWrapper__2Lcty {
    height: 6rem;
    width: 100%;

}

.Itemised_PrintToolsWrapper__3Mdr9 {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Itemised_AddNewMerchant__1MCpV {
    /* // font-family: Cambay; */
}

.Itemised_FilterReportBtn__3Bljc {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Itemised_AddNewMerchantBtn__2LMMN:focus,
.Itemised_AddNewMerchantBtn__2LMMN:hover,
.Itemised_AddNewMerchantBtn__2LMMN:active {
    background-color: rgb(51, 137, 255);
}


.Itemised_ToolsDataExportIconWrapper__1wl-T {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Itemised_ToolsPrintIconWrapper__iTBU0 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Itemised_FilterInput__2FToG {
    margin-right: 2rem;
}

.Itemised_FormGroup__2T_Rw {
    /* margin-bottom: 10px; */
}

.Itemised_FormGroupDefault__V9ZI7.Itemised_has-error__Z3vIU {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Itemised_FormGroupDefault__V9ZI7 {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Itemised_FormGroupDefault__V9ZI7 label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Itemised_FormGroup__2T_Rw label:not(.Itemised_error__3RmQV) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Itemised_FormControl__16JXo {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Itemised_MerchantTableWrapper__BTtrE {
    margin-top: 3rem;
    white-space: nowrap;
}

.Itemised_TableWrapper__1d-pt {
    /* margin: 0 2.5rem; */
}

.Itemised_MerchantTable__2bXhv {
    width: 100%;
    border-collapse: collapse;
}

.Itemised_MerchantTable__2bXhv th {
    text-align: left;
}

.Itemised_MerchantTable__2bXhv th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Itemised_MerchantTable__2bXhv td,
.Itemised_MerchantTable__2bXhv th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Itemised_RevealMoreHeader__225ww {
    padding: 0;
    vertical-align: middle;
}





.Itemised_Row__1WHNe {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Itemised_Row__1WHNe>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Itemised_dataTables_paginate__YNVEJ {
    float: right;
}

.Itemised_dataTables_paginate__YNVEJ {
    /* margin-top: 25px !important; */
}

div.Itemised_dataTables_paginate__YNVEJ {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Itemised_dataTables_paginate__YNVEJ ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Itemised_dataTables_paginate__YNVEJ ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Itemised_dataTables_paginate__YNVEJ ul>li.Itemised_disabled__2R0V6 a {
    opacity: .5;
}

.Itemised_dataTables_paginate__YNVEJ ul>li.Itemised_disabled__2R0V6 a {
    opacity: .35;
}

.Itemised_dataTables_paginate__YNVEJ ul>li.Itemised_active__31D-o>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Itemised_dataTables_paginate__YNVEJ ul>li.Itemised_next__3BAA2>a,
.Itemised_dataTables_paginate__YNVEJ ul>li.Itemised_prev__dIIg_>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_right__2KNh9 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_left__1r4o_ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_right__2KNh9:before {
    content: "\E631";
}

.Itemised_pg_arrow_left__1r4o_:before {
    content: "\E629";
}

.Itemised_btn__2vwJ2 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Itemised_btn_primary__36u8f,
.Itemised_btn_primary__36u8f:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Itemised_btn_cons__20fL5 {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Itemised_MerchantName__16IAR {
    font-weight: 500;
}

.Itemised_Icn__QpQon {
    cursor: pointer;
    /* fill:#000; */
}

.Itemised_Icn__QpQon:hover {
    cursor: pointer;

}



.Itemised_RevealMore__4eW8b {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Itemised_RevealMore__4eW8b:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Itemised_RevealMore__4eW8b div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Itemised_RevealMoreExpanded__EZpPk {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Itemised_Expandabled__3f6F8 {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Itemised_Expandabled__3f6F8 td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Itemised_MerchantTable__2bXhv td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Itemised_MerchantTable__2bXhv td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Itemised_MerchantRow__5rdKw {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Itemised_MerchantRow__5rdKw:hover {
    background-color: #267788;
    color: #fff;

}

.Itemised_MerchantRow__5rdKw:hover a {
    color: #fff;
}

.Itemised_MerchantRowExpanded__1IbMr {
    background: #267788;
    color: #fff;
}

.Itemised_table__2BwpX.Itemised_table-condensed__3PhC-.Itemised_table-detailed__2hALt>tbody>tr.Itemised_shown__4iVaJ>td {
    background: #fdf5df;
}


.Itemised_Totals__WZkDK td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Itemised_MoneyTotalLabel___92nz {
    float: right;

}
.Category_Container__1-c0q {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Category_BreadcrumbWrapper__2ZRAD {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Category_Content__atDRY {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Category_Alert__15-bz {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Category_Breadcrumb__1SOzL {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Category_Header__35cM- {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Category_ByLine__2Y6TJ {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Category_Activity__11JCu {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Category_WelcomeContainer__uv-1r {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Category_MerchantProfile__3BAKH {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Category_TabHeader__faiA1 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Category_PageHeader__b1fCZ {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Category_AddUser__2ZnKR {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Category_AddUserBtn__37w1Q {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Category_ReportContainer__HsfBL {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
}

.Category_ReportTiles__8vOta {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.Category_TileContainer__2UsvP {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    -webkit-transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.Category_Tile__1NOrY {
    cursor: pointer;
    padding: 4px;
}

.Category_TileInner__1G_YP {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.ReportBody_Container__12ZfQ {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.ReportBody_Tools__2dI49 {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}

.ReportBody_PageFilter__2zfHK {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.ReportBody_ToolsWrapper__hWUl9 {
    height: 6rem;
    width: 100%;

}

.ReportBody_PrintToolsWrapper__3v5f7 {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.ReportBody_AddNewMerchant__cEfxY {
    /* // font-family: Cambay; */
}

.ReportBody_FilterReportBtn__2rclr {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.ReportBody_AddNewMerchantBtn__Y34Nl:focus,
.ReportBody_AddNewMerchantBtn__Y34Nl:hover,
.ReportBody_AddNewMerchantBtn__Y34Nl:active {
    background-color: rgb(51, 137, 255);
}


.ReportBody_ToolsDataExportIconWrapper__3nS89 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ReportBody_ToolsPrintIconWrapper__3bvv5 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ReportBody_FilterInput__1yvLO {
    margin-right: 2rem;
}

.ReportBody_FormGroup__2QNGT {
    /* margin-bottom: 10px; */
}

.ReportBody_FormGroupDefault__1FCYN.ReportBody_has-error__1eAwQ {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReportBody_FormGroupDefault__1FCYN {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ReportBody_FormGroupDefault__1FCYN label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.ReportBody_FormGroup__2QNGT label:not(.ReportBody_error__3BHzY) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ReportBody_FormControl__1nt-R {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ReportBody_MerchantTableWrapper__2garK {
    margin-top: 3rem;
    white-space: nowrap;
}

.ReportBody_TableWrapper__3Q7Lh {
    /* margin: 0 2.5rem; */
}

.ReportBody_MerchantTable__33njN {
    width: 100%;
    border-collapse: collapse;
}

.ReportBody_MerchantTable__33njN th {
    text-align: left;
}

.ReportBody_MerchantTable__33njN th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ReportBody_MerchantTable__33njN td,
.ReportBody_MerchantTable__33njN th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.ReportBody_RevealMoreHeader__3xLY9 {
    padding: 0;
    vertical-align: middle;
}





.ReportBody_Row__3c5f5 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ReportBody_Row__3c5f5>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ReportBody_dataTables_paginate__3FTi- {
    float: right;
}

.ReportBody_dataTables_paginate__3FTi- {
    /* margin-top: 25px !important; */
}

div.ReportBody_dataTables_paginate__3FTi- {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ReportBody_dataTables_paginate__3FTi- ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.ReportBody_dataTables_paginate__3FTi- ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.ReportBody_dataTables_paginate__3FTi- ul>li.ReportBody_disabled__dWlTG a {
    opacity: .5;
}

.ReportBody_dataTables_paginate__3FTi- ul>li.ReportBody_disabled__dWlTG a {
    opacity: .35;
}

.ReportBody_dataTables_paginate__3FTi- ul>li.ReportBody_active__v5aCw>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ReportBody_dataTables_paginate__3FTi- ul>li.ReportBody_next__1-sxT>a,
.ReportBody_dataTables_paginate__3FTi- ul>li.ReportBody_prev__2yn9s>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__3_tYm {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_left__3ndUL {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__3_tYm:before {
    content: "\E631";
}

.ReportBody_pg_arrow_left__3ndUL:before {
    content: "\E629";
}

.ReportBody_btn__3p2DC {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReportBody_btn_primary__kq7Zj,
.ReportBody_btn_primary__kq7Zj:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ReportBody_btn_cons__1gvkT {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.ReportBody_MerchantName__3esJU {
    font-weight: 500;
}

.ReportBody_Icn__2KIdb {
    cursor: pointer;
    /* fill:#000; */
}

.ReportBody_Icn__2KIdb:hover {
    cursor: pointer;

}



.ReportBody_RevealMore__2Boh6 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ReportBody_RevealMore__2Boh6:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ReportBody_RevealMore__2Boh6 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ReportBody_RevealMoreExpanded__2Hdnd {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ReportBody_Expandabled__1XkuM {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ReportBody_Expandabled__1XkuM td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReportBody_MerchantTable__33njN td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ReportBody_MerchantTable__33njN td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ReportBody_MerchantRow__1AxaK {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ReportBody_MerchantRow__1AxaK:hover {
    background-color: #267788;
    color: #fff;

}

.ReportBody_MerchantRow__1AxaK:hover a {
    color: #fff;
}

.ReportBody_MerchantRowExpanded__32R5P {
    background: #267788;
    color: #fff;
}

.ReportBody_table__3y5P4.ReportBody_table-condensed__4OXWz.ReportBody_table-detailed__3gzgk>tbody>tr.ReportBody_shown__1fZvD>td {
    background: #fdf5df;
}


.ReportBody_Totals__1Mc2Z td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ReportBody_MoneyTotalLabel__3a2-h {
    float: right;

}
.Date_Container__fxWea {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Date_BreadcrumbWrapper__1ntjh {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Date_Content__3uulZ {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Date_Alert__2wNpM {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Date_Breadcrumb__d_1nF {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Date_Header__332pM {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Date_ByLine__2rwNg {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Date_Activity__1Qw1q {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Date_WelcomeContainer__3uLwj {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Date_MerchantProfile__1rB-F {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Date_TabHeader__1hPvK {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Date_PageHeader__1K2tP {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Date_AddUser__1WK9k {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Date_AddUserBtn__30tWx {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Date_ReportContainer__m1U-W {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
}

.Date_ReportTiles__3-TBC {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.Date_TileContainer__nZ7NG {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    -webkit-transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.Date_Tile__1f78S {
    cursor: pointer;
    padding: 4px;
}

.Date_TileInner__2mXww {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.ReportBody_Container__2iAMS {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.ReportBody_Tools__3t3aD {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}

.ReportBody_PageFilter__3df1q {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.ReportBody_ToolsWrapper__ixmCd {
    height: 6rem;
    width: 100%;

}

.ReportBody_PrintToolsWrapper__3Vjl7 {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.ReportBody_AddNewMerchant__1-LrV {
    /* // font-family: Cambay; */
}

.ReportBody_FilterReportBtn__3ondU {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.ReportBody_AddNewMerchantBtn__2w-BI:focus,
.ReportBody_AddNewMerchantBtn__2w-BI:hover,
.ReportBody_AddNewMerchantBtn__2w-BI:active {
    background-color: rgb(51, 137, 255);
}


.ReportBody_ToolsDataExportIconWrapper__1RbDf {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ReportBody_ToolsPrintIconWrapper__3Bk9A {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ReportBody_FilterInput__3wg8L {
    margin-right: 2rem;
}

.ReportBody_FormGroup__1JdOF {
    /* margin-bottom: 10px; */
}

.ReportBody_FormGroupDefault__2I_vn.ReportBody_has-error__O4rnt {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReportBody_FormGroupDefault__2I_vn {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ReportBody_FormGroupDefault__2I_vn label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.ReportBody_FormGroup__1JdOF label:not(.ReportBody_error__1V7Ty) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ReportBody_FormControl__3nKgL {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ReportBody_MerchantTableWrapper__2nzP7 {
    margin-top: 3rem;
    white-space: nowrap;
}

.ReportBody_TableWrapper__kJr6S {
    /* margin: 0 2.5rem; */
}

.ReportBody_MerchantTable__1m7Of {
    width: 100%;
    border-collapse: collapse;
}

.ReportBody_MerchantTable__1m7Of th {
    text-align: left;
}

.ReportBody_MerchantTable__1m7Of th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ReportBody_MerchantTable__1m7Of td,
.ReportBody_MerchantTable__1m7Of th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.ReportBody_RevealMoreHeader__2rfho {
    padding: 0;
    vertical-align: middle;
}





.ReportBody_Row__2FdsK {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ReportBody_Row__2FdsK>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ReportBody_dataTables_paginate__2EGbO {
    float: right;
}

.ReportBody_dataTables_paginate__2EGbO {
    /* margin-top: 25px !important; */
}

div.ReportBody_dataTables_paginate__2EGbO {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ReportBody_dataTables_paginate__2EGbO ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.ReportBody_dataTables_paginate__2EGbO ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.ReportBody_dataTables_paginate__2EGbO ul>li.ReportBody_disabled__1pz-O a {
    opacity: .5;
}

.ReportBody_dataTables_paginate__2EGbO ul>li.ReportBody_disabled__1pz-O a {
    opacity: .35;
}

.ReportBody_dataTables_paginate__2EGbO ul>li.ReportBody_active__1DD-_>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ReportBody_dataTables_paginate__2EGbO ul>li.ReportBody_next__2_q_G>a,
.ReportBody_dataTables_paginate__2EGbO ul>li.ReportBody_prev__1FCrU>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__3hOTw {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_left__3OrEK {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__3hOTw:before {
    content: "\E631";
}

.ReportBody_pg_arrow_left__3OrEK:before {
    content: "\E629";
}

.ReportBody_btn__m3WIZ {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReportBody_btn_primary__3U41i,
.ReportBody_btn_primary__3U41i:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ReportBody_btn_cons__HtJfv {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.ReportBody_MerchantName__2-7Nz {
    font-weight: 500;
}

.ReportBody_Icn__Nz45t {
    cursor: pointer;
    /* fill:#000; */
}

.ReportBody_Icn__Nz45t:hover {
    cursor: pointer;

}



.ReportBody_RevealMore__1wEbi {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ReportBody_RevealMore__1wEbi:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ReportBody_RevealMore__1wEbi div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ReportBody_RevealMoreExpanded__3w6Ec {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ReportBody_Expandabled__3h2DA {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ReportBody_Expandabled__3h2DA td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReportBody_MerchantTable__1m7Of td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ReportBody_MerchantTable__1m7Of td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ReportBody_MerchantRow__2zsid {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ReportBody_MerchantRow__2zsid:hover {
    background-color: #267788;
    color: #fff;

}

.ReportBody_MerchantRow__2zsid:hover a {
    color: #fff;
}

.ReportBody_MerchantRowExpanded__3y_tN {
    background: #267788;
    color: #fff;
}

.ReportBody_table__1FOHA.ReportBody_table-condensed__2bLiO.ReportBody_table-detailed__1HoPK>tbody>tr.ReportBody_shown__3v9mU>td {
    background: #fdf5df;
}


.ReportBody_Totals__2ucju td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ReportBody_MoneyTotalLabel__1DpSI {
    float: right;

}
.ItemisedReport_Container__2pFqR {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.ItemisedReport_BreadcrumbWrapper__2CdWb {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.ItemisedReport_Content__6wD-q {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.ItemisedReport_Alert__362HA {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.ItemisedReport_Breadcrumb__DRmFo {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.ItemisedReport_Header__1PzDK {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.ItemisedReport_ByLine___U0Vk {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.ItemisedReport_Activity__2dtg6 {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.ItemisedReport_WelcomeContainer__2Zrjm {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.ItemisedReport_MerchantProfile__3KwJD {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.ItemisedReport_TabHeader__1NqMj {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.ItemisedReport_PageHeader__1kH42 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.ItemisedReport_AddUser__1AkqV {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.ItemisedReport_AddUserBtn__3Yu4- {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.ItemisedReport_ReportContainer__twYnq {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
}

.ItemisedReport_ReportTiles__hJZrk {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.ItemisedReport_TileContainer__2xr1u {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    -webkit-transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.ItemisedReport_Tile__1i1-e {
    cursor: pointer;
    padding: 4px;
}

.ItemisedReport_TileInner__2u-U6 {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.PresetDateRangePicker_panel {
    padding: 0 22px 11px
}

.PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #00a699;
    color: #00a699;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer
}

.PresetDateRangePicker_button:active {
    outline: 0
}

.PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699
}

.SingleDatePickerInput {
    display: inline-block;
    background-color: #fff
}

.SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
}

.SingleDatePickerInput__rtl {
    direction: rtl
}

.SingleDatePickerInput__disabled {
    background-color: #f2f2f2
}

.SingleDatePickerInput__block {
    display: block
}

.SingleDatePickerInput__showClearDate {
    padding-right: 30px
}

.SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.SingleDatePickerInput_clearDate__small {
    padding: 6px
}

.SingleDatePickerInput_clearDate__hide {
    visibility: hidden
}

.SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.SingleDatePickerInput_clearDate_svg__small {
    height: 9px
}

.SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.SingleDatePicker {
    position: relative;
    display: inline-block
}

.SingleDatePicker__block {
    display: block
}

.SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.SingleDatePicker_picker__rtl {
    direction: rtl
}

.SingleDatePicker_picker__directionLeft {
    left: 0
}

.SingleDatePicker_picker__directionRight {
    right: 0
}

.SingleDatePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff
}

.SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_show {
    width: 33px;
    height: 26px;
    position: absolute;
    z-index: 2
}

.DayPickerKeyboardShortcuts_show::before {
    content: "";
    display: block;
    position: absolute
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topRight {
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight::before {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topLeft {
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft::before {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
    border-left: 33px solid #008489
}

.DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: 5px
}

.DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px;
    text-align: left
}

.DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 700;
    margin: 0
}

.DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2
}

.DayPickerKeyboardShortcuts_close:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a
}

.CalendarDay {
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center
}

.CalendarDay:active {
    outline: 0
}

.CalendarDay__defaultCursor {
    cursor: default
}

.CalendarDay__default {
    border: 1px solid #e4e7e7;
    color: #484848;
    background: #fff
}

.CalendarDay__default:hover {
    background: #e4e7e7;
    border: 1px solid #e4e7e7;
    color: inherit
}

.CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 1px double #e4e7e7;
    color: inherit
}

.CalendarDay__outside {
    border: 0;
    background: #fff;
    color: #484848
}

.CalendarDay__outside:hover {
    border: 0
}

.CalendarDay__blocked_minimum_nights {
    background: #fff;
    border: 1px solid #eceeee;
    color: #cacccd
}

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd
}

.CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848
}

.CalendarDay__selected_span {
    background: #66e2da;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: #33dacd;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #00a699;
    border: 1px double #00a699;
    color: #fff
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: #b2f1ec;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__hovered_span:active {
    background: #80e8e0;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #cacccd
}

.CalendarDay__hovered_start_first_possible_end {
    background: #eceeee;
    border: 1px double #eceeee
}

.CalendarDay__hovered_start_blocked_min_nights {
    background: #eceeee;
    border: 1px double #e4e7e7
}

.CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0
}

.CalendarMonth_verticalSpacing {
    border-collapse: separate
}

.CalendarMonth_caption {
    color: #484848;
    font-size: 18px;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: top;
    caption-side: initial
}

.CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px
}

.CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 0
}

.CalendarMonthGrid__animating {
    z-index: 1
}

.CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px
}

.CalendarMonthGrid__vertical {
    margin: 0 auto
}

.CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
    overflow-y: scroll
}

.CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%
}

.CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none
}

.CalendarMonthGrid_month__hidden {
    visibility: hidden
}

.DayPickerNavigation {
    position: relative;
    z-index: 2
}

.DayPickerNavigation__horizontal {
    height: 0
}

.DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0
}

.DayPickerNavigation__verticalScrollableDefault {
    position: relative
}

.DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0
}

.DayPickerNavigation_button__default {
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: #757575
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
    border: 1px solid #c4c4c4
}

.DayPickerNavigation_button__default:active {
    background: #f2f2f2
}

.DayPickerNavigation_button__disabled {
    cursor: default;
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:active {
    background: 0 0
}

.DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px
}

.DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px
}

.DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px
}

.DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%
}

.DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0
}

.DayPickerNavigation_nextButton__verticalScrollableDefault {
    width: 100%
}

.DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block
}

.DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: #484848
}

.DayPickerNavigation_svg__disabled {
    fill: #f2f2f2
}

.DayPicker {
    background: #fff;
    position: relative;
    text-align: left
}

.DayPicker__horizontal {
    background: #fff
}

.DayPicker__verticalScrollable {
    height: 100%
}

.DayPicker__hidden {
    visibility: hidden
}

.DayPicker__withBorder {
    box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07);
    border-radius: 3px
}

.DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%
}

.DayPicker_portal__vertical {
    position: static;
    position: initial
}

.DayPicker_focusRegion {
    outline: 0
}

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top
}

.DayPicker_weekHeaders {
    position: relative
}

.DayPicker_weekHeaders__horizontal {
    margin-left: 9px
}

.DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 62px;
    z-index: 2;
    text-align: left
}

.DayPicker_weekHeader__vertical {
    left: 50%
}

.DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center
}

.DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px
}

.DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center
}

.DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px
}

.DayPicker_transitionContainer__horizontal {
    -webkit-transition: height .2s ease-in-out;
    transition: height .2s ease-in-out
}

.DayPicker_transitionContainer__vertical {
    width: 100%
}

.DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll
}

.DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 130px;
    vertical-align: middle
}

.DateInput__small {
    width: 97px
}

.DateInput__block {
    width: 100%
}

.DateInput__disabled {
    background: #f2f2f2;
    color: #dbdbdb
}

.DateInput_input {
    font-weight: 200;
    font-size: 19px;
    line-height: 24px;
    color: #484848;
    background-color: #fff;
    width: 100%;
    padding: 11px 11px 9px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid transparent;
    border-left: 0;
    border-radius: 0
}

.DateInput_input__small {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: .2px;
    padding: 10px 8px 6px;
}

.DateInput_input__regular {
    font-weight: auto
}

.DateInput_input__readOnly {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.DateInput_input__focused {
    outline: 0;
    background: #fff;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid #008489;
    border-left: 0
}

.DateInput_input__disabled {
    background: #f2f2f2;
    font-style: italic
}

.DateInput_screenReaderMessage {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.DateInput_fang {
    position: absolute;
    width: 20px;
    height: 10px;
    left: 22px;
    z-index: 2
}

.DateInput_fangShape {
    fill: #fff
}

.DateInput_fangStroke {
    stroke: #dbdbdb;
    fill: transparent
}

.DateRangePickerInput {
    background-color: #fff;
    display: inline-block
}

.DateRangePickerInput__disabled {
    background: #f2f2f2
}

.DateRangePickerInput__withBorder {
    border-radius: 0;
    border: 1px solid #dbdbdb
}

.DateRangePickerInput__rtl {
    direction: rtl
}

.DateRangePickerInput__block {
    display: block
}

.DateRangePickerInput__showClearDates {
    padding-right: 30px
}

.DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848;
    padding: 5px;
}

.DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #484848;
    height: 24px;
    width: 24px
}

.DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.DateRangePickerInput_clearDates__small {
    padding: 6px
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.DateRangePickerInput_clearDates__hide {
    visibility: hidden
}

.DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.DateRangePickerInput_clearDates_svg__small {
    height: 9px
}

.DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.DateRangePicker {
    position: relative;
    display: inline-block
}

.DateRangePicker__block {
    display: block
}

.DateRangePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.DateRangePicker_picker__rtl {
    direction: rtl
}

.DateRangePicker_picker__directionLeft {
    left: 0
}

.DateRangePicker_picker__directionRight {
    right: 0
}

.DateRangePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.DateRangePicker_picker__fullScreenPortal {
    background-color: #fff
}

.DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}
.Itemised_Container__l9ykd {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Itemised_Tools__2tDz0 {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.Itemised_PageFilter__i_zZO {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.Itemised_FilterReportBtn__292Ne {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.Itemised_ToolsWrapper__3zwej {
    height: 6rem;
    width: 100%;

}

.Itemised_PrintToolsWrapper__13PaK {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Itemised_AddNewMerchant__2t92n {
    /* // font-family: Cambay; */
}




/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Itemised_AddNewMerchantBtn__3kgJh:focus,
.Itemised_AddNewMerchantBtn__3kgJh:hover,
.Itemised_AddNewMerchantBtn__3kgJh:active {
    background-color: rgb(51, 137, 255);
}


.Itemised_ToolsDataExportIconWrapper__2rfXx {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Itemised_ToolsPrintIconWrapper__34c6U {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Itemised_FilterInput__uTvwj {
    margin-right: 2rem;
}

.Itemised_FormGroup__2uXfC {
    /* margin-bottom: 10px; */
}

.Itemised_FormGroupDefault__Mbvez.Itemised_has-error__39tDp {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Itemised_FormGroupDefault__Mbvez {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Itemised_FormGroupDefault__Mbvez label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Itemised_FormGroup__2uXfC label:not(.Itemised_error__3OBJk) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Itemised_FormControl__20J34 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Itemised_MerchantTableWrapper__2iBH4 {
    margin-top: 3rem;
    white-space: nowrap;
}

.Itemised_TableWrapper__2tGHf {
    display: flex;
}

.Itemised_MerchantTable__27H1s {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Itemised_MerchantTable__27H1s th {
    text-align: left;
}

.Itemised_MerchantTable__27H1s th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Itemised_MerchantTable__27H1s td,
.Itemised_MerchantTable__27H1s th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Itemised_RevealMoreHeader__3aANi {
    padding: 0;
    vertical-align: middle;
}





.Itemised_Row__2B3XN {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Itemised_Row__2B3XN>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Itemised_dataTables_paginate__1__fD {
    float: right;
}

.Itemised_dataTables_paginate__1__fD {
    /* margin-top: 25px !important; */
}

div.Itemised_dataTables_paginate__1__fD {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Itemised_dataTables_paginate__1__fD ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Itemised_dataTables_paginate__1__fD ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Itemised_dataTables_paginate__1__fD ul>li.Itemised_disabled__gBOwB a {
    opacity: .5;
}

.Itemised_dataTables_paginate__1__fD ul>li.Itemised_disabled__gBOwB a {
    opacity: .35;
}

.Itemised_dataTables_paginate__1__fD ul>li.Itemised_active__2Kvr3>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Itemised_dataTables_paginate__1__fD ul>li.Itemised_next__1Zb0E>a,
.Itemised_dataTables_paginate__1__fD ul>li.Itemised_prev__3IXCY>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_right__1ImFk {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_left__3B9XJ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_right__1ImFk:before {
    content: "\E631";
}

.Itemised_pg_arrow_left__3B9XJ:before {
    content: "\E629";
}

.Itemised_btn__3Sbl3 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Itemised_btn_primary__gxTmP,
.Itemised_btn_primary__gxTmP:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Itemised_btn_cons__3yUbD {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Itemised_MerchantName__1qbG2 {
    font-weight: 500;
}

.Itemised_Icn__1U93i {
    cursor: pointer;
    /* fill:#000; */
}

.Itemised_Icn__1U93i:hover {
    cursor: pointer;

}



.Itemised_RevealMore__3VZRT {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Itemised_RevealMore__3VZRT:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Itemised_RevealMore__3VZRT div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Itemised_RevealMoreExpanded__o2Kcm {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Itemised_Expandabled__2Aha0 {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Itemised_Expandabled__2Aha0 td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Itemised_MerchantTable__27H1s td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Itemised_MerchantTable__27H1s td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Itemised_MerchantRow__2AKJq {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Itemised_MerchantRow__2AKJq:hover {
    background-color: #267788;
    color: #fff;

}

.Itemised_SelectedTransaction__1t3Zd {
    background-color: #267788;
    color: #fff;

}

.Itemised_MerchantRow__2AKJq:hover a {
    color: #fff;
}

.Itemised_MerchantRowExpanded__2ZaN8 {
    background: #267788;
    color: #fff;
}

.Itemised_table__3mHww.Itemised_table-condensed__3k_EX.Itemised_table-detailed__3ZPdv>tbody>tr.Itemised_shown__2oW_j>td {
    background: #fdf5df;
}


.Itemised_Totals__3hatI td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Itemised_MoneyTotalLabel__2k2MN {
    float: right;

}

.Itemised_TransactionDetail__Rpz6X {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Itemised_TransactionDetailHeader__39len {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Itemised_TransactionDetailBody__11kWz {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.Category_Container__1PqLM {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Category_BreadcrumbWrapper__35lze {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Category_Content__A3pe5 {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Category_Alert__1dcVV {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Category_Breadcrumb__20bK2 {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Category_Header__1L5a8 {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Category_ByLine__1X34H {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Category_Activity__FSfzQ {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Category_WelcomeContainer__3uBSg {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Category_MerchantProfile__1HynN {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Category_TabHeader___JVf6 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Category_PageHeader__21TSR {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Category_AddUser__2OnXp {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Category_AddUserBtn__2l-E2 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Category_ReportContainer__31-sU {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
}

.Category_ReportTiles__3Gu_1 {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.Category_TileContainer__29-lR {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    -webkit-transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.Category_Tile__24ENF {
    cursor: pointer;
    padding: 4px;
}

.Category_TileInner__dJbAl {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.ReportBody_Container__3CU-h {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.ReportBody_Tools__e-tVk {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.ReportBody_PageFilter__kNYxf {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.ReportBody_FilterReportBtn__2_INb {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.ReportBody_ToolsWrapper__2AU9K {
    height: 6rem;
    width: 100%;

}

.ReportBody_PrintToolsWrapper__2uBli {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.ReportBody_AddNewMerchant__2TAWB {
    /* // font-family: Cambay; */
}




/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.ReportBody_AddNewMerchantBtn__2Zlpu:focus,
.ReportBody_AddNewMerchantBtn__2Zlpu:hover,
.ReportBody_AddNewMerchantBtn__2Zlpu:active {
    background-color: rgb(51, 137, 255);
}


.ReportBody_ToolsDataExportIconWrapper__-rbff {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ReportBody_ToolsPrintIconWrapper__r0PkP {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ReportBody_FilterInput__1OfTg {
    margin-right: 2rem;
}

.ReportBody_FormGroup__1ejdI {
    /* margin-bottom: 10px; */
}

.ReportBody_FormGroupDefault__1q2u1.ReportBody_has-error__2YUNB {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReportBody_FormGroupDefault__1q2u1 {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ReportBody_FormGroupDefault__1q2u1 label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.ReportBody_FormGroup__1ejdI label:not(.ReportBody_error__1oMf6) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ReportBody_FormControl__2Q4aI {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ReportBody_MerchantTableWrapper__2YBGl {
    margin-top: 3rem;
    white-space: nowrap;
}

.ReportBody_TableWrapper__1Rhhz {
    display: flex;
}

.ReportBody_MerchantTable__23zre {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.ReportBody_MerchantTable__23zre th {
    text-align: left;
}

.ReportBody_MerchantTable__23zre th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ReportBody_MerchantTable__23zre td,
.ReportBody_MerchantTable__23zre th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.ReportBody_RevealMoreHeader__2SvtY {
    padding: 0;
    vertical-align: middle;
}





.ReportBody_Row__3N0vc {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ReportBody_Row__3N0vc>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ReportBody_dataTables_paginate__3P43v {
    float: right;
}

.ReportBody_dataTables_paginate__3P43v {
    /* margin-top: 25px !important; */
}

div.ReportBody_dataTables_paginate__3P43v {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ReportBody_dataTables_paginate__3P43v ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.ReportBody_dataTables_paginate__3P43v ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.ReportBody_dataTables_paginate__3P43v ul>li.ReportBody_disabled__3Zsu- a {
    opacity: .5;
}

.ReportBody_dataTables_paginate__3P43v ul>li.ReportBody_disabled__3Zsu- a {
    opacity: .35;
}

.ReportBody_dataTables_paginate__3P43v ul>li.ReportBody_active__2cOwo>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ReportBody_dataTables_paginate__3P43v ul>li.ReportBody_next__1dXyC>a,
.ReportBody_dataTables_paginate__3P43v ul>li.ReportBody_prev__MrF8m>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__1TCCX {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_left__MyrUZ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__1TCCX:before {
    content: "\E631";
}

.ReportBody_pg_arrow_left__MyrUZ:before {
    content: "\E629";
}

.ReportBody_btn__2l5ev {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReportBody_btn_primary__1iydQ,
.ReportBody_btn_primary__1iydQ:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ReportBody_btn_cons__1vf9g {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.ReportBody_MerchantName__3GPg9 {
    font-weight: 500;
}

.ReportBody_Icn__2YwGC {
    cursor: pointer;
    /* fill:#000; */
}

.ReportBody_Icn__2YwGC:hover {
    cursor: pointer;

}



.ReportBody_RevealMore__uDrLx {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ReportBody_RevealMore__uDrLx:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ReportBody_RevealMore__uDrLx div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ReportBody_RevealMoreExpanded__2IE8U {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ReportBody_Expandabled__3e9YZ {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ReportBody_Expandabled__3e9YZ td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReportBody_MerchantTable__23zre td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ReportBody_MerchantTable__23zre td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ReportBody_MerchantRow__1XSQS {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ReportBody_MerchantRow__1XSQS:hover {
    background-color: #267788;
    color: #fff;

}

.ReportBody_SelectedTransaction__16bS6 {
    background-color: #267788;
    color: #fff;

}

.ReportBody_MerchantRow__1XSQS:hover a {
    color: #fff;
}

.ReportBody_MerchantRowExpanded__zFI5p {
    background: #267788;
    color: #fff;
}

.ReportBody_table__3X--A.ReportBody_table-condensed__jHKAf.ReportBody_table-detailed__14wn8>tbody>tr.ReportBody_shown__10Q1u>td {
    background: #fdf5df;
}


.ReportBody_Totals__1tzw4 td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ReportBody_MoneyTotalLabel__ERijW {
    float: right;

}

.ReportBody_TransactionDetail__3GzVD {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.ReportBody_TransactionDetailHeader__2OgaQ {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.ReportBody_TransactionDetailBody__17gpx {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.Date_Container__1PUny {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Date_BreadcrumbWrapper__1PISx {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Date_Content__m8wnG {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Date_Alert__1JbLb {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Date_Breadcrumb__2B9rN {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Date_Header__13BDQ {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Date_ByLine__3ZDFV {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Date_Activity__2ab2x {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Date_WelcomeContainer__1wc0i {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Date_MerchantProfile__2DVF1 {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Date_TabHeader__1T1D3 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Date_PageHeader__15h6F {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Date_AddUser__3Sqf5 {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Date_AddUserBtn__2pB4w {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Date_ReportContainer__194vU {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
}

.Date_ReportTiles__Rm2DW {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.Date_TileContainer__9txeg {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    -webkit-transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.Date_Tile__1iCKB {
    cursor: pointer;
    padding: 4px;
}

.Date_TileInner__OXiWT {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.ReportBody_Container__RcB9d {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.ReportBody_Tools__2D24d {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.ReportBody_PageFilter__374Y0 {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.ReportBody_FilterReportBtn__qaDez {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.ReportBody_ToolsWrapper__3Q6ug {
    height: 6rem;
    width: 100%;

}

.ReportBody_PrintToolsWrapper__3DWe0 {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.ReportBody_AddNewMerchant__3alHI {
    /* // font-family: Cambay; */
}




/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.ReportBody_AddNewMerchantBtn__3U6UA:focus,
.ReportBody_AddNewMerchantBtn__3U6UA:hover,
.ReportBody_AddNewMerchantBtn__3U6UA:active {
    background-color: rgb(51, 137, 255);
}


.ReportBody_ToolsDataExportIconWrapper__10FZJ {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ReportBody_ToolsPrintIconWrapper__1Nkv1 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ReportBody_FilterInput__LwOo8 {
    margin-right: 2rem;
}

.ReportBody_FormGroup__1WyUx {
    /* margin-bottom: 10px; */
}

.ReportBody_FormGroupDefault__3_W9c.ReportBody_has-error__3FGzd {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReportBody_FormGroupDefault__3_W9c {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ReportBody_FormGroupDefault__3_W9c label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.ReportBody_FormGroup__1WyUx label:not(.ReportBody_error__1r4BK) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ReportBody_FormControl__4yAXF {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ReportBody_MerchantTableWrapper__2xy_3 {
    margin-top: 3rem;
    white-space: nowrap;
}

.ReportBody_TableWrapper__3wgYL {
    display: flex;
}

.ReportBody_MerchantTable__1HGun {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.ReportBody_MerchantTable__1HGun th {
    text-align: left;
}

.ReportBody_MerchantTable__1HGun th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ReportBody_MerchantTable__1HGun td,
.ReportBody_MerchantTable__1HGun th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.ReportBody_RevealMoreHeader__1P9py {
    padding: 0;
    vertical-align: middle;
}





.ReportBody_Row__1kq20 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ReportBody_Row__1kq20>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ReportBody_dataTables_paginate__FAd9U {
    float: right;
}

.ReportBody_dataTables_paginate__FAd9U {
    /* margin-top: 25px !important; */
}

div.ReportBody_dataTables_paginate__FAd9U {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ReportBody_dataTables_paginate__FAd9U ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.ReportBody_dataTables_paginate__FAd9U ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.ReportBody_dataTables_paginate__FAd9U ul>li.ReportBody_disabled__sy9gx a {
    opacity: .5;
}

.ReportBody_dataTables_paginate__FAd9U ul>li.ReportBody_disabled__sy9gx a {
    opacity: .35;
}

.ReportBody_dataTables_paginate__FAd9U ul>li.ReportBody_active__37igB>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ReportBody_dataTables_paginate__FAd9U ul>li.ReportBody_next__EO1Np>a,
.ReportBody_dataTables_paginate__FAd9U ul>li.ReportBody_prev__7lJUM>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__KqE9l {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_left__3K_60 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__KqE9l:before {
    content: "\E631";
}

.ReportBody_pg_arrow_left__3K_60:before {
    content: "\E629";
}

.ReportBody_btn__1hx4x {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReportBody_btn_primary__3TXKz,
.ReportBody_btn_primary__3TXKz:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ReportBody_btn_cons__1pPs4 {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.ReportBody_MerchantName__3rkbe {
    font-weight: 500;
}

.ReportBody_Icn__3w5M2 {
    cursor: pointer;
    /* fill:#000; */
}

.ReportBody_Icn__3w5M2:hover {
    cursor: pointer;

}



.ReportBody_RevealMore__1frmk {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ReportBody_RevealMore__1frmk:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ReportBody_RevealMore__1frmk div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ReportBody_RevealMoreExpanded__Avm4z {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ReportBody_Expandabled__2tcia {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ReportBody_Expandabled__2tcia td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReportBody_MerchantTable__1HGun td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ReportBody_MerchantTable__1HGun td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ReportBody_MerchantRow__3tCoH {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ReportBody_MerchantRow__3tCoH:hover {
    background-color: #267788;
    color: #fff;

}

.ReportBody_SelectedTransaction__3GG5W {
    background-color: #267788;
    color: #fff;

}

.ReportBody_MerchantRow__3tCoH:hover a {
    color: #fff;
}

.ReportBody_MerchantRowExpanded__252yd {
    background: #267788;
    color: #fff;
}

.ReportBody_table__XPHaT.ReportBody_table-condensed__2_3wV.ReportBody_table-detailed__2oYLV>tbody>tr.ReportBody_shown__1KeDK>td {
    background: #fdf5df;
}


.ReportBody_Totals__b2O1P td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ReportBody_MoneyTotalLabel__nAc2R {
    float: right;

}

.ReportBody_TransactionDetail__38pgf {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.ReportBody_TransactionDetailHeader__2pSB7 {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.ReportBody_TransactionDetailBody__15v2d {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.Levy_Container__3JlVL {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Levy_BreadcrumbWrapper__2Z02F {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Levy_Content__1vDUo {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Levy_Alert__3RFW3 {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Levy_Breadcrumb__VPZHg {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Levy_Header__2V3nU {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Levy_ByLine__3rWhL {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Levy_Activity__33xcE {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Levy_WelcomeContainer__1hFhv {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Levy_MerchantProfile__1VU65 {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Levy_TabHeader__3eAfL {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Levy_PageHeader__wJzZV {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Levy_AddUser__3DeC7 {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Levy_AddUserBtn__1pHFb {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Levy_ReportContainer__Dz3zT {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
}

.Levy_ReportTiles__1rgHL {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.Levy_TileContainer__P3acO {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    -webkit-transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.Levy_Tile__1tIds {
    cursor: pointer;
    padding: 4px;
}

.Levy_TileInner__2lzIW {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.Itemised_Container__1fxVv {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Itemised_Tools__30lBv {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.Itemised_PageFilter__2vPnO {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.Itemised_FilterReportBtn__1XhLs {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.Itemised_ToolsWrapper__2QBG5 {
    height: 6rem;
    width: 100%;

}

.Itemised_PrintToolsWrapper__26YaZ {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Itemised_AddNewMerchant__1_8w6 {
    /* // font-family: Cambay; */
}




/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Itemised_AddNewMerchantBtn__aqsDr:focus,
.Itemised_AddNewMerchantBtn__aqsDr:hover,
.Itemised_AddNewMerchantBtn__aqsDr:active {
    background-color: rgb(51, 137, 255);
}


.Itemised_ToolsDataExportIconWrapper__31Dxm {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Itemised_ToolsPrintIconWrapper__11ssC {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Itemised_FilterInput__-caTP {
    margin-right: 2rem;
}

.Itemised_FormGroup__1gnrV {
    /* margin-bottom: 10px; */
}

.Itemised_FormGroupDefault__2mlKP.Itemised_has-error__QBMcK {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Itemised_FormGroupDefault__2mlKP {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Itemised_FormGroupDefault__2mlKP label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Itemised_FormGroup__1gnrV label:not(.Itemised_error__1Jsh7) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Itemised_FormControl__1KPM7 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Itemised_MerchantTableWrapper__8fZJ0 {
    margin-top: 3rem;
    white-space: nowrap;
}

.Itemised_TableWrapper__21eng {
    display: flex;
}

.Itemised_MerchantTable__2Qctt {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Itemised_MerchantTable__2Qctt th {
    text-align: left;
}

.Itemised_MerchantTable__2Qctt th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Itemised_MerchantTable__2Qctt td,
.Itemised_MerchantTable__2Qctt th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Itemised_RevealMoreHeader__3ZrfW {
    padding: 0;
    vertical-align: middle;
}





.Itemised_Row__arPl9 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Itemised_Row__arPl9>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Itemised_dataTables_paginate__2AZD- {
    float: right;
}

.Itemised_dataTables_paginate__2AZD- {
    /* margin-top: 25px !important; */
}

div.Itemised_dataTables_paginate__2AZD- {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Itemised_dataTables_paginate__2AZD- ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Itemised_dataTables_paginate__2AZD- ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Itemised_dataTables_paginate__2AZD- ul>li.Itemised_disabled__X1Qxc a {
    opacity: .5;
}

.Itemised_dataTables_paginate__2AZD- ul>li.Itemised_disabled__X1Qxc a {
    opacity: .35;
}

.Itemised_dataTables_paginate__2AZD- ul>li.Itemised_active__39qmT>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Itemised_dataTables_paginate__2AZD- ul>li.Itemised_next__1nS4u>a,
.Itemised_dataTables_paginate__2AZD- ul>li.Itemised_prev__2lT29>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_right__3MmWz {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_left__2sL12 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_right__3MmWz:before {
    content: "\E631";
}

.Itemised_pg_arrow_left__2sL12:before {
    content: "\E629";
}

.Itemised_btn__3h14X {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Itemised_btn_primary__2YzQg,
.Itemised_btn_primary__2YzQg:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Itemised_btn_cons__3jC_d {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Itemised_MerchantName__2PFrQ {
    font-weight: 500;
}

.Itemised_Icn__13FY4 {
    cursor: pointer;
    /* fill:#000; */
}

.Itemised_Icn__13FY4:hover {
    cursor: pointer;

}



.Itemised_RevealMore__m304j {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Itemised_RevealMore__m304j:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Itemised_RevealMore__m304j div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Itemised_RevealMoreExpanded__2wHXS {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Itemised_Expandabled__28dkR {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Itemised_Expandabled__28dkR td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Itemised_MerchantTable__2Qctt td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Itemised_MerchantTable__2Qctt td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Itemised_MerchantRow__1rIvl {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Itemised_MerchantRow__1rIvl:hover {
    background-color: #267788;
    color: #fff;

}

.Itemised_SelectedTransaction__2Rs7S {
    background-color: #267788;
    color: #fff;

}

.Itemised_MerchantRow__1rIvl:hover a {
    color: #fff;
}

.Itemised_MerchantRowExpanded__3U9dF {
    background: #267788;
    color: #fff;
}

.Itemised_table__NMm87.Itemised_table-condensed__2ZDVx.Itemised_table-detailed__3kcW7>tbody>tr.Itemised_shown__1H_xU>td {
    background: #fdf5df;
}


.Itemised_Totals__2YJX0 td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Itemised_MoneyTotalLabel__1sAwD {
    float: right;

}

.Itemised_TransactionDetail__1Aa2N {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Itemised_TransactionDetailHeader__lXGsH {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Itemised_TransactionDetailBody__5KsCC {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.Cachet_Container__2Z4Bk {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.Cachet_BreadcrumbWrapper__3hJN- {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Cachet_Content__3zfKJ {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Cachet_CardBody__2cTGW {
    margin-top: 1rem;
}

.Cachet_Alert__1Mp7I {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Cachet_Breadcrumb__2GIQ9 {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Cachet_Header__3cVMy {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.Cachet_ByLine__2FDzE {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Cachet_Activity__M6ENR {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Cachet_WelcomeContainer__rkWwN {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Cachet_MerchantProfile__1LIXf {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.Cachet_TabHeader__3w-W0 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Cachet_PageHeader__377sW {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.Cachet_Header__3cVMy {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Cachet_ExportReport__3xUYM {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Cachet_ExportIconWrapper__1Ume4 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Cachet_EmailIconWrapper__2yWzE {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Cachet_AddUser__24F1c {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Cachet_AddUserBtn__O3mZO {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.Cachet_Tools__2AiBV {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.Cachet_PageFilter__V9SP5 {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.Cachet_PrintToolsWrapper__3eGRE {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.Cachet_FilterReportBtn__gXbGy {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.Cachet_AddNewMerchantBtn__KJ3jC:focus,
.Cachet_AddNewMerchantBtn__KJ3jC:hover,
.Cachet_AddNewMerchantBtn__KJ3jC:active {
    background-color: rgb(51, 137, 255);
}


.Cachet_ToolsDataExportIconWrapper__2ZmB1 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Cachet_ToolsPrintIconWrapper__2rwfz {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Cachet_FilterInput__3v_-V {
    margin-right: 2rem;
}

.Cachet_FormGroupDefault__3DwPr.Cachet_has-error__Qs-V6 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Cachet_FormGroupDefault__3DwPr {

    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Cachet_FormGroupDefault__3DwPr label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Cachet_FormGroup__khS_o label:not(.Cachet_error__30N2E) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Cachet_FormControl__q6Tza {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Cachet_MerchantTableWrapper__1aXOj {
    margin-top: 3rem;
    white-space: nowrap;
}

.Cachet_MerchantTable__YSf_I {
    width: 100%;
    border-collapse: collapse;
}

.Cachet_MerchantTable__YSf_I th {
    text-align: left;
}

.Cachet_MerchantTable__YSf_I th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Cachet_MerchantTable__YSf_I td,
.Cachet_MerchantTable__YSf_I th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Cachet_RevealMoreHeader__2731y {
    padding: 0;
    vertical-align: middle;
}

.Cachet_Row__2RxVD {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Cachet_Row__2RxVD>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Cachet_dataTables_paginate__3P0cM {
    float: right;
}

div.Cachet_dataTables_paginate__3P0cM {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Cachet_dataTables_paginate__3P0cM ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.Cachet_dataTables_paginate__3P0cM ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Cachet_dataTables_paginate__3P0cM ul>li.Cachet_disabled__pATKK a {
    opacity: .5;
}

.Cachet_dataTables_paginate__3P0cM ul>li.Cachet_disabled__pATKK a {
    opacity: .35;
}

.Cachet_dataTables_paginate__3P0cM ul>li.Cachet_active__1LTPI>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Cachet_dataTables_paginate__3P0cM ul>li.Cachet_next__3Yg5A>a,
.Cachet_dataTables_paginate__3P0cM ul>li.Cachet_prev__272TN>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Cachet_pg_arrow_right__EANRG {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Cachet_pg_arrow_left__1FagW {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Cachet_pg_arrow_right__EANRG:before {
    content: "\E631";
}

.Cachet_pg_arrow_left__1FagW:before {
    content: "\E629";
}

.Cachet_btn__1nTrC {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Cachet_btn_primary__36vfH,
.Cachet_btn_primary__36vfH:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Cachet_btn_cons__1QWLK {
    min-width: 120px;
}




.Cachet_MerchantName__2ASoy {
    font-weight: 500;
}

.Cachet_Icn__2a2kP {
    cursor: pointer;
    /* fill:#000; */
}

.Cachet_Icn__2a2kP:hover {
    cursor: pointer;

}



.Cachet_RevealMore__fZq2P {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Cachet_RevealMore__fZq2P:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Cachet_RevealMore__fZq2P div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Cachet_RevealMoreExpanded__hemF2 {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Cachet_Expandabled__1aQmA {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Cachet_Expandabled__1aQmA td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Cachet_MerchantTable__YSf_I td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Cachet_MerchantTable__YSf_I td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Cachet_MerchantRow__g0OqK {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Cachet_MerchantRow__g0OqK:hover {
    background-color: #267788;
    color: #fff;

}

.Cachet_MerchantRow__g0OqK:hover a {
    color: #fff;
}

.Cachet_MerchantRowExpanded__1dcN_ {
    background: #267788;
    color: #fff;
}

.Cachet_table__WGD34.Cachet_table-condensed__ERQ5n.Cachet_table-detailed__2aTNS>tbody>tr.Cachet_shown__zWcxy>td {
    background: #fdf5df;
}


.Cachet_Totals__2R9hF td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Cachet_MoneyTotalLabel__2XFRL {
    float: right;

}

.Cachet_FilterWrapper__15V4M {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.Cachet_FilterPeriods__1OqMv {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.Cachet_FilterPeriods__1OqMv li {
    font-weight: 600;

    padding-left: 4.8rem;
    line-height: 38px;
}

.Cachet_FinancialsIntro__2wBiU {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}
.Deposits_Container__3N43M {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Deposits_Tools__3Wa5s {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Deposits_PageFilter__1HExL {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Deposits_ToolsWrapper__W5qvt {
    height: 6rem;
    width: 100%;

}

.Deposits_TabHeader__2m9yf {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Deposits_Header__3M-3d {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Deposits_ExportReport__Wqxma {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Deposits_ExportIconWrapper__2LM9E {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Deposits_EmailIconWrapper__d_zfR {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Deposits_PrintToolsWrapper__w0QZD {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Deposits_AddNewMerchant__2webv {
    /* // font-family: Cambay; */
}

.Deposits_AddNewMerchantBtn__Rzurp {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Deposits_AddNewMerchantBtn__Rzurp:focus,
.Deposits_AddNewMerchantBtn__Rzurp:hover,
.Deposits_AddNewMerchantBtn__Rzurp:active {
    background-color: rgb(51, 137, 255);
}


.Deposits_ToolsDataExportIconWrapper__7O7Yx {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Deposits_ToolsPrintIconWrapper__3RcKL {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Deposits_FilterInput__1zYDR {
    margin-right: 2rem;
}

.Deposits_FormGroup__FFUkl {
    /* margin-bottom: 10px; */
}

.Deposits_FormGroupDefault__UwzY0.Deposits_has-error__3iDon {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Deposits_FormGroupDefault__UwzY0 {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Deposits_FormGroupDefault__UwzY0 label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Deposits_FormGroup__FFUkl label:not(.Deposits_error__25UbE) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Deposits_FormControl__1cCe8 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Deposits_MerchantTableWrapper__1aXvT {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Deposits_TableWrapper__2PJN6 {
    display: flex;
}

.Deposits_MerchantTable__31LEU {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Deposits_MerchantTable__31LEU th {
    text-align: left;
}

.Deposits_MerchantTable__31LEU th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Deposits_MerchantTable__31LEU td,
.Deposits_MerchantTable__31LEU th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Deposits_MerchantTable__31LEU td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Deposits_MerchantTable__31LEU td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Deposits_RevealMoreHeader__29Woe {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Deposits_Row__YDw75 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Deposits_Row__YDw75>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Deposits_dataTables_paginate__1dcbh {
    float: right;
}

.Deposits_dataTables_paginate__1dcbh {
    /* margin-top: 25px !important; */
}

div.Deposits_dataTables_paginate__1dcbh {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Deposits_dataTables_paginate__1dcbh ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Deposits_dataTables_paginate__1dcbh ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Deposits_dataTables_paginate__1dcbh ul>li.Deposits_disabled__3uYhl a {
    opacity: .5;
}

.Deposits_dataTables_paginate__1dcbh ul>li.Deposits_disabled__3uYhl a {
    opacity: .35;
}

.Deposits_dataTables_paginate__1dcbh ul>li.Deposits_active__1FrHo>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Deposits_dataTables_paginate__1dcbh ul>li.Deposits_next__1SW2J>a,
.Deposits_dataTables_paginate__1dcbh ul>li.Deposits_prev__1QI7x>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_right__UXxRe {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_left__NmDhN {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_right__UXxRe:before {
    content: "\E631";
}

.Deposits_pg_arrow_left__NmDhN:before {
    content: "\E629";
}

.Deposits_btn__1mdHk {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Deposits_btn_primary__17VWl,
.Deposits_btn_primary__17VWl:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Deposits_btn_cons__3qDpt {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Deposits_MerchantRow__1Np6U {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Deposits_MerchantRow__1Np6U:hover {
    background-color: #267788;
    color: #fff;

}

.Deposits_MerchantRow__1Np6U:hover a {
    color: #fff;
}

.Deposits_MerchantRowExpanded__mWFq7 {
    background: #267788;
    color: #fff;
}

.Deposits_table__LeHFU.Deposits_table-condensed__1D0l5.Deposits_table-detailed__1C3Gw>tbody>tr.Deposits_shown__2WdL6>td {
    background: #fdf5df;
}

.Deposits_EnableMerchant__16w-F {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Deposits_EnableMerchantHeader__3209F {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Deposits_EnableMerchantBody__2l2Wt {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}


.Deposits_MoneyTotalLabel__2HaFw {
    float: right;
    font-weight: 600;
}

.Deposits_MoneyTotal__3RUBu {
    font-weight: 600;
}
.Reversals_Container__34h2k {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Reversals_Tools__4Ckxr {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Reversals_PageFilter__1zKrR {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Reversals_ToolsWrapper__XhHh2 {
    height: 6rem;
    width: 100%;

}

.Reversals_TabHeader__3rNA8 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Reversals_Header__2QVN_ {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Reversals_ExportReport__1b8pr {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Reversals_ExportIconWrapper__1BHi4 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Reversals_EmailIconWrapper__2MtfW {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Reversals_PrintToolsWrapper__18Kj3 {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Reversals_AddNewMerchant__2xLK8 {
    /* // font-family: Cambay; */
}

.Reversals_AddNewMerchantBtn__BifXl {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Reversals_AddNewMerchantBtn__BifXl:focus,
.Reversals_AddNewMerchantBtn__BifXl:hover,
.Reversals_AddNewMerchantBtn__BifXl:active {
    background-color: rgb(51, 137, 255);
}


.Reversals_ToolsDataExportIconWrapper__2rvS5 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Reversals_ToolsPrintIconWrapper__1mOoL {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Reversals_FilterInput__VVUJ8 {
    margin-right: 2rem;
}

.Reversals_FormGroup__1hnU9 {
    /* margin-bottom: 10px; */
}

.Reversals_FormGroupDefault__ioJEe.Reversals_has-error__1Dp7L {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Reversals_FormGroupDefault__ioJEe {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Reversals_FormGroupDefault__ioJEe label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Reversals_FormGroup__1hnU9 label:not(.Reversals_error__378j_) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Reversals_FormControl__1MeON {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Reversals_MerchantTableWrapper__2cCJU {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Reversals_TableWrapper__2MNtW {
    display: flex;
}

.Reversals_MerchantTable__1kwm0 {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Reversals_MerchantTable__1kwm0 th {
    text-align: left;
}

.Reversals_MerchantTable__1kwm0 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Reversals_MerchantTable__1kwm0 td,
.Reversals_MerchantTable__1kwm0 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Reversals_MerchantTable__1kwm0 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Reversals_MerchantTable__1kwm0 td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Reversals_RevealMoreHeader__3rJwN {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Reversals_Row__1rZ-a {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Reversals_Row__1rZ-a>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Reversals_dataTables_paginate__1YCdW {
    float: right;
}

.Reversals_dataTables_paginate__1YCdW {
    /* margin-top: 25px !important; */
}

div.Reversals_dataTables_paginate__1YCdW {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Reversals_dataTables_paginate__1YCdW ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Reversals_dataTables_paginate__1YCdW ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Reversals_dataTables_paginate__1YCdW ul>li.Reversals_disabled__LtT2z a {
    opacity: .5;
}

.Reversals_dataTables_paginate__1YCdW ul>li.Reversals_disabled__LtT2z a {
    opacity: .35;
}

.Reversals_dataTables_paginate__1YCdW ul>li.Reversals_active__3DAR3>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Reversals_dataTables_paginate__1YCdW ul>li.Reversals_next__1y_zo>a,
.Reversals_dataTables_paginate__1YCdW ul>li.Reversals_prev__3hMc1>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Reversals_pg_arrow_right__fmyfG {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Reversals_pg_arrow_left__3LEsf {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Reversals_pg_arrow_right__fmyfG:before {
    content: "\E631";
}

.Reversals_pg_arrow_left__3LEsf:before {
    content: "\E629";
}

.Reversals_btn__3hi8Q {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Reversals_btn_primary__1kpns,
.Reversals_btn_primary__1kpns:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Reversals_btn_cons__2KIKb {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Reversals_MerchantRow__3N4z7 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Reversals_MerchantRow__3N4z7:hover {
    background-color: #267788;
    color: #fff;

}

.Reversals_MerchantRow__3N4z7:hover a {
    color: #fff;
}

.Reversals_MerchantRowExpanded__177Qd {
    background: #267788;
    color: #fff;
}

.Reversals_table__3qf3Z.Reversals_table-condensed__2eFeS.Reversals_table-detailed__2ar2w>tbody>tr.Reversals_shown__2jC_Y>td {
    background: #fdf5df;
}

.Reversals_EnableMerchant__BJ3hF {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Reversals_EnableMerchantHeader__1qN-H {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Reversals_EnableMerchantBody__3d-PI {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}


.Reversals_MoneyTotalLabel__31DtH {
    float: right;
    font-weight: 600;
}

.Reversals_MoneyTotal__1JaIm {
    font-weight: 600;
}
.Summary_Container__hLRBs {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Summary_Tools__3zQI_ {
    display: flex;
    /* margin: 0 2.5rem; */
    /* padding-top: 1.4rem; */
}

.Summary_PageFilter__2FrUW {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.Summary_ToolsWrapper__2KkPV {
    height: 6rem;
    width: 100%;

}

.Summary_PrintToolsWrapper__1gq5s {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Summary_AddNewMerchant__htNXe {
    /* // font-family: Cambay; */
}

.Summary_FilterReportBtn__2DUs0 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Summary_AddNewMerchantBtn__1FHOm:focus,
.Summary_AddNewMerchantBtn__1FHOm:hover,
.Summary_AddNewMerchantBtn__1FHOm:active {
    background-color: rgb(51, 137, 255);
}


.Summary_ToolsDataExportIconWrapper__3yd_z {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Summary_ToolsPrintIconWrapper__iPqeB {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Summary_FilterInput__1YgVW {
    margin-right: 2rem;
}

.Summary_FormGroup__382xb {
    /* margin-bottom: 10px; */
}

.Summary_FormGroupDefault__6YqaZ.Summary_has-error__2Hu5k {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Summary_FormGroupDefault__6YqaZ {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Summary_FormGroupDefault__6YqaZ label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Summary_FormGroup__382xb label:not(.Summary_error__3yo8C) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Summary_FormControl__3d12- {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Summary_MerchantTableWrapper__22x8G {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Summary_TableWrapper__3gBkZ {
    /* margin: 0 2.5rem; */
}

.Summary_MerchantTable__2Ps4N {
    width: 50%;
    border-collapse: collapse;
}

.Summary_MerchantTable__2Ps4N th {
    text-align: left;
}

.Summary_MerchantTable__2Ps4N th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Summary_MerchantTable__2Ps4N td,
.Summary_MerchantTable__2Ps4N th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Summary_RevealMoreHeader__2vDN2 {
    padding: 0;
    vertical-align: middle;
}





.Summary_Row__22uoc {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Summary_Row__22uoc>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Summary_dataTables_paginate__2oKoz {
    float: right;
}

.Summary_dataTables_paginate__2oKoz {
    /* margin-top: 25px !important; */
}

div.Summary_dataTables_paginate__2oKoz {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Summary_dataTables_paginate__2oKoz ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Summary_dataTables_paginate__2oKoz ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Summary_dataTables_paginate__2oKoz ul>li.Summary_disabled__2EI7- a {
    opacity: .5;
}

.Summary_dataTables_paginate__2oKoz ul>li.Summary_disabled__2EI7- a {
    opacity: .35;
}

.Summary_dataTables_paginate__2oKoz ul>li.Summary_active__B6QGu>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Summary_dataTables_paginate__2oKoz ul>li.Summary_next__1dkjj>a,
.Summary_dataTables_paginate__2oKoz ul>li.Summary_prev__2oJnQ>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Summary_pg_arrow_right__xyfOb {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Summary_pg_arrow_left__34nPO {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Summary_pg_arrow_right__xyfOb:before {
    content: "\E631";
}

.Summary_pg_arrow_left__34nPO:before {
    content: "\E629";
}

.Summary_btn__3fqJX {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Summary_btn_primary__3QIH0,
.Summary_btn_primary__3QIH0:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Summary_btn_cons__3pwCh {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Summary_MerchantName__1XmOf {
    font-weight: 500;
}

.Summary_Icn__3a1Fo {
    cursor: pointer;
    /* fill:#000; */
}

.Summary_Icn__3a1Fo:hover {
    cursor: pointer;

}



.Summary_RevealMore__2oYa8 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Summary_RevealMore__2oYa8:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Summary_RevealMore__2oYa8 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Summary_RevealMoreExpanded__Rg3Jp {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Summary_Expandabled__3XhTr {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Summary_Expandabled__3XhTr td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Summary_MerchantTable__2Ps4N td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Summary_MerchantTable__2Ps4N td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Summary_MerchantRow___VTZr {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Summary_MerchantRow___VTZr:hover {
    background-color: #267788;
    color: #fff;

}

.Summary_MerchantRow___VTZr:hover a {
    color: #fff;
}

.Summary_MerchantRowExpanded__1-m-V {
    background: #267788;
    color: #fff;
}

.Summary_table__2i8eH.Summary_table-condensed__1VEnw.Summary_table-detailed__1QbXS>tbody>tr.Summary_shown__28cEr>td {
    background: #fdf5df;
}


.Summary_Totals__1lp1D td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Summary_MoneyTotalLabel__1YtSF {
    float: right;

}
.Commissions_Container__3G56l {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Commissions_Tools__1EM_5 {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Commissions_PageFilter__3FeoG {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Commissions_ToolsWrapper__FvZZM {
    height: 6rem;
    width: 100%;

}

.Commissions_TabHeader__2c1GF {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Commissions_Header__3Ql-A {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Commissions_ExportReport__3vEjE {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Commissions_ExportIconWrapper__1_1rG {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Commissions_EmailIconWrapper__2Gd-C {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Commissions_PrintToolsWrapper__3rDfP {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Commissions_AddNewMerchant__32IF2 {
    /* // font-family: Cambay; */
}

.Commissions_AddNewMerchantBtn__2KTry {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Commissions_AddNewMerchantBtn__2KTry:focus,
.Commissions_AddNewMerchantBtn__2KTry:hover,
.Commissions_AddNewMerchantBtn__2KTry:active {
    background-color: rgb(51, 137, 255);
}


.Commissions_ToolsDataExportIconWrapper__CniF2 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Commissions_ToolsPrintIconWrapper__10B89 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Commissions_FilterInput__3bKLV {
    margin-right: 2rem;
}

.Commissions_FormGroup__1D4XA {
    /* margin-bottom: 10px; */
}

.Commissions_FormGroupDefault__1io1V.Commissions_has-error__2oB51 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Commissions_FormGroupDefault__1io1V {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Commissions_FormGroupDefault__1io1V label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Commissions_FormGroup__1D4XA label:not(.Commissions_error__3ICPO) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Commissions_FormControl__toWDh {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Commissions_MerchantTableWrapper__1jLMk {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Commissions_TableWrapper__2Fy_O {
    display: flex;
}

.Commissions_MerchantTable__1Xpw1 {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Commissions_MerchantTable__1Xpw1 th {
    text-align: left;
}

.Commissions_MerchantTable__1Xpw1 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Commissions_MerchantTable__1Xpw1 td,
.Commissions_MerchantTable__1Xpw1 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Commissions_MerchantTable__1Xpw1 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Commissions_MerchantTable__1Xpw1 td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Commissions_RevealMoreHeader__TUB6R {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Commissions_Row__31Wbb {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Commissions_Row__31Wbb>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Commissions_dataTables_paginate__2pxkd {
    float: right;
}

.Commissions_dataTables_paginate__2pxkd {
    /* margin-top: 25px !important; */
}

div.Commissions_dataTables_paginate__2pxkd {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Commissions_dataTables_paginate__2pxkd ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Commissions_dataTables_paginate__2pxkd ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Commissions_dataTables_paginate__2pxkd ul>li.Commissions_disabled__OHGBZ a {
    opacity: .5;
}

.Commissions_dataTables_paginate__2pxkd ul>li.Commissions_disabled__OHGBZ a {
    opacity: .35;
}

.Commissions_dataTables_paginate__2pxkd ul>li.Commissions_active__3zYJJ>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Commissions_dataTables_paginate__2pxkd ul>li.Commissions_next__2mIVg>a,
.Commissions_dataTables_paginate__2pxkd ul>li.Commissions_prev__wzj6h>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commissions_pg_arrow_right__3iEcH {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commissions_pg_arrow_left__3rSRw {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commissions_pg_arrow_right__3iEcH:before {
    content: "\E631";
}

.Commissions_pg_arrow_left__3rSRw:before {
    content: "\E629";
}

.Commissions_btn__YxBHL {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Commissions_btn_primary__2Duhw,
.Commissions_btn_primary__2Duhw:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Commissions_btn_cons__3QDsp {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Commissions_MerchantRow__3Hkkh {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Commissions_MerchantRow__3Hkkh:hover {
    background-color: #267788;
    color: #fff;

}

.Commissions_MerchantRow__3Hkkh:hover a {
    color: #fff;
}

.Commissions_MerchantRowExpanded__1BQOE {
    background: #267788;
    color: #fff;
}

.Commissions_table__3hdbX.Commissions_table-condensed__3_Rya.Commissions_table-detailed__3EKzf>tbody>tr.Commissions_shown__xGdJm>td {
    background: #fdf5df;
}

.Commissions_EnableMerchant__2l7B3 {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Commissions_EnableMerchantHeader__1UwOU {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Commissions_EnableMerchantBody__2rflA {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}


.Commissions_MoneyTotalLabel__3nGNf {
    /* float: right; */
    font-weight: 600;
}

.Commissions_MoneyTotal__1tban {
    font-weight: 600;
}
.Withdrawals_Container__2SxT7 {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Withdrawals_Tools__28Gw8 {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Withdrawals_PageFilter__OlVF6 {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Withdrawals_ToolsWrapper__3mc-V {
    height: 6rem;
    width: 100%;

}

.Withdrawals_TabHeader__3mey2 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Withdrawals_Header__3xpi5 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Withdrawals_ExportReport__2R9mp {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Withdrawals_ExportIconWrapper__7wLn1 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Withdrawals_EmailIconWrapper__y1l-G {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Withdrawals_PrintToolsWrapper__1Hp9Y {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Withdrawals_AddNewMerchant__1X-YX {
    /* // font-family: Cambay; */
}

.Withdrawals_AddNewMerchantBtn__1tqBx {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Withdrawals_AddNewMerchantBtn__1tqBx:focus,
.Withdrawals_AddNewMerchantBtn__1tqBx:hover,
.Withdrawals_AddNewMerchantBtn__1tqBx:active {
    background-color: rgb(51, 137, 255);
}


.Withdrawals_ToolsDataExportIconWrapper__2A_7x {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Withdrawals_ToolsPrintIconWrapper__1ZKav {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Withdrawals_FilterInput__3TWm5 {
    margin-right: 2rem;
}

.Withdrawals_FormGroup__Ldfcn {
    /* margin-bottom: 10px; */
}

.Withdrawals_FormGroupDefault__2MoHH.Withdrawals_has-error__2Ha-H {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Withdrawals_FormGroupDefault__2MoHH {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Withdrawals_FormGroupDefault__2MoHH label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Withdrawals_FormGroup__Ldfcn label:not(.Withdrawals_error__32oQR) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Withdrawals_FormControl__13tki {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Withdrawals_MerchantTableWrapper__RelUQ {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Withdrawals_TableWrapper__ml9DX {
    display: flex;
}

.Withdrawals_MerchantTable__1VrVJ {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Withdrawals_MerchantTable__1VrVJ th {
    text-align: left;
}

.Withdrawals_MerchantTable__1VrVJ th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Withdrawals_MerchantTable__1VrVJ td,
.Withdrawals_MerchantTable__1VrVJ th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Withdrawals_MerchantTable__1VrVJ td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Withdrawals_MerchantTable__1VrVJ td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Withdrawals_RevealMoreHeader__3-_Y- {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Withdrawals_Row__2njby {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Withdrawals_Row__2njby>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Withdrawals_dataTables_paginate__1SdGe {
    float: right;
}

.Withdrawals_dataTables_paginate__1SdGe {
    /* margin-top: 25px !important; */
}

div.Withdrawals_dataTables_paginate__1SdGe {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Withdrawals_dataTables_paginate__1SdGe ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Withdrawals_dataTables_paginate__1SdGe ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Withdrawals_dataTables_paginate__1SdGe ul>li.Withdrawals_disabled__v7Y_T a {
    opacity: .5;
}

.Withdrawals_dataTables_paginate__1SdGe ul>li.Withdrawals_disabled__v7Y_T a {
    opacity: .35;
}

.Withdrawals_dataTables_paginate__1SdGe ul>li.Withdrawals_active__1b0gj>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Withdrawals_dataTables_paginate__1SdGe ul>li.Withdrawals_next__2SW-N>a,
.Withdrawals_dataTables_paginate__1SdGe ul>li.Withdrawals_prev__Nm6rZ>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Withdrawals_pg_arrow_right__2B-Eo {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Withdrawals_pg_arrow_left__3uhc5 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Withdrawals_pg_arrow_right__2B-Eo:before {
    content: "\E631";
}

.Withdrawals_pg_arrow_left__3uhc5:before {
    content: "\E629";
}

.Withdrawals_btn__2ZAC6 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Withdrawals_btn_primary__4Q2t7,
.Withdrawals_btn_primary__4Q2t7:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Withdrawals_btn_cons__2dcxa {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Withdrawals_MerchantRow__3uz6A {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Withdrawals_MerchantRow__3uz6A:hover {
    background-color: #267788;
    color: #fff;

}

.Withdrawals_MerchantRow__3uz6A:hover a {
    color: #fff;
}

.Withdrawals_MerchantRowExpanded__3Zq8F {
    background: #267788;
    color: #fff;
}

.Withdrawals_table__3uNzX.Withdrawals_table-condensed__2Bvrg.Withdrawals_table-detailed__34pu7>tbody>tr.Withdrawals_shown__yTjNL>td {
    background: #fdf5df;
}

.Withdrawals_EnableMerchant__2O8QF {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Withdrawals_EnableMerchantHeader__ZYasA {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Withdrawals_EnableMerchantBody__3lvRc {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.Sales_Container__2cMpv {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Sales_TabHeader__xJLiO {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Sales_Header__2-RbX {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Sales_ExportReport__1mOxk {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Sales_ExportIconWrapper__3RB65 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Sales_EmailIconWrapper__2Ybgm {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Sales_Tools__2JKXU {
    display: flex;
    /* margin: 0 2.5rem; */
    /* padding-top: 1.4rem; */
}

.Sales_PageFilter__hmGEY {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.Sales_ToolsWrapper__1nteq {
    height: 6rem;
    width: 100%;

}

.Sales_PrintToolsWrapper__2aw3O {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Sales_AddNewMerchant__rgNlo {
    /* // font-family: Cambay; */
}

.Sales_FilterReportBtn__2VJb_ {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Sales_AddNewMerchantBtn__2e9zg:focus,
.Sales_AddNewMerchantBtn__2e9zg:hover,
.Sales_AddNewMerchantBtn__2e9zg:active {
    background-color: rgb(51, 137, 255);
}


.Sales_ToolsDataExportIconWrapper__2i_Jg {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Sales_ToolsPrintIconWrapper__pCCLp {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Sales_FilterInput__1dI9q {
    margin-right: 2rem;
}

.Sales_FormGroup__2GBKb {
    /* margin-bottom: 10px; */
}

.Sales_FormGroupDefault__jku7H.Sales_has-error__3tu6p {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Sales_FormGroupDefault__jku7H {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Sales_FormGroupDefault__jku7H label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Sales_FormGroup__2GBKb label:not(.Sales_error__1rdXD) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Sales_FormControl__3UVaY {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Sales_MerchantTableWrapper__1CyUS {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Sales_TableWrapper__JP71o {
    /* margin: 0 2.5rem; */
}

.Sales_MerchantTable__3jip_ {
    width: 100%;
    border-collapse: collapse;
}

.Sales_MerchantTable__3jip_ th {
    text-align: left;
}

.Sales_MerchantTable__3jip_ th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Sales_MerchantTable__3jip_ td,
.Sales_MerchantTable__3jip_ th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Sales_RevealMoreHeader__1Aebm {
    padding: 0;
    vertical-align: middle;
}





.Sales_Row__1Ek-w {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Sales_Row__1Ek-w>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Sales_dataTables_paginate__JR7YQ {
    float: right;
}

.Sales_dataTables_paginate__JR7YQ {
    /* margin-top: 25px !important; */
}

div.Sales_dataTables_paginate__JR7YQ {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Sales_dataTables_paginate__JR7YQ ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Sales_dataTables_paginate__JR7YQ ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Sales_dataTables_paginate__JR7YQ ul>li.Sales_disabled__-FxL7 a {
    opacity: .5;
}

.Sales_dataTables_paginate__JR7YQ ul>li.Sales_disabled__-FxL7 a {
    opacity: .35;
}

.Sales_dataTables_paginate__JR7YQ ul>li.Sales_active__3tzDt>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Sales_dataTables_paginate__JR7YQ ul>li.Sales_next__22HTI>a,
.Sales_dataTables_paginate__JR7YQ ul>li.Sales_prev__2claJ>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Sales_pg_arrow_right__36iPl {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Sales_pg_arrow_left__3Z0Ss {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Sales_pg_arrow_right__36iPl:before {
    content: "\E631";
}

.Sales_pg_arrow_left__3Z0Ss:before {
    content: "\E629";
}

.Sales_btn__3fZqW {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Sales_btn_primary__2s5nZ,
.Sales_btn_primary__2s5nZ:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Sales_btn_cons__B7ZRi {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Sales_MerchantName__23v9Z {
    font-weight: 500;
}

.Sales_Icn__1gAZ3 {
    cursor: pointer;
    /* fill:#000; */
}

.Sales_Icn__1gAZ3:hover {
    cursor: pointer;

}



.Sales_RevealMore__2qSEX {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Sales_RevealMore__2qSEX:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Sales_RevealMore__2qSEX div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Sales_RevealMoreExpanded__1wNUy {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Sales_Expandabled__2Xdnv {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Sales_Expandabled__2Xdnv td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Sales_MerchantTable__3jip_ td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Sales_MerchantTable__3jip_ td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Sales_MerchantRow__1ciJW {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Sales_MerchantRow__1ciJW:hover {
    background-color: #267788;
    color: #fff;

}

.Sales_MerchantRow__1ciJW:hover a {
    color: #fff;
}

.Sales_MerchantRowExpanded__2BZ0W {
    background: #267788;
    color: #fff;
}

.Sales_table__2_oP_.Sales_table-condensed__37wKw.Sales_table-detailed__V0kmq>tbody>tr.Sales_shown__1_ckI>td {
    background: #fdf5df;
}


.Sales_Totals__SbN2w td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Sales_MoneyTotalLabel__xt_2l {
    float: right;

}

.Sales_MoneyTotal__2umtM {
    font-weight: 600;
}
.transactions_Container__1hVFg {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.transactions_BreadcrumbWrapper__3mHSv {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.transactions_Content__1ctMe {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.transactions_Alert__3D6KF {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.transactions_Breadcrumb__1e9oN {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.transactions_Header__2Gykd {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.transactions_ByLine__3omIm {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.transactions_Activity__1eBV1 {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.transactions_WelcomeContainer__wDolR {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.transactions_MerchantProfile__2K6ui {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.transactions_TabHeader__2vepm {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.transactions_PageHeader__2wDQD {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.transactions_AddUser__39hOU {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.transactions_AddUserBtn__285r- {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.transactions_ReportContainer__2S-7L {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
}

.transactions_ReportTiles__36tm2 {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.transactions_TileContainer__3fEaw {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    -webkit-transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.transactions_Tile__2F2M7 {
    cursor: pointer;
    padding: 4px;
}

.transactions_TileInner__3wW2R {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.PresetDateRangePicker_panel {
    padding: 0 22px 11px
}

.PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #00a699;
    color: #00a699;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer
}

.PresetDateRangePicker_button:active {
    outline: 0
}

.PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699
}

.SingleDatePickerInput {
    display: inline-block;
    background-color: #fff
}

.SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
}

.SingleDatePickerInput__rtl {
    direction: rtl
}

.SingleDatePickerInput__disabled {
    background-color: #f2f2f2
}

.SingleDatePickerInput__block {
    display: block
}

.SingleDatePickerInput__showClearDate {
    padding-right: 30px
}

.SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.SingleDatePickerInput_clearDate__small {
    padding: 6px
}

.SingleDatePickerInput_clearDate__hide {
    visibility: hidden
}

.SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.SingleDatePickerInput_clearDate_svg__small {
    height: 9px
}

.SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.SingleDatePicker {
    position: relative;
    display: inline-block
}

.SingleDatePicker__block {
    display: block
}

.SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.SingleDatePicker_picker__rtl {
    direction: rtl
}

.SingleDatePicker_picker__directionLeft {
    left: 0
}

.SingleDatePicker_picker__directionRight {
    right: 0
}

.SingleDatePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff
}

.SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_show {
    width: 33px;
    height: 26px;
    position: absolute;
    z-index: 2
}

.DayPickerKeyboardShortcuts_show::before {
    content: "";
    display: block;
    position: absolute
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topRight {
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight::before {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topLeft {
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft::before {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
    border-left: 33px solid #008489
}

.DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: 5px
}

.DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px;
    text-align: left
}

.DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 700;
    margin: 0
}

.DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2
}

.DayPickerKeyboardShortcuts_close:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a
}

.CalendarDay {
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center
}

.CalendarDay:active {
    outline: 0
}

.CalendarDay__defaultCursor {
    cursor: default
}

.CalendarDay__default {
    border: 1px solid #e4e7e7;
    color: #484848;
    background: #fff
}

.CalendarDay__default:hover {
    background: #e4e7e7;
    border: 1px solid #e4e7e7;
    color: inherit
}

.CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 1px double #e4e7e7;
    color: inherit
}

.CalendarDay__outside {
    border: 0;
    background: #fff;
    color: #484848
}

.CalendarDay__outside:hover {
    border: 0
}

.CalendarDay__blocked_minimum_nights {
    background: #fff;
    border: 1px solid #eceeee;
    color: #cacccd
}

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd
}

.CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848
}

.CalendarDay__selected_span {
    background: #66e2da;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: #33dacd;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #00a699;
    border: 1px double #00a699;
    color: #fff
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: #b2f1ec;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__hovered_span:active {
    background: #80e8e0;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #cacccd
}

.CalendarDay__hovered_start_first_possible_end {
    background: #eceeee;
    border: 1px double #eceeee
}

.CalendarDay__hovered_start_blocked_min_nights {
    background: #eceeee;
    border: 1px double #e4e7e7
}

.CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0
}

.CalendarMonth_verticalSpacing {
    border-collapse: separate
}

.CalendarMonth_caption {
    color: #484848;
    font-size: 18px;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: top;
    caption-side: initial
}

.CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px
}

.CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 0
}

.CalendarMonthGrid__animating {
    z-index: 1
}

.CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px
}

.CalendarMonthGrid__vertical {
    margin: 0 auto
}

.CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
    overflow-y: scroll
}

.CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%
}

.CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none
}

.CalendarMonthGrid_month__hidden {
    visibility: hidden
}

.DayPickerNavigation {
    position: relative;
    z-index: 2
}

.DayPickerNavigation__horizontal {
    height: 0
}

.DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0
}

.DayPickerNavigation__verticalScrollableDefault {
    position: relative
}

.DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0
}

.DayPickerNavigation_button__default {
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: #757575
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
    border: 1px solid #c4c4c4
}

.DayPickerNavigation_button__default:active {
    background: #f2f2f2
}

.DayPickerNavigation_button__disabled {
    cursor: default;
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:active {
    background: 0 0
}

.DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px
}

.DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px
}

.DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px
}

.DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%
}

.DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0
}

.DayPickerNavigation_nextButton__verticalScrollableDefault {
    width: 100%
}

.DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block
}

.DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: #484848
}

.DayPickerNavigation_svg__disabled {
    fill: #f2f2f2
}

.DayPicker {
    background: #fff;
    position: relative;
    text-align: left
}

.DayPicker__horizontal {
    background: #fff
}

.DayPicker__verticalScrollable {
    height: 100%
}

.DayPicker__hidden {
    visibility: hidden
}

.DayPicker__withBorder {
    box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07);
    border-radius: 3px
}

.DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%
}

.DayPicker_portal__vertical {
    position: static;
    position: initial
}

.DayPicker_focusRegion {
    outline: 0
}

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top
}

.DayPicker_weekHeaders {
    position: relative
}

.DayPicker_weekHeaders__horizontal {
    margin-left: 9px
}

.DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 62px;
    z-index: 2;
    text-align: left
}

.DayPicker_weekHeader__vertical {
    left: 50%
}

.DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center
}

.DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px
}

.DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center
}

.DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px
}

.DayPicker_transitionContainer__horizontal {
    -webkit-transition: height .2s ease-in-out;
    transition: height .2s ease-in-out
}

.DayPicker_transitionContainer__vertical {
    width: 100%
}

.DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll
}

.DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 130px;
    vertical-align: middle
}

.DateInput__small {
    width: 97px
}

.DateInput__block {
    width: 100%
}

.DateInput__disabled {
    background: #f2f2f2;
    color: #dbdbdb
}

.DateInput_input {
    font-weight: 200;
    font-size: 19px;
    line-height: 24px;
    color: #484848;
    background-color: #fff;
    width: 100%;
    padding: 11px 11px 9px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid transparent;
    border-left: 0;
    border-radius: 0
}

.DateInput_input__small {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: .2px;
    padding: 10px 8px 6px;
}

.DateInput_input__regular {
    font-weight: auto
}

.DateInput_input__readOnly {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.DateInput_input__focused {
    outline: 0;
    background: #fff;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid #008489;
    border-left: 0
}

.DateInput_input__disabled {
    background: #f2f2f2;
    font-style: italic
}

.DateInput_screenReaderMessage {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.DateInput_fang {
    position: absolute;
    width: 20px;
    height: 10px;
    left: 22px;
    z-index: 2
}

.DateInput_fangShape {
    fill: #fff
}

.DateInput_fangStroke {
    stroke: #dbdbdb;
    fill: transparent
}

.DateRangePickerInput {
    background-color: #fff;
    display: inline-block
}

.DateRangePickerInput__disabled {
    background: #f2f2f2
}

.DateRangePickerInput__withBorder {
    border-radius: 0;
    border: 1px solid #dbdbdb
}

.DateRangePickerInput__rtl {
    direction: rtl
}

.DateRangePickerInput__block {
    display: block
}

.DateRangePickerInput__showClearDates {
    padding-right: 30px
}

.DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848;
    padding: 5px;
}

.DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #484848;
    height: 24px;
    width: 24px
}

.DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.DateRangePickerInput_clearDates__small {
    padding: 6px
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.DateRangePickerInput_clearDates__hide {
    visibility: hidden
}

.DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.DateRangePickerInput_clearDates_svg__small {
    height: 9px
}

.DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.DateRangePicker {
    position: relative;
    display: inline-block
}

.DateRangePicker__block {
    display: block
}

.DateRangePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.DateRangePicker_picker__rtl {
    direction: rtl
}

.DateRangePicker_picker__directionLeft {
    left: 0
}

.DateRangePicker_picker__directionRight {
    right: 0
}

.DateRangePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.DateRangePicker_picker__fullScreenPortal {
    background-color: #fff
}

.DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}
.Transactions_Container__qP5wN {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Transactions_Tools__2Gw-Y {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.Transactions_PageFilter__2o9yr {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.Transactions_FilterReportBtn__2ORfq {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.Transactions_ToolsWrapper__3f4Cv {
    height: 6rem;
    width: 100%;

}

.Transactions_PrintToolsWrapper__2fw35 {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Transactions_AddNewMerchant__3UM-Z {
    /* // font-family: Cambay; */
}




/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Transactions_AddNewMerchantBtn__1rZuc:focus,
.Transactions_AddNewMerchantBtn__1rZuc:hover,
.Transactions_AddNewMerchantBtn__1rZuc:active {
    background-color: rgb(51, 137, 255);
}


.Transactions_ToolsDataExportIconWrapper__3NvjF {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Transactions_ToolsPrintIconWrapper__2mx39 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Transactions_FilterInput__2NGKk {
    margin-right: 2rem;
}

.Transactions_FormGroup__PvScj {
    /* margin-bottom: 10px; */
}

.Transactions_FormGroupDefault__2V2SL.Transactions_has-error__DAFXc {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Transactions_FormGroupDefault__2V2SL {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Transactions_FormGroupDefault__2V2SL label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.Transactions_FormGroup__PvScj label:not(.Transactions_error__1hvAf) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Transactions_FormControl__2uRON {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Transactions_MerchantTableWrapper__1ff_- {
    margin-top: 3rem;
    white-space: nowrap;
}

.Transactions_TableWrapper__3hoZ6 {
    display: flex;
}

.Transactions_MerchantTable__3v1RX {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Transactions_MerchantTable__3v1RX th {
    text-align: left;
}

.Transactions_MerchantTable__3v1RX th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Transactions_MerchantTable__3v1RX td,
.Transactions_MerchantTable__3v1RX th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Transactions_RevealMoreHeader__V2tfY {
    padding: 0;
    vertical-align: middle;
}





.Transactions_Row__2e6uo {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Transactions_Row__2e6uo>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Transactions_dataTables_paginate__2Igcb {
    float: right;
}

.Transactions_dataTables_paginate__2Igcb {
    /* margin-top: 25px !important; */
}

div.Transactions_dataTables_paginate__2Igcb {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Transactions_dataTables_paginate__2Igcb ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Transactions_dataTables_paginate__2Igcb ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.Transactions_dataTables_paginate__2Igcb ul>li.Transactions_disabled__2xiPi a {
    opacity: .5;
}

.Transactions_dataTables_paginate__2Igcb ul>li.Transactions_disabled__2xiPi a {
    opacity: .35;
}

.Transactions_dataTables_paginate__2Igcb ul>li.Transactions_active__3d9KX>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Transactions_dataTables_paginate__2Igcb ul>li.Transactions_next__2Va-m>a,
.Transactions_dataTables_paginate__2Igcb ul>li.Transactions_prev__35VmU>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_right__3S6D_ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_left__24S8O {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_right__3S6D_:before {
    content: "\E631";
}

.Transactions_pg_arrow_left__24S8O:before {
    content: "\E629";
}

.Transactions_btn__3q2P0 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Transactions_btn_primary__30p9b,
.Transactions_btn_primary__30p9b:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Transactions_btn_cons__31Q0j {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Transactions_MerchantName__21CvD {
    font-weight: 500;
}

.Transactions_Icn__3SVAU {
    cursor: pointer;
    /* fill:#000; */
}

.Transactions_Icn__3SVAU:hover {
    cursor: pointer;

}



.Transactions_RevealMore__q9JdA {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Transactions_RevealMore__q9JdA:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Transactions_RevealMore__q9JdA div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Transactions_RevealMoreExpanded__sMmtX {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Transactions_Expandabled__3Z-Ey {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Transactions_Expandabled__3Z-Ey td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Transactions_MerchantTable__3v1RX td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Transactions_MerchantTable__3v1RX td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Transactions_MerchantRow__2tC2j {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Transactions_MerchantRow__2tC2j:hover {
    background-color: #267788;
    color: #fff;

}

.Transactions_SelectedTransaction__2SGmW {
    background-color: #267788;
    color: #fff;

}

.Transactions_MerchantRow__2tC2j:hover a {
    color: #fff;
}

.Transactions_MerchantRowExpanded__1c5gH {
    background: #267788;
    color: #fff;
}

.Transactions_table__-tQeZ.Transactions_table-condensed__19qR7.Transactions_table-detailed__38OK2>tbody>tr.Transactions_shown__6G3QZ>td {
    background: #fdf5df;
}


.Transactions_Totals__3I74K td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Transactions_MoneyTotalLabel__1o2MV {
    float: right;

}

.Transactions_TransactionDetail__27gLV {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Transactions_TransactionDetailHeader__3SoOb {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Transactions_TransactionDetailBody__3EQCt {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.SelectedTransaction_PageHeader__2fkGg {
    flex: 1 1 100%;
    justify-content: flex-start;
    width: auto;
}
