.Container {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.BreadcrumbWrapper {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Content {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Alert {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Breadcrumb {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Header {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.ByLine {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Activity {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.WelcomeContainer {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.MerchantProfile {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.TabHeader {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.PageHeader {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.AddUser {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.AddUserBtn {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.FilterInput {
    margin-right: 2rem;
}

.FormGroup {
    margin-bottom: 10px;
}

.FormGroupDefault.has-error {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.FormGroupDefault {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

.PageFilter {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
    padding-left: 2rem;
    margin-top: 10px;
}

.PageTitle {
    border-right: 1px solid #707070;
    padding-right: 2rem;
    font-weight: 700;
    margin-top: 10px;
}

.FilterInput {
    margin-right: 2rem;
}

.FormGroup {
    margin-bottom: 10px;
}

.FormGroupDefault.has-error {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.FormGroupDefault {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.FormGroupDefault label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.FormGroup label:not(.error) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.FormControl {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}