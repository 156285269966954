:root {
  box-sizing: border-box;

}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  /* background-color: #f4f5f8; */
  /* background-color: #f6f9fa; */
  background-color: #efefef;
  font-family: 'Roboto';
  height: 100%;
  /* padding-top: 6.4rem; */
}

/* UTILITY CLASSES */
.u-left-text {
  text-align: left;
}

.u-right-text {
  text-align: right;
}

.u-margin-bottom-big {
  margin-bottom: 7rem;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem;
}

.u-text-semi-bold {
  font-weight: 600;
}

.u-inline-block {
  display: inline-block;
}

.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

