.Wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.ToolBar {
    /* height: 6.4rem; */
}

.Content {
    width: 100% !important;
}

/* .MainContentWrapper {
    width: 100%;
} */
.FlexColumns {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
    transform: translate3d(0, 0, 0);
}

.shell-view .main:not(.main-flex-container-true) {
    width: 100%;
    height: 100%;
    position: absolute;
}

.bAw {
    min-width: 56px;
    -webkit-transition-duration: .15s;
    transition-duration: .15s;
    -webkit-transition-property: min-width, width;
    transition-property: min-width, width;
    -webkit-transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    z-index: 2;
}