.Container {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.BreadcrumbWrapper {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Content {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Alert {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Breadcrumb {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Header {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.ByLine {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Activity {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.WelcomeContainer {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.MerchantProfile {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

@media (max-width: 1366px) {
    .LoggedUser {
        flex: 0 0 30rem;
    }
}

.ProfilePic {
    flex: 0 0 10.4rem;
}

.SummaryContainer {
    display: flex;
    flex: 1 1 auto;
    margin-left: 2.4rem;
}

.MembershipSummary {
    flex: 1 1 25.6rem;
}

.SubscriptionSummary {
    flex: 1 1 25.6rem;
    margin-left: 2.4rem;
}

.ClaimSummary {
    flex: 1 1 25.6rem;
    margin-left: 2.4rem;
}

.ProfileImage {
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    padding: 12px;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}