.Container {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.BreadcrumbWrapper {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Content {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Alert {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Breadcrumb {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Header {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.ByLine {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Activity {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.WelcomeContainer {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.MerchantProfile {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.TabHeader {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.PageHeader {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.AddUser {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.AddUserBtn {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}